import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ToolbarService } from 'src/app/common/components/toolbar/toolbar.service';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/common/app.constants';

@Component({
  selector: 'app-order-optionbar',
  templateUrl: './order-optionbar.component.html',
  styleUrl: './order-optionbar.component.scss'
})

  export class OrderOptionbarComponent implements OnDestroy {

    @Input() show: boolean = true;
    @Input() filters: any[] = [];
    @Output() filterSelected: EventEmitter<any> = new EventEmitter();
    showFilter: boolean = true;
    selectedFilter: any;
    
  
    @Output() reset: EventEmitter<any> = new EventEmitter();
  
  
    toolbarToggleEventSub: Subscription;
  
  
    constructor(private toolbarService: ToolbarService) {
  
      /**
       * Subscribes the toolbar events
       */
      this.toolbarToggleEventSub = this.toolbarService.menuToggleEvent.subscribe(() => {
        this.toggleOptionBar();
      });
  
    }
  
    /**
     * un register the subscription
     */
    ngOnDestroy(): void {
      this.toolbarToggleEventSub.unsubscribe();
    }
  
    /**
     * Toggles the display of optioin bar
     */
    toggleOptionBar(): void {
      this.show = !this.show;
    }
  
    
  
    /**
     * When filter is selected clear other selections
     * @param segment 
     */
    onFilterSelected(filter: any): void {
      this.resetQuickFilters();
      this.selectedFilter = filter;
      this.filterSelected.emit(filter);
    }
  
    /**
     * Reset all the selected quick filters
     */
    resetQuickFilters(reload?: boolean) {
  
      if (reload !== undefined && reload == true) {
        this.reset.emit({filter:(this.filterSelected!==undefined)});
      }
     
      this.selectedFilter = undefined;
    }
  }
