import { Injectable } from "@angular/core";
import { ItemCategoryModel } from "./models/item-category.model";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ItemCategoryService {

  constructor(private http: HttpClient) {}


  /**
   *
   * @param categoryInfo
   * Calls the API to delete the record
   */
  delete(categoryInfo: ItemCategoryModel): Observable<any> {
    return this.http.get(environment.baseUrl + "itemcategory/delete?id=" + categoryInfo.id);

  }
  getCategoryList(): any {
    return this.http.get<any>(environment.baseUrl + "itemcategory/get-list");
  }
  // getCategoryListExt(staticPayload: { search: { filters: { filter: never[]; scope: { offset: string; limit: number; }; }; sort: { column: string; order: string; }[]; }; }): Observable<any> {
  //   return this.http.post<Response>(
  //     environment.baseUrl + "itemcategory/get-list-ext",
  //     staticPayload
  //   );
  // }
  
  getCategoryListExtJava(staticPayload: { search: { filters: { filter: never[]; scope: { offset: string; limit: number; }; }; sort: { column: string; order: string; }[]; }; }): Observable<any> {
    return this.http.post<Response>(
      environment.baseUrl + "itemcategory/get-list-ext",
      staticPayload
    );
  }
  getCategoryListExt(requestData:RequestData): Observable<any> {

   
    return this.http.post(environment.wsPhp+'/itemcategory/get-list', requestData);
  
}
getItemCategoryData(categoryInfo: ItemCategoryModel): Observable<any> {
  return this.http.get(environment.baseUrl + "itemcategory/get?id=" + categoryInfo.id);
}


  getId(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + "itemcategory/get?id=" + id);
    
  }
  /**
   *
   * @param userInfo
   * Calls the update API to insert/update the record
   */
  update(requestParams: any) {
    return this.http.post(
      environment.baseUrl + "itemcategory/save",
      requestParams
    );
  }
  
  uploadItemCategoryImage(formData: any): any {
    return this.http.post<any>(
      environment.baseUrl + "itemcategory/upload-file/",
      formData
    );
  }

  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.wsPhp + '/itemcategory/export', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }
  

}

