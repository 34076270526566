import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/common/app.constants';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { StorageService } from 'src/app/common/services/storage.service';
import { SystemSettingService } from 'src/app/common/services/system-setting.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  userLogin(loginData: { user_name: any; password: any; }) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient,
    private snackBarService:SnackBarService,
    private systemSettingService:SystemSettingService,
    private storageService:StorageService
  ) { 

  }
  /** 
   * Login function
   */
  get getActiveUser() {
    let user=sessionStorage.getItem(AppConstants.SESSION_VAR_USER);
    return (user!==null)?JSON.parse(user):undefined;
  }
  // tslint:disable-next-line:typedef
  logout() {
    sessionStorage.setItem(AppConstants.SESSION_VAR_USER, '');
    return true;
  }
  // tslint:disable-next-line:typedef
  // isloggedin():boolean{
    
  //   return sessionStorage.getItem(AppConstants.SESSION_VAR_USER)!=null;
  // }
  // tslint:disable-next-line:typedef
  setLoggin(params: any) {
    this.storageService.saveToSession(AppConstants.SESSION_VAR_USER, JSON.stringify(params));
    return true;
  }

  // tslint:disable-next-line:typedef
  getData() {
    return this.http.get('https://reqres.in/api/users?page=2')
    .subscribe( data => {
    });
  }

  // tslint:disable-next-line:typedef
  validateLogin(requestParams: any) {
    return this.http.post<Response>(environment.wsPhp + '/user/login',
      requestParams
    );
  }

  // authenticate(data: any): Observable<any> {
  //   return this.http.post(environment.baseUrl+'/auth', data);
  // }

  getAuthToken() {
  
    
    let authData = {
      user_name: environment.userId,
      password: environment.password,
      apikey: environment.appKey
    };
     this.http.post(environment.wsPhp+'/auth', authData).subscribe(
       (response:any) => {
        if (response.status === 'SUCCESS') {
          let token = response.token;
          sessionStorage.setItem(AppConstants.SESSION_VAR_TOKEN, token);
          this.setSystemParam();
          // login success action gose here
        } else {
   
           // this.response.status = 401;
            let snackBarData: SnackBarData = {
              title: "Failed",
              type: 'error',
              message: ''
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
          
        }
    );
  
}
setSystemParam(){
  this.systemSettingService.getSystemParam().subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log(response.data);
          sessionStorage.setItem(AppConstants.SESSION_SYSTEMPARAM, JSON.stringify(response.data));
        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  }

}
