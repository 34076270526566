<div class="dashboard-root-container  page-root-container">
  <div class="sales-summary-div">

    <app-sales-summary-card [salesData]="todaySales" [salesType]='saleTypes[0]'></app-sales-summary-card>
    <app-sales-summary-card [salesData]="thisWeekSales" [salesType]='saleTypes[1]'></app-sales-summary-card>
    <app-sales-summary-card [salesData]="thisMonthSales" [salesType]='saleTypes[2]'></app-sales-summary-card>
    <app-sales-summary-card [salesData]="thisYearSales" [salesType]='saleTypes[3]'></app-sales-summary-card>

  </div>
  <div class="chart-section"style="margin-top:10px;">
    <div style="display:flex; justify-content: space-between;">
<!-- dashboard.component.html -->
<!-- <ngx-charts-line-chart
  [view]="[600, 300]"
  [scheme]="colorScheme"
  [results]="multi"
  [gradient]="false"
  [xAxis]="true"
  [yAxis]="true"
  [legend]="true"
  [legendTitle]="''"
  [showXAxisLabel]="false"
  [showYAxisLabel]="false"
  xAxisLabel="Month"
  yAxisLabel="Amount"
  [autoScale]="true"
  [legendPosition]="legendPosition"
  [curve]="shape"
  >
</ngx-charts-line-chart> -->
<ngx-charts-bar-vertical-2d
  [view]="[600, 300]"
  [scheme]="colorScheme"
  [results]="multi"
  [gradient]="false"
  [xAxis]="true"
  [yAxis]="true"
  [legend]="true"
  [legendTitle]="''"
  [showXAxisLabel]="false"
  [showYAxisLabel]="false"
  [barPadding]="0"    
  [groupPadding]="8"
  [legendPosition]="legendPosition"
  >
</ngx-charts-bar-vertical-2d>



<!-- Simple HTML Table Section -->
<div class="table-section">
  
  <div class="item-table-cantainer">
    <p *ngIf="!tableData || tableData.length === 0"  class="no-items-message">
      No items to show.
    </p>
  <table class="styled-table">

    <tbody>
      <tr *ngFor="let product of tableData; let i = index">
        <td> {{ i + 1 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ product.name }}</td>
        <td>{{ product.qty }}</td>
      </tr>
      
    </tbody>
  
  </table>
</div>
  <div class="total-item-container"><p class="total-item">Top 10 items</p></div>

</div>
</div>




  </div>
</div>
