import { Injectable } from "@angular/core";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class MastersalesReportService {

  constructor(private http: HttpClient) { }

  getDepartmentListExt(requestParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "department/get-list-ext",
      requestParams
    );
  }
  getShopListExt(requestParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "shop/get-list-ext",
      requestParams
    );
  }
  getRegionListExt(requestParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "region/get-list-ext",
      requestParams
    );
  }
  getItemsList(bodyParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "saleitem/get-list",
      JSON.stringify(bodyParams)
    );
  }
  getItemCategoryListExt(requestParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "itemcategory/get-list-ext",
      requestParams
    );
  }
  generateMasterSalesReport(requestParams: any): any {
    return this.http.post(
      environment.apiReportUrl,  
      JSON.stringify(requestParams),  { observe: "response", responseType: "blob"}
    );
  }

}
