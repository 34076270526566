import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentModel } from '../models/department.model';
import { DepartmentService } from '../department.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { PermissionService } from 'src/app/auth/permission.service';

@Component({
  selector: 'app-department-edit',
  templateUrl: './department-edit.component.html',
  styleUrls: ['./department-edit.component.scss'],
  animations: [
    trigger('if', [
      // ... animation states and styles
    ])
  ]
})
export class DepartmentEditComponent implements DirtyCheck {
  title: string = "New Department";
  editForm: FormGroup;
  departmentInfo: DepartmentModel = {
    isDeleted: 0,
    isSystem: 0,
    isCommon: 1,
    isValid: 1,
  };
  isFormDirty: boolean = false;
  permission: any;

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private departmentService: DepartmentService,
    private snackBarService: SnackBarService,
    public auth: AuthService,
    public dialog: MatDialog,
    private permissionService: PermissionService,
  ) {
    this.permission = permissionService.getPagePermission('department')

    let DepartmentId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.departmentInfo.code, [Validators.required, Validators.maxLength(50)]),
      name: this.builder.control(this.departmentInfo.name, [Validators.required]),
      description: this.builder.control(this.departmentInfo.description),
      displayOrder: this.builder.control(this.departmentInfo.displayOrder, [Validators.pattern('^[0-9]*$')]),
      isValid: this.builder.control(this.departmentInfo.isValid === 1),
      isCommon: this.builder.control(this.departmentInfo.isCommon === 1)
    });

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (DepartmentId !== null && parseInt(DepartmentId) !== 0) {
      this.setdepartmentInfo(DepartmentId);
    }
  }

  ngOnInit(): void { }

  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  navBack(): void {
    this.router.navigate(['/department']);
  }

  setdepartmentInfo(DepartmentId: any): void {
    this.departmentService.getId(DepartmentId).subscribe({
      next: (response) => {
        if (response.successCode === 0) {
          this.departmentInfo = response.data;
          this.editForm.patchValue({
            ...this.departmentInfo,
            isValid: this.departmentInfo.isValid === 1,
            isCommon: this.departmentInfo.isCommon === 1
          });
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  getTitle(): string {
    let title = (this.departmentInfo.id == 0) ? "New Department" : "Editing : " + this.departmentInfo.name;
    return title;
  }

  save(): void {
    if (this.editForm.valid) {
      const formValues = this.editForm.value;
      this.departmentInfo = {
        ...this.departmentInfo,
        ...formValues,
        isValid: formValues.isValid ? 1 : 0,
        isCommon: formValues.isCommon ? 1 : 0
      };

      const activeUser: any = this.auth.getUser();

      if (this.departmentInfo.id === 0 || this.departmentInfo.id === undefined) {
        this.departmentInfo.createdBy = activeUser.id;
      } else {
        this.departmentInfo.updatedBy = activeUser.id;
      }

      this.departmentService.update(this.departmentInfo).subscribe({
        next: (response: any) => {
          if (response.successCode === 0) {
            this.isFormDirty = false;
            let snackBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + (this.departmentInfo ? this.departmentInfo.name : '') + ' has been updated successfully.',
            };
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['department']);
          } else {
            let snackBarData: SnackBarData = {
              title: 'Failed!!!',
              type: 'error',
              message: 'Failed to Update'
            };
            this.snackBarService.openSnackBar(snackBarData);
            
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error',
          };
          this.snackBarService.openSnackBar(snackBarData);
        },
      });
    }
  }

  getErrorDescription(controlName: string): string | null {
    var message = null;
    const errors = this.editForm.get(controlName)?.errors;
    if (errors) {
      if (errors['required'])
        message = "This field should be entered.";
      else if (errors['maxlength'])
        message = "Max length of the code is " + errors['maxlength'].requiredLength;
    }
    return message;
  }  
}
