import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingService {

  constructor(private http: HttpClient) { }

   /**
   * 
   *  
   * get default date and time format
   */
   getSystemParam(): Observable<any> {
    return this.http.get(environment.wsPhp+'/oodatesettings/getSystemParam');
  }
  getSystemParamValue(key: string): any {
    let systemSettings = sessionStorage.getItem(AppConstants.SESSION_SYSTEMPARAM);
    var value = '';
    if (systemSettings != null) {
      var params = JSON.parse(systemSettings);
      var param = params.system_param.find((p: any) => p.key == key);
      value = param.value;
     
    }
    return value;

  }
  /**
   * set default date and time format
   */
  getDefaultDateFormat():string{
    return 'dd-MM-yyyy, h:mm a';
  }
}
