import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from './company.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { EditTimingComponent } from './edit-timing/edit-timing.component';
import { HtmlEditComponent } from './html-edit/html-edit.component';
import { PermissionService } from 'src/app/auth/permission.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrl: './company.component.scss'
})
export class CompanyComponent implements DirtyCheck {
  title: string = "Company Settings";
  editForm: FormGroup;
  companyProvider: any = { data: {} }; // Initialize with an empty object
  logoUrl: any;
  uploadFilePath: string;
  hasError: boolean;
  currentTabIndex = 0;
  formValid: boolean = false;
  formSubmitting: boolean = false;
  isFormDirty: boolean = false;
  isLoading = true;
  displayTitle: any;
  permission: any;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private snackBarService: SnackBarService,
    public auth: AuthService,
    public dialog: MatDialog,
  private permissionService: PermissionService) {
    this.permission = permissionService.getPagePermission('company');
    this.hasError = false;
    this.uploadFilePath = environment.uplodFilePath;
    this.editForm = this.builder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      zipCode: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required,],
      logo: [''],
      domain: ['', Validators.required],
      businessTypesCheckBox1: [''],
      businessTypesCheckBox2: [''],
      businessTypesCheckBox3: [''],
      businessTypesCheckBox4: [''],
      businessTypesCheckBox5: [''],
      businessTypesName1: [''],
      businessTypesName2: [''],
      businessTypesName3: [''],
      businessTypesName4: [''],
      businessTypesName5: [''],
      businessTypesMinOrder1: [''],
      businessTypesMinOrder2: [''],
      businessTypesMinOrder3: [''],
      businessTypesMinOrder4: [''],
      businessTypesMinOrder5: [''],
      businessTypesMaxOrder1: [''],
      businessTypesMaxOrder2: [''],
      businessTypesMaxOrder3: [''],
      businessTypesMaxOrder4: [''],
      businessTypesMaxOrder5: [''],
      businessTypesMaxOrderSameDay1: [''],
      businessTypesMaxOrderSameDay2: [''],
      businessTypesMaxOrderSameDay3: [''],
      businessTypesMaxOrderSameDay4: [''],
      businessTypesMaxOrderSameDay5: [''],
      businessTypesInitialDelay1: [''],
      businessTypesInitialDelay2: [''],
      businessTypesInitialDelay3: [''],
      businessTypesInitialDelay4: [''],
      businessTypesInitialDelay5: [''],
      businessTypesDisplayOrder1: [''],
      businessTypesDisplayOrder2: [''],
      businessTypesDisplayOrder3: [''],
      businessTypesDisplayOrder4: [''],
      businessTypesDisplayOrder5: [''],
      OrderServiceCheckBox1: [''],
      OrderServiceCheckBox2: [''],
      OrderServiceCheckBox3: [''],
      OrderServiceCheckBox4: [''],
      OrderServiceCheckBox5: [''],
      OrderServiceName1: [''],
      OrderServiceName2: [''],
      OrderServiceName3: [''],
      OrderServiceName4: [''],
      OrderServiceName5: [''],
      OrderServiceDescription1: [''],
      OrderServiceDescription2: [''],
      OrderServiceDescription3: [''],
      OrderServiceDescription4: [''],
      OrderServiceDescription5: [''],
      OrderServiceOrderBefore1: [''],
      OrderServiceOrderBefore2: [''],
      OrderServiceOrderBefore3: [''],
      OrderServiceOrderBefore4: [''],
      OrderServiceOrderBefore5: [''],
      OrderServiceDisplayOrder1: [''],
      OrderServiceDisplayOrder2: [''],
      OrderServiceDisplayOrder3: [''],
      OrderServiceDisplayOrder4: [''],
      OrderServiceDisplayOrder5: [''],
    });
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

  }

  ngOnInit() {
    this.isLoading = true;
    this.loadData();


  }

  /**
   * default tab selection
   */
  getSelectedIndex(): number {
    return this.currentTabIndex;
  }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return this.isFormDirty;
  }
  /**
  * loads the company settings
  */
  loadData(): void {
    this.isLoading = true;
    let response = this.companyService.loadCompanySetting().subscribe({
      next: (response: any) => {
        if (response.successCode === 0) {
          this.companyProvider = response;
          this.logoUrl = this.uploadFilePath + 'images/logos/' + this.companyProvider.data.logo;

          this.editForm.patchValue(this.companyProvider);
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });

  }
  /**
   * 
   * populate the order service timing
   */

  viewDefaultTiming(data: any): void {
    const dialogRef = this.dialog.open(EditTimingComponent, {
      width: '500px', // Adjust the width as needed
      data: { id: data.id },
    });

  }

  /**
    * On save
    * if the details are valid call the API to update the changes.
    */
  actionSaveBussiness(): void {

    for (let i = 0; i < this.companyProvider.data.businessTypes.length; i++) {
      if (this.companyProvider.data.businessTypes[i].isValid === false) {
        this.companyProvider.data.businessTypes[i].isValid = 0;
      } else if (this.companyProvider.data.businessTypes[i].isValid === true) {
        this.companyProvider.data.businessTypes[i].isValid = 1;
      }
    }

    for (let i = 0; i < this.companyProvider.data.orderServices.length; i++) {
      if (this.companyProvider.data.orderServices[i].isValid === false) {
        this.companyProvider.data.orderServices[i].isValid = 0;
      } else if (this.companyProvider.data.orderServices[i].isValid === true) {
        this.companyProvider.data.orderServices[i].isValid = 1;
      }
    }

    if (this.editForm.status === 'INVALID') {
      this.formValid = false;
      this.isFormDirty = true;
    } else if (this.editForm.status === 'VALID') {
      this.formValid = true;     
      this.isFormDirty = false;


    }

    if (this.formValid === true) {
      // go
      this.formSubmitting = true;
      this.companyService.updateCompanySettings(this.companyProvider.data).subscribe(
        (response: any) => {
          this.formSubmitting = false;
          if (response.successCode === 0) {
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'Company details updated  successfully.',
            };
            this.snackBarService.openSnackBar(snckBarData);

          } else {
            let snackBarData: SnackBarData = {
              title: 'Failed!!!',
              type: 'error',
              message: 'Failed to Update'
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error',
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      );

    } else {
      let snackBarData: SnackBarData = {
        message: 'Please fill the required fields',
        title: 'Failed!!!',
        type: 'error',
      };
      this.snackBarService.openSnackBar(snackBarData);

    }
  }


  navBack(): void {
    this.router.navigate(['/company']);
  }
  actionOpenEditor(params: any): void {
    if (params === 'terms') {
      this.displayTitle = 'Terms and Conditions';
    } else if (params === 'faq') {
      this.displayTitle = 'FAQ';
    }
    const editorConfig = {
      'title': this.displayTitle,
      'data': this.companyProvider.data,
      'params': params,
    };

   
      const dialogRef = this.dialog.open(HtmlEditComponent, {
        width: '80%', // Adjust the width as needed
        data: editorConfig,
      });
  
    }
  
    getErrorDescription(controlName: string): string | null {
      var message = null;
      const errors = this.editForm.get(controlName)?.errors;
      if (errors) {
        if (errors['required'])
          message = "This field should be entered.";
        else if (errors['maxlength'])
          message = "Max length of the code is " + errors['maxlength'].requiredLength;
      }
      return message;
    }



   
    allowNumbersOnly(event: KeyboardEvent) {
      const char = String.fromCharCode(event.charCode);
      if (!/[0-9.]/.test(char)) {
        event.preventDefault();
      }
    }

    numbersOnly(event: KeyboardEvent) {
      const char = String.fromCharCode(event.charCode);
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    }
  


    
}
