<div class="order-card-container">
  <div class="card order-card">
    <div class="card-heading">
      <span>Orders</span>
    </div>
    <div class="order-div">
      <div class="order-amount-containr-div">
        <span>${{ data.total.amount | number : "1.2-2" }}</span>
      </div>
      <div class="order-line"></div>
      <div class="order-count">
        <span>{{ data.total.count }}</span>
      </div>
    </div>
  </div>
  <div class="card order-card">
    <div class="card-heading">
      <span>Delivered</span>
    </div>
    <div class="order-div">
      <div class="order-amount-containr-div">
        <span>${{ data.delivered.amount | number : "1.2-2" }}</span>
      </div>
      <div class="order-line"></div>
      <div class="order-count">
        <span>{{ data.delivered.count }}</span>
      </div>
    </div>
  </div>
  <div class="card order-card">
    <div class="card-heading">
      <span>Cancelled</span>
    </div>
    <div class="order-div">
      <div class="order-amount-containr-div">
        <span>${{ data.cancelled.amount | number : "1.2-2" }}</span>
      </div>
      <div class="order-line"></div>
      <div class="order-count">
        <span>{{ data.cancelled.count }}</span>
      </div>
    </div>
  </div>
  <div class="card order-card">
    <div class="card-heading">
      <span>Undelivered</span>
    </div>
    <div class="order-div">
      <div class="order-amount-containr-div">
        <span>${{ data.undelivered.amount | number : "1.2-2" }}</span>
      </div>
      <div class="order-line"></div>
      <div class="order-count">
        <span>{{ data.undelivered.count }}</span>
      </div>
    </div>
  </div>
</div>