import { Injectable } from "@angular/core";
import { RegionModel } from "./models/region.model";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RegionService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @param regionInfo
   * Calls the API to delete the record
   */
  delete(regionInfo: RegionModel): Observable<any> {
    return this.http.get(environment.baseUrl + "region/delete?id=" + regionInfo.id);

  }
  getRegionList(): any {
    return this.http.get<any>(environment.baseUrl + "region/get-list-ext");
  }

  getRegionListExt(requestData:RequestData): Observable<any> {

   
    return this.http.post(environment.wsPhp+'/region/get-list-ext', requestData);
  
}


  getId(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + "region/get?id=" + id);
    
  }
  /**
   *
   * @param userInfo
   * Calls the update API to insert/update the record
   */
  update(requestParams: any) {
    return this.http.post(
      environment.baseUrl + "region/save",
      requestParams
    );
  }
  
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.wsPhp + '/region/export', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }
  

}

