<div class="edit-page-root-container pickup-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                @if(permission.can_edit){
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
                }
            </div>
        </div>
        <div class="page-details ">
            <div class="detail-section">
                <div class="content-container">
                    <form class="edit-form" [formGroup]="editForm">
                        <div class="form-content-container">
                            <div class="common-ug-partion">
                                <mat-form-field class="code">
                                    <mat-label>Title</mat-label>
                                    <input matInput formControlName="title" maxlength="150" [readonly]="pickupInfo.id!==undefined && pickupInfo.id!==0">
                                    <mat-error [@if]="getErrorDescription('title')">{{getErrorDescription('title')}}</mat-error>
                                </mat-form-field>
                                <mat-form-field class="name">
                                    <mat-label>Display Order</mat-label>
                                    <input matInput formControlName="displayOrder" maxlength="50" type="number">
                                    <mat-error [@if]="editForm.controls['displayOrder'].hasError('required')">Display Order
                                        should
                                        be
                                        entered</mat-error>
                                    <mat-error [@if]="editForm.controls['displayOrder'].hasError('maxlength')">Maximum length is
                                        50.</mat-error>
                                </mat-form-field>
                            </div>
                        
                            
                            <mat-form-field class="kitchenRemarks">
                                <mat-label>Kitchen Remarks</mat-label>
                                <textarea matInput   maxlength="250" formControlName="kitchenRemarks"></textarea>
                                <mat-error [@if]="getErrorDescription('kitchenRemarks')">{{getErrorDescription('kitchenRemarks')}}</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field class="receiptRemarks">
                                <mat-label>Receipt Remarks</mat-label>
                                <textarea matInput  maxlength="250" formControlName="receiptRemarks"></textarea>
                            </mat-form-field>
                            <div class="status-toggle-container">
                                <span class="status-toggle-title">Valid</span>
                                <mat-slide-toggle formControlName="isValid" class="status-toggle"></mat-slide-toggle>
                            </div>
                        </div>
                    </form>

                </div>
            </div>


        </div>
    </div>
</div>