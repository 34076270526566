import { Injectable } from "@angular/core";
import { ShopDetails } from "./models/shopDetails.model";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class ShopsService {

  constructor(private http: HttpClient) {}
  getShopList(): any {
    return this.http.get<any>(environment.baseUrl + "shop/get-list");
  }

  getShopListExt(requestData:RequestData): Observable<any> {

   
    return this.http.post(environment.wsPhp+'/shop/get-list', requestData);
  
}

  getShopImportList(bodyParam: any): any {
    return this.http.post<any>(
      environment.baseUrl + "shop/get-list-for-import",
      bodyParam
    );
  }
  saveImportedShopDetails(selectedshops: any): any {
    return this.http.post<any>(
      environment.baseUrl + "shop/save-list",
      selectedshops
    );
  }
  getItemsList(bodyParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "saleitem/get-list",
      JSON.stringify(bodyParams)
    );
  }
  loadCompanySetting() {
    return this.http.get(environment.baseUrl + "company/get");
  }
  getAmenityList(): any {
    return this.http.get<any>(environment.baseUrl + "amenity/get-list");
  }

  getPickUpLocationList(): any {
    return this.http.get<any>(environment.baseUrl + "pickup-location-info/get-pickup-location");
  }
  updateShopDetails(bodyParam: any): any {
    return this.http.post<any>(environment.baseUrl + "shop/save", bodyParam);
  }
  getshopDetailsById(id: number): any {
    return this.http.get<any>(environment.baseUrl + "shop/get?id=" + id);
  }
  getRegionList(): any {
    return this.http.get<any>(environment.baseUrl + "region/get-list");
  }
  getDepartmentList(): any {
    return this.http.get<any>(environment.baseUrl + "department/get-list");
  }
  createPauseTime(bodyParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "shop/create-pause-time",
      bodyParams
    );
  }
  
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.wsPhp + '/shop/export', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

}


