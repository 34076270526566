<div class="modal-holiday-setting row ">
   
    <div class="holiday-title">
        <h2 class="holiday-title">Calendar Settings [{{pageTitle}}] </h2>
        <!-- <span class="closex fa fa-close" (click)="actionClose()"> </span> -->
    </div>

    <div fxLayout="row" class="custom_flex" style="background: #fff; margin-bottom: 10px;">
        <label class="title-label">Title<span style="color: red;">*</span></label>
        <input class="title-input" [(ngModel)]=holidayTitle  >
    </div>

    <div class="festieve-details-container">
       
        <div class="section-container dept-timings-section">
            <div fxLayout="row" class="custom_flex" style="background: #fff; padding-bottom: 10px;">
                <label class="section-title title-dept-timings">Departments & Timings</label>
                <div class="nodays-container">
                    <label class="title-label from-date">From</label>
                    <input class="from-date" disabled [(ngModel)]=startDateToDisplay>
                    <label class="title-label nodays-label">Days</label>

                    <input class="nodays-input" type='number' (keyup.enter)="reloadData()" min=1
                        [(ngModel)]=noDaysToShow  >
                    <button mat-flat-button color="primary" (click)="reloadData()" class="nodays-apply"  > Show</button>
                </div>
            </div>
            <span class='title-warning warning-dept-timings'>(All timings are in 24 hours format. eg. 08:00 ~ 20:30
                )</span>
            <div fxLayout="row" class="custom_flex department-date-time-list-container"
                style="background: #fff; height: 185px; ">
                
                <div class="dept-date-time-col department-name-container"
                    style=" padding: 10px; padding-top: 80px; background: lightgray;" fxLayout='column'>
                    <i *ngIf="noDaysToShow>1" class="fa fa-hand-o-right copy-dept-hours-to-all"
                        aria-hidden="true" (click)="applyToAll(deptFestiveTimings[0])"></i>
                    <ng-container *ngFor="let dept of ooDepartmentList">
                    <div *ngIf="dept.isCommon==0" fxLayout="row" class="dept-date-time-col-value" >
                        <label class="dept-name">{{dept.name}}</label>
                    </div>
                </ng-container>
                </div>
                <div fxLayout="row" class="custom_flex " style="background: #fff; overflow-x: auto;">

                    <div class="dept-date-time-col dept-time-container" fxLayout='column'
                        *ngFor="let fstDays of deptFestiveTimings; let dayIndex = index"
                        [ngClass]="{'festive-day':fstDays.festiveType==2, 'holiday':fstDays.festiveType==1}">
                        <div class="">
                            
                            <mat-form-field class="festiveController" style="width:190px;">
                                <mat-select [(ngModel)]="fstDays.festiveType">
                                    <mat-option *ngFor="let item of festiveTypes" [value]="item.id">{{ item.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" class="dept-time-col-title-container">
                            <label class="dept-date-time-col-title dept-time-title">{{fstDays.displayDate}}</label>
                        </div>

                        <div fxLayout="row" class="dept-date-time-col-value time-input-container"
                            *ngFor="let time of fstDays.festiveDeptTimings">
                            <input (keypress)="numberOnly($event)" placeholder="HH:MM"
                                (change)="onChangeDeptTime($event, fstDays, time,0)"
                                [ngStyle]="{'border': !(deptValidationError.date==fstDays.date && deptValidationError.departmentId==time.departmentId && deptValidationError.timeFromHasError)?  '1px solid gray' :  '1px solid red'}"
                                class="time-input time-input-from" [(ngModel)]="time.timeFrom"
                                [disabled]="fstDays.festiveType!==2">
                            <input (keypress)="numberOnly($event)" placeholder="HH:MM"
                                (change)="onChangeDeptTime($event, fstDays,time,1)"
                                [ngStyle]="{'border': !(deptValidationError.date==fstDays.date && deptValidationError.departmentId==time.departmentId && deptValidationError.timeToHasError)?  '1px solid gray' :  '1px solid red'}"
                                class="time-input time-input-to" [(ngModel)]="time.timeTo"
                                [disabled]="fstDays.festiveType!==2">
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="section-container service-timings-section">
            <div fxLayout="row" class="custom_flex"
                style="background: #fff; padding-top: 15px; padding-left: 0px; width: 100%;">
                <label class="section-title title-dept-timings"> SERVICE & TIME SLOTS </label><span
                    class="title-warning"> (From and To
                    times are in 24hr format. Preparation time in
                    minutes) </span>
            </div>
            <div fxLayout="row" class="custom_flex"
                style="background: #fff; padding-top: 5px; padding-left: 0px; width: 100%;">NOTE:
                <span class="title-warning"> Leave this portion empty to follow the shop's default settings </span>
            </div>
            <div class="form-group" *ngIf="ooServiceList.length>1">
                <mat-radio-group [(ngModel)]="selectedService">
                    <mat-radio-button *ngFor="let service of ooServiceList let i = index" [value]=service>
                        {{service.name}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <ng-container *ngFor="let oosrv of ooServiceList">
                <div *ngIf="oosrv.id==selectedService.id" fxLayout="row" class="custom_flex"
                    class="service-timings-container">
                    <table class="service-timings-table">
                        <thead>
                            <tr>
                                <th class=" day-title" disabled> Date <i *ngIf=" noDaysToShow>1"
                                    class="fa fa-hand-o-down copy-time-slot-to-all" aria-hidden="true"
                                    (click)="applyServiceTimeToAll(serviceFestiveTimings,oosrv)"></i></th>
                                <th class=" day-title"> From </th>
                                <th class=" day-title"> To </th>
                                <th class=" day-title"> Preparation Time </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let serviceTimings of serviceFestiveTimings; let dayIndex=index">
                                <td style="background: lightgray;">


                                    <input [(ngModel)]="serviceTimings.displayDate" class="slot-data slot-date"
                                        disabled>


                                </td>
                                <ng-container *ngFor=" let timeslot of serviceTimings.timings">
                                    <ng-container *ngIf="timeslot.serviceId==oosrv.id">
                                        <td >
                                            <div  >
                                                <!-- <i *ngIf="dayIndex==0 && noDaysToShow>1"
                                                    class="fa fa-hand-o-down copy-to-all" aria-hidden="true"
                                                    (click)="applyServiceTimeToAll(timeslot.festiveTimeSlots,oosrv)"></i> -->
                                                <input (keypress)="numberOnly($event)" placeholder="HH:MM"
                                                [disabled]="deptFestiveTimings[dayIndex].festiveType!==2"
                                                    (change)="onChangeServiceTime($event, deptFestiveTimings[dayIndex],timeslot.festiveTimeSlots[0],0)"
                                                    class="slot-data"
                                                    [ngStyle]="{'border': !(serviceValidationError!=undefined && serviceValidationError.date==serviceFestiveTimings[dayIndex].date && serviceValidationError.serviceId==timeslot.serviceId && serviceValidationError.timeFromHasError)?  '1px solid gray' :  '1px solid red'}"
                                                    
                                                    [(ngModel)]="timeslot.festiveTimeSlots[0].timeFrom">
                                            </div>
                                        </td>
                                        <td class="tdtime">
                                            <input (keypress)="numberOnly($event)" placeholder="HH:MM"
                                                [disabled]="deptFestiveTimings[dayIndex].festiveType!==2"
                                                (change)="onChangeServiceTime($event,  deptFestiveTimings[dayIndex],timeslot.festiveTimeSlots[0], 1)"
                                                class="slot-data" 
                                                [ngStyle]="{'border': !(serviceValidationError!=undefined && serviceValidationError.date==serviceFestiveTimings[dayIndex].date && serviceValidationError.serviceId==timeslot.serviceId && serviceValidationError.timeToHasError)?  '1px solid gray' :  '1px solid red'}"
                                                [(ngModel)]="timeslot.festiveTimeSlots[0].timeTo">
                                        </td>
                                        <td class="tdtime">
                                            <div style="display: flex;">
                                                <input (keypress)="numberOnly($event)" placeholder="MM"
                                                [disabled]="deptFestiveTimings[dayIndex].festiveType!==2"
                                                    [(ngModel)]="timeslot.festiveTimeSlots[0].delay" 
                                                    [ngStyle]="{'border': !( serviceValidationError!=undefined && serviceValidationError.date==serviceFestiveTimings[dayIndex].date && serviceValidationError.serviceId==timeslot.serviceId && serviceValidationError.delayHasError)?  '1px solid gray' :  '1px solid red'}"
                                                    class="slot-data">
                                                <span
                                                    style="width: 100%; display: flex; height: 35px; background: lightgray;font-weight: bold; align-items: center; padding-left:2px">min</span>
                                            </div>
                                        </td>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>

    <div fxLayout="row" class="custom_flex"
        style="background: #fff; padding: 10px;    float: right; padding-right: reset;">
        <button mat-flat-button color="warn" (click)="actionClose()" style="float: right; margin-right:10px"
           > <span> Close </span> </button>
        <button mat-flat-button color="primary" (click)="actionSave()" style="float: right;"
            [disabled]="this.saving" [ngStyle]="{'background-color':this.saving === true ? 'darkgray' : '#03a9f4' }" > <span *ngIf="this.saving"> Saving... </span>
            <span *ngIf="!this.saving"> Save </span> </button>
    </div>

</div>
