<app-masters-optionbar> </app-masters-optionbar>

<div class="amenity-component-container page-root-container">
        <div class="page-header">
                <div class="page-title"><img class="icon" src="assets/images/menu/svg/amenity.svg"><span class="title">Amenity</span></div>
                <div class="page-actions">
                        @if(permission.can_add){
                        <button mat-button color="primary" class="btn-add"
                                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                        }
                        @if(permission.can_export){
                                <button mat-button color="accent" class="btn-export" 
                                (click)="exportClicked()" 
                                [disabled]="isExporting">
                          <img class="download-icon" 
                               [src]="IsDownloading ? 'assets/images/download-animation/download-anim.svg' : 'assets/images/download-animation/download.svg'">
                          Export
                        </button>
                }
                        
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <app-search-filter #searchFilter [pagingData]="pagingData" 
                        [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
                        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
                </app-search-filter>
                <!-- @if(pagingData.length>0){ -->
                <!-- @if(!isHandset){ -->
                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="dataSource" >                    
                                        <ng-container matColumnDef="item" >
                                        <th  mat-header-cell *matHeaderCellDef class="item-header">#</th>
                                        <td mat-cell *matCellDef="let amenity; let i = index" class="item-column">{{
                                                (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                                </ng-container>
                                <ng-container matColumnDef="code">
                                        <th mat-sort-header="oo_amenity.code" (click)="sortData($event)" mat-header-cell *matHeaderCellDef class="code-header">Code</th>
                                        <td mat-cell *matCellDef="let amenity" class="code-column"> {{amenity.code}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                        <th mat-sort-header="oo_amenity.name"  (click)="sortData($event)"mat-header-cell *matHeaderCellDef> Name </th>
                                        <td mat-cell *matCellDef="let amenity"> {{amenity.name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="isValid">
                                        <th mat-sort-header="is_valid" (click)="sortData($event)" mat-header-cell *matHeaderCellDef class="valid-header"> Valid </th>
                                        <td mat-cell *matCellDef="let amenity" class="valid-column">@if(amenity.is_valid ===
                                                1){<mat-icon>check</mat-icon>
                                                }@else{}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                                <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                        (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                                <mat-icon>more_vert</mat-icon>
                                                        
                                                </a>
                                                <mat-menu #rowMenu>
                                                        @if(permission.can_delete){
                                                        <button mat-menu-item
                                                                (click)="onDelete(rowItem)">Delete</button>
                                                        }
                                                        @if(permission.can_edit){
                                                                <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                        }@else{
                                                                <button mat-menu-item (click)="onEdit(rowItem)">View</button>
                                                        }                                                        
                                                </mat-menu>
                                        
                                        </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row"
                                        *matRowDef="let amenity; columns: displayedColumns;"
                                        (click)="onRowSelected(amenity)" (dblclick)="onEdit(amenity)"
                                        [ngClass]=" {'row-selected' : amenity.id===selectedItem?.id}">
                                </tr>
                        </table>
                </section>
                <!-- }
                } -->
                @if(pagingData.length<=0){
                        <div class="no-data-container">
                                <img src="assets/images/no-data.png">
                                <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
                        </div>
                        }
        </div>

</div>