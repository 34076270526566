<div class="card sales-card" [class]="saleClass">
    <div class="card-heading">
        <span><b>{{title}}</b></span>
    </div>
    <div class="total-sales-div">
        <div class="sales_amount-containr-div">
            <span>${{salesData.total_amount | number:'1.2-2' }}</span>
        </div>
        <div class="order-line"></div>
        <div class="total-sales-count">
            <span>{{salesData.num_orders}}</span>
        </div>
    </div>
    <!-- @if(salesType=='today' || salesType=='week'){
    <div class="deliverd-status-div">
        <div class="delivery-div deliverd-div">
            <div class=" deliver-title">To deliver</div>
            <div class="sales-amount">
                <span>{{salesData.toDeliverTotalAmt | number:'1.2-2' }}</span>
            </div>
            <div class="deliverd-line"></div>
            <div class="sales-count">
                <span>{{salesData.toDeliverOrders}}</span>
            </div>
        </div>
        <div class="delivery-div deliverd-div">
            <div class="deliver-title">Delivered</div>
            <div class="sales-amount">
                <span>{{salesData.deliveredTotalAmt | number:'1.2-2' }}</span>
            </div>
            <div class="deliverd-line"></div>
            <div class="sales-count">
                <span>{{salesData.deliveredOrders}}</span>
            </div>
        </div>
    </div>
    } -->
</div>