import { Injectable } from "@angular/core";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }
  /**
     *
     * 
     * Calls the API to get  the list of orders from server
     */
  getOrdersList(bodyParams: any): any {
    return this.http.post<any>(
      environment.wsPhp + "/orderhistory/get-list",
      JSON.stringify(bodyParams)
    );
  }
  /**
   *
   * @param orderId
   * Calls the API to get  the order details from server
   */
  getOrderDetails(orderId: number): any {
    return this.http.get<any>(
      environment.baseUrl + "orderhistory/get-headers?orderId=" + orderId
    );
  }
  /**
   *
   * Calls the API to get  the shop list
   */
  getShopList(): any {
    return this.http.get<any>(environment.baseUrl + "shop/get-list");
  }
  getOrderSummery(requestData: any): any {
    return this.http.post<any>(environment.wsPhp + "/orderhistory/get-order-summary",requestData);
  }
  /**
   *
   * Calls the API to get  the status list
   */
  getStatusList(): any {
    return this.http.get<any>(environment.wsPhp + "/orderhistory/get-status-list");
  }
  /**
   *
   *  order list export 
   */
  exportOrderHistory(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.wsPhp + '/orderhistory/get-list-export', requestData, { headers, responseType: 'blob' })
      .pipe(
      //catchError(this.handleError)
    );

  }

}
