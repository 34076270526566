import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { UserComponent } from './pages/user/user.component';

import { FormDeactivateGuard } from './common/guards/form-deactivate.guard';
import { RegionComponent } from './pages/region/region.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { RegionEditComponent } from './pages/region/region-edit/region-edit.component';
import { PickupLocationComponent } from './pages/pickup-location/pickup-location.component';
import { PickupLocationEditComponent } from './pages/pickup-location/pickup-location-edit/pickup-location-edit.component';
import { DepartmentComponent } from './pages/department/department.component';
import { DepartmentEditComponent } from './pages/department/department-edit/department-edit.component';
import { AmenityComponent } from './pages/amenity/amenity.component';
import { AmenityEditComponent } from './pages/amenity/amenity-edit/amenity-edit.component';
import { ItemCategoryComponent } from './pages/item-category/item-category.component';
import { ItemCategoryEditComponent } from './pages/item-category/item-category-edit/item-category-edit.component';
import { ItemsComponent } from './pages/items/items.component';
import { ItemEditComponent } from './pages/items/item-edit/item-edit.component';
import { CompanyComponent } from './pages/company/company.component';
import { MastersalesReportComponent } from './pages/mastersales-report/mastersales-report.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { HolidayComponent } from './pages/holiday/holiday.component';
import { ImportItemComponent } from './pages/items/import-item/import-item.component';
import { ShopsComponent } from './pages/shops/shops.component';
import { ShopEditComponent } from './pages/shops/shop-edit/shop-edit/shop-edit.component';
import { ImportShopComponent } from './pages/shops/import-shop/import-shop.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomerEditComponent } from './pages/customer/customer-edit/customer-edit.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';

const routes: Routes = [
  { component: LoginComponent, path: 'login' },
  { component: AccessDeniedComponent, path: 'access-denied' },
  { component: DashboardComponent, path: '', canActivate: [AuthGuard] },
  { component: DashboardComponent, path: 'home', canActivate: [AuthGuard] },
  { component: RegionComponent, path: 'region', canActivate: [AuthGuard] },
  { component: UserComponent, path: 'users', canActivate: [AuthGuard] },
  { component: UserEditComponent, path: 'user-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: RegionEditComponent, path: 'region-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },

  { component: PickupLocationComponent, path: 'pickup-location', canActivate: [AuthGuard] },
  { component: PickupLocationEditComponent, path: 'pickup-location-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: DepartmentComponent, path: 'department', canActivate: [AuthGuard] },
  { component: DepartmentEditComponent, path: 'department-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: AmenityComponent, path: 'amenity', canActivate: [AuthGuard] },
  { component: AmenityEditComponent, path: 'amenity-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: ItemCategoryComponent, path: 'item-category', canActivate: [AuthGuard] },
  { component: ItemCategoryEditComponent, path: 'item-category-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: ItemsComponent, path: 'item', canActivate: [AuthGuard] },
  { component: ItemEditComponent, path: 'item-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: CompanyComponent, path: 'company', canActivate: [AuthGuard] },
  { component: CustomerComponent, path: 'customer', canActivate: [AuthGuard] },
  { component: CustomerEditComponent, path: 'customer-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },

  { component: MastersalesReportComponent, path: 'mastersalesreport', canActivate: [AuthGuard] },
  { component: OrdersComponent, path: 'orders', canActivate: [AuthGuard] },
  { component: HolidayComponent, path: 'calendar', canActivate: [AuthGuard] },
  { component: ImportItemComponent, path: 'import-item', canActivate: [AuthGuard] },
  { component: ShopsComponent, path: 'shop', canActivate: [AuthGuard] },
  { component: ShopEditComponent, path: 'shop-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: ImportShopComponent, path: 'import-shop', canActivate: [AuthGuard] },
  { component: CustomerComponent, path: 'customer', canActivate: [AuthGuard] },


];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [FormDeactivateGuard]
})
export class AppRoutingModule { }
