import { OrdersService } from '../orders.service';
import { Component, Inject ,Pipe, PipeTransform,} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-order-export',
  
  templateUrl: './order-export.component.html',
  styleUrl: './order-export.component.scss'
})
export class OrderExportComponent {
  orderExportDataOption: any[] = [{ label: 'Order Summary', value: 'all' }, { label: 'Item List', value: 'items' }];
  selectedValue: any = this.orderExportDataOption[0].value;
  constructor(
    private builder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OrderExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrdersService,// Inject your RewardService
    private snackBarService: SnackBarService
  ) {
    
   }
  
   closeDialog() {
    this.dialogRef.close();
  }
}
