<!-- <div class="portal-dashboard-page-wrapper" *ngIf="!detailProvider">
    <div fxLayout="column" fxLayout.lg="row" fxLayoutGap="16px">
      <div class="portal-widget" fxFlex="1 0 auto" fxFlex.lg="100">
        <div class="loader-center">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div> -->
  <div class="contaner">
    <span class="closeIconNew" (click)="actionCancel()" *ngIf="detailProvider">
      X
    </span>
  </div>
  <div *ngIf="detailProvider" class="main-container">
    <div class="width-100">
      <table class="table table-bordered invoice-table-custom">
        <tbody class="tbody">
          <tr>
            <th>Customer Name</th>
            <td>{{ detailProvider.customer_name }}</td>
            <th>Address</th>
            <td>{{ detailProvider.address }}</td>
          </tr>
          <tr>
            <th>City</th>
            <td>{{ detailProvider.city }}</td>
            <th>State</th>
            <td>{{ detailProvider.state }}</td>
          </tr>
          <tr>
            <th>Country</th>
            <td>{{ detailProvider.country }}</td>
            <th>Post Code</th>
            <td>{{ detailProvider.post_code }}</td>
          </tr>
          <tr>
            <th>Gender</th>
            <td>{{ detailProvider.gender }}</td>
            <th>DOB</th>
            <td>{{ detailProvider.dob }}</td>
          </tr>
          <tr>
            <th>Phone Number</th>
            <td>{{ detailProvider.phone_number }}</td>
            <th>Email</th>
            <td>{{ detailProvider.email }}</td>
          </tr>
          <tr>
            <th>Subscribed</th>
            <td>
              <span class="label label-{{ detailProvider.is_subscribed }}">
                {{ detailProvider.is_subscribed === 0 ? "NO" : "Yes" }}
              </span>
            </td>
            <th>Active</th>
            <td>
              <mat-slide-toggle [(ngModel)]="detailProvider.is_valid">
                {{ detailProvider.is_valid == 1 ? "Active" : "Inactive" }}
              </mat-slide-toggle>
              
            </td>
          </tr>
        </tbody>
      </table>
      <button mat-raised-button color="warn" (click)="actionCancel()" style="float: left;  margin-top: 5px;">Cancel </button>
      <button
                mat-raised-button
                color="primary"
                class="save"
                style="float: right !important; margin-top: 5px;"
                (click)="actionSave()"
              >
                Save
              </button>
    </div>
  </div>
  