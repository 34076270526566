<div class="edit-page-root-container item-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                @if(permission.can_edit){
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-raised-button color="primary" (click)="actionUpdate()"
                    [disabled]="savingRequest">{{savingRequest === true ? 'Saving..' : 'Save'}}</button>
                }
            </div>
        </div>


        <div class="page-details">

            <form class="edit-form" [formGroup]="editForm">
                <mat-tab-group style="background: #fff; width:800px;">
                    <mat-tab label="Details">
                        <div class="detail-section">
                            <div class="content-container">
                                <div style="display:flex; gap:20px; margin-top:10px;">
                                    <mat-form-field style="width:20%;">
                                        <mat-label>Code</mat-label>
                                        <input matInput maxlength="10" formControlName="code" readonly>
                                    </mat-form-field>


                                    <mat-form-field class="name" style="width:80%;">
                                        <mat-label>Name</mat-label>
                                        <input matInput maxlength="150" formControlName="name">
                                        <mat-error *ngIf="getErrorDescription('name')">{{getErrorDescription('name')}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div style="display:flex; gap:20px;max-width:730px;">
                                    <div  style="width:65%;">
                                        <mat-form-field class="description" style="width: 100%;" readonly>
                                            <mat-label>Item Description</mat-label>
                                            <textarea matInput  maxlength="250" formControlName="description"
                                                style="width:100%; height:108px;"></textarea>
                                        </mat-form-field>

                                        <mat-form-field class="description" style="width: 100%;" readonly>
                                            <mat-label>Nutritional Info</mat-label>
                                            <textarea matInput  maxlength="250" formControlName="nutritionalInfo"
                                                style="width:100%; height:108px;"></textarea>
                                        </mat-form-field>
                                        <!-- <mat-form-field style="width:100%;">
                                            <mat-select formControlName="ooItemCategoryId" placeholder="Category"
                                                [(ngModel)]="itemInfo.ooItemCategoryId">
                                                <mat-option value="">--select--</mat-option>
                                                <mat-option *ngFor="let item of categoryList" [value]="item.id">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>  -->
                                        <mat-form-field style="width:100%;">
                                            <mat-label>Category</mat-label>
                                            <input #categoryInput
                                                   type="text"
                                                   formControlName="category"
                                                   placeholder="Category"
                                                   matInput
                                                   [matAutocomplete]="categoryAutoComplete"
                                                   (input)="categoryFilter()"
                                                   (focus)="categoryFilter()"
                                                 >
                                                 <button (click)="clearCategory()"  matSuffix mat-icon-button aria-label="Clear">
                                                    <mat-icon
                                                        style="color: rgb(29 28 28 / 38%); font-weight: 500;  font-size: 22px;">close</mat-icon>
                                                </button>
                                            <mat-autocomplete [displayWith]="catAcDisplayText.bind(this)"  #categoryAutoComplete="matAutocomplete"   >
                                                @for (item of filteredCategory; track item) {
                                                  <mat-option [value]="item">
                                                    <span>{{item.name}}</span> 
                                                  </mat-option>
                                                }
                                              </mat-autocomplete>
                                              <mat-error *ngIf="editForm.controls['category'].hasError('required')">This field is invalid.</mat-error>
                                          </mat-form-field>
                                          
                                       
                                        <div style="display:flex; gap:20px;">
                                            <mat-form-field class="name" style="width:50%;">
                                                <mat-label>Display Order</mat-label>
                                                <input matInput formControlName="displayOrder">
                                            </mat-form-field>
                                            <mat-form-field class="name" style="width:50%">
                                                <mat-label>Price</mat-label>
                                                <input matInput formControlName="fixedPrice" readonly>
                                            </mat-form-field>
                                            
                                        </div>


                                        <div style="display:flex; gap:20px;">
                                            <mat-form-field appearance="fill" style="width: 50%;">
                                                <mat-label>Start Date</mat-label>
                                                <input matInput [matDatepicker]="startDatePicker" name="startDate"
                                                    formControlName="inactiveFrom">
                                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startDatePicker></mat-datepicker>
                                            </mat-form-field>
            
                                            <mat-form-field appearance="fill" style="width: 50%;">
                                                <mat-label>End Date</mat-label>
                                                <input matInput [matDatepicker]="endDatePicker" name="endDate"
                                                    formControlName="inactiveTo">
                                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #endDatePicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        
                                        
                                        
                                    </div>


                                    <div style="width:35%;">
                                        <table style="width:100%;border:1px solid rgb(150, 147, 147);height:397px;">
                                            <thead>
                                                <tr style="background-color: #424242; color: white;">
                                                    <th colspan="2"
                                                        style="text-align: center; font-size:18px; padding:10px; font-weight: normal;">
                                                        Availability</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let day of itemInfo.availability let i = index">
                                                    <td style="background: #00aff0; padding:10px; width:160px;"> {{day.day |
                                                        daysCustom}}</td>
                                                    <td style="text-align: right;"> <mat-checkbox
                                                            formControlName="day{{i}}" [(ngModel)]="day.isValid">
                                                        </mat-checkbox></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                        <table style="width:100%;border:1px solid rgb(150, 147, 147);margin-top: 10px;">
                                            <thead>
                                                <tr
                                                    style="background-color: #424242; color: white; border:1px solid #424242;">
                                                    <th colspan="2"
                                                        style="text-align: center;font-size:18px;padding:10px;font-weight: normal;">
                                                        Business Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let businessTypes of itemBuisnessTypes let i = index">
                                                    <td
                                                        style="background: #00aff0; padding:10px;  width:160px; word-wrap: break-word;">
                                                        {{businessTypes.name}}</td>
                                                    <td><mat-checkbox style="text-align: right;"
                                                            formControlName="businessTypes{{i}}"
                                                            [(ngModel)]="businessTypes.isValid"></mat-checkbox></td>
                                                </tr>
                                                <!-- <tr>
                                                    <td style="background: #00aff0; padding:10px; max-width: 150px; word-wrap: break-word;">CATERING PLATTERS [CD]</td>
                                                    <td><mat-checkbox checked></mat-checkbox></td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                
                               


                               

                            </div>

                          
                            <!-- <mat-checkbox checked>Valid</mat-checkbox> -->
                            <div class="status-toggle-container">
                                <span class="status-toggle-title">Valid</span>
                                <mat-slide-toggle formControlName="isValid" class="status-toggle"></mat-slide-toggle>
                            </div>

                            <div class="content-container"> 
                                <div class="imageUploadBlock">
                                    <p class="image-label"> Images  @if(permission.can_edit){ <button mat-raised-button color="secondary"
                                            class="add-image" (click)="actionAddImage()">Add Image</button>}@else{
                                                <button mat-raised-button color="secondary"
                                            class="add-image">Add Image</button>
                                            } </p>
                                    <div class="imageList">

                                        <div class="imgContainer" *ngFor="let image of imageListProvider let i = index">
                                            <span class="defult">
                                                @if(image.isDefault === 1){
                                                <mat-checkbox checked="image.isDefault"
                                                    (change)="changeDefaultImage($event, image, i)"> Default
                                                </mat-checkbox>
                                                }@else{
                                                <mat-checkbox (change)="changeDefaultImage($event, image, i)"> Default
                                                </mat-checkbox>

                                                }
                                            </span>
                                            @if(permission.can_edit){
                                            <span class="close" (click)="actionDelete(image)"> X </span>
                                            }@else{
                                                <span class="close"> x </span>
                                            }
                                            <div class="subImageBlock">
                                                <img [src]="imageListPath + image.fileName"
                                                    style="max-height: 100%; max-width:125px;">
                                            </div>
                                        </div>
                                        @if(permission.can_edit){
                                        <div class="imgContainer" *ngIf="isAddEnabled" (click)="inputFile.click()"
                                            style="cursor: pointer;">
                                            <span class="upload"> Upload </span>
                                            <div class="subImageBlock">
                                                <img [src]="previewImage" style=" max-height: 100%; max-width:125px;">
                                            </div>
                                        </div>}
                                        <input #inputFile type="file" name="file" style="display: none"
                                            (change)="importFile($event)">
                                    </div>

                                </div>
                            </div>
                        </div>

                      
                    </mat-tab>
                    @if(itemInfo.itemType === 2){
                    <mat-tab label="Customise">
                        <div class="Customise-section">
                        <mat-checkbox formControlName="isCustomizable">Allow Customization</mat-checkbox>

                        <mat-accordion>
                            <mat-expansion-panel
                                *ngFor="let customizationData of customizationDetail; let optIndex=index">
                                <mat-expansion-panel-header>
                                    <div
                                  
                                        style="display: flex; align-items: center; justify-content: center; padding-top: 10px; padding-bottom: 5px;margin-left:-11px;">
                                        <mat-checkbox  [checked]="customizationData.isSelected"
                                            [disabled]="!itemInfo.isCustomizable" (change)="onOptionChange($event, customizationData)"></mat-checkbox>
                                            
                                        <mat-panel-title
                                            style="text-align: center; padding-left: 10px; padding-right: 10px;">{{
                                            customizationData.displayName }}</mat-panel-title>
                                    </div>
                                </mat-expansion-panel-header>

                                <div
                                    style="display: grid; grid-template-columns: repeat(4, minmax(100px, 1fr)); gap: 20px; padding: 10px;">
                                    <div *ngFor="let custOptVal of customizationData.optionValue; let optValIndex=index"
                                        style="border: 1px solid #ccc; padding: 10px; background-color: #f5eae04f; position: relative; margin-bottom: 2px;">
                                        <div
                                            style="display: flex; flex-direction: column; height: 100%; position:relative;">
                                            <div style="position:absolute;">
                                                <mat-checkbox style="bottom:15px;right:15px;"
                                                    [checked]=isValueEnabled(custOptVal.id)
                                                    [disabled]="custOptVal.isDefault==1" (change)="onCheckboxChange($event, custOptVal.id)"></mat-checkbox>
                                            </div>
                                            <div style="flex-grow: 1; text-align: center;padding-top:15px;height:40px;">{{custOptVal.name}}</div>
                                            <div
                                                style="display: flex; justify-content: space-between; align-items: flex-end;">
                                                <img src="assets/images/icons/default.png" alt="Photo of a Shiba Inu"
                                                    style="width:60px; margin-right: 10px; align-self: flex-start;"
                                                    *ngIf="custOptVal.isDefault==1" />
                                                <img alt=""
                                                    style="width:60px; margin-right: 10px; align-self: flex-start;"
                                                    *ngIf="custOptVal.isDefault!=1" />
                                                <div>{{custOptVal.price | currency}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

















                    </mat-tab>

                    }

                    

                    @if(itemInfo.itemType === 3){
                        <mat-tab label="Combo" >
                            <div class="Customise-section">

                            <!-- <mat-checkbox formControlName="isCombo">Allow Customization</mat-checkbox> -->
    
                            <mat-accordion>
                                <mat-expansion-panel
                                    *ngFor="let comboData of comboDetail; let optIndex=index">
                                    <mat-expansion-panel-header>
                                        <!-- <div
                                      
                                            style="display: flex; align-items: center; justify-content: center; padding-top: 10px; padding-bottom: 5px;margin-left:-11px;"> -->
                                            <!-- <mat-checkbox  [checked]="comboData.isSelected"
                                        (change)="onComboOptionChange($event, comboData)" style="display:none;"></mat-checkbox> -->
                                                
                                            <mat-panel-title
                                                style="text-align: center; padding-left: 10px; padding-right: 10px;">{{
                                                comboData.displayName }}</mat-panel-title>
                                        <!-- </div> -->
                                    </mat-expansion-panel-header>
    
                                    <div
                                        style="display: grid; grid-template-columns: repeat(4, minmax(100px, 1fr)); gap: 20px; padding: 10px;">
                                        <div *ngFor="let comboOptVal of comboData.optionValue; let optValIndex=index"
                                            style="border: 1px solid #ccc; padding: 10px; background-color: #f5eae04f; position: relative; margin-bottom: 2px;">
                                            <div
                                                style="display: flex; flex-direction: column; height: 100%; position:relative;">
                                                <!-- <div style="position:absolute;">
                                                    <mat-checkbox style="bottom:15px;right:15px;"
                                                        [checked]=isComboValueEnabled(comboOptVal.id)
                                                        [disabled]="comboOptVal.isDefault==1" (change)="onComboCheckboxChange($event, comboOptVal.id)" style=""></mat-checkbox>
                                                </div> -->
                                                <div style="flex-grow: 1; text-align: center;padding-top:26px;height:40px;">{{comboOptVal.name}}</div>
                                                <div
                                                    style="display: flex; justify-content: space-between; align-items: flex-end;padding-top:12px;">
                                                    <img src="assets/images/icons/default.png" alt="Photo of a Shiba Inu"
                                                        style="width:60px; margin-right: 10px; align-self: flex-start;"
                                                        *ngIf="comboOptVal.isDefault==1" />
                                                    <img alt=""
                                                        style="width:60px; margin-right: 10px; align-self: flex-start;"
                                                        *ngIf="comboOptVal.isDefault!=1" />
                                                    <div>{{comboOptVal.price | currency}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
                        </mat-tab>
    
                        }





                </mat-tab-group>
            </form>

        </div>


    </div>
</div>
