<app-masters-optionbar> </app-masters-optionbar>
<div class="shop-component-container   page-root-container">
        <div class="page-header">
                <div class="page-title"><img class="icon" src="assets/images/menu/svg/shop.svg"><span
                                style=" margin-top: 3px;">Shop</span></div>
                <div class="page-actions">
                        @if(permission.can_export){
                        <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
                                [disabled]="IsDownloading">
                                <img class="download-icon"
                                        [src]="IsDownloading ? 'assets/images/download-animation/download-anim.svg' : 'assets/images/download-animation/download.svg'">
                                Export</button>
                        }
                        @if(permission.can_execute){
                        <button mat-button color="accent" class="btn-export" (click)="directToImportShop()">
                                <div style="display: flex; align-items: center;">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px"
                                                viewBox="0 0 24 24"
                                                style=" height: 16px; width: 16px;fill: rgb(58 35 35 / 85%);margin-right: 8px; transform:rotate(90deg);">
                                                <path
                                                        d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Z">
                                                </path>
                                        </svg>
                                        Import
                                </div>
                        </button>
                        }
                </div>
        </div>
    <div class="data-container ">
            <app-loader [show]="isLoading"></app-loader>

            <app-search-filter #searchFilter  [pagingData]="pagingData"
                    [pageSizeOptions]="[5,10,20,50,100]"  [filter]="filter"
                    (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
            </app-search-filter>
         
            <section tabindex="0" class="table-container">
                <table matSort mat-table [dataSource]="dataSource" > 
                        <ng-container matColumnDef="item">
                            <th mat-header-cell *matHeaderCellDef class="item-header">#</th>
                            <td mat-cell *matCellDef="let shop; let i = index" class="item-column">{{ (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                          </ng-container>
                            <ng-container matColumnDef="code">
                                <th mat-sort-header="code" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="code-header">Code</th>
                                <td mat-cell *matCellDef="let shop" class="code-column"> {{shop.code}}
                                </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-sort-header="name" mat-header-cell *matHeaderCellDef (click)="sortData($event)"> Name </th>
                            <td mat-cell *matCellDef="let shop"> {{shop.name}}
                            </td>
                    </ng-container>
                            <ng-container matColumnDef="hasBento">
                                    <th mat-sort-header="has_bento" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="bento-header"> Bento </th>
                                    <td mat-cell *matCellDef="let shop" class="bento-column">@if(shop.has_bento === 1){<mat-icon>check</mat-icon>
                                    }@else{}
                                    </td>
                            </ng-container>
                            <ng-container matColumnDef="hasSushi">
                                <th mat-sort-header="has_sushi" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="sushi-header"> Sushi </th>
                                <td mat-cell *matCellDef="let shop" class="sushi-column">@if(shop.has_sushi === 1){<mat-icon>check</mat-icon>
                                }@else{}
                                </td>
                                
                        </ng-container>
                        <ng-container matColumnDef="OOStatus">
                            <th mat-sort-header="has_pause_time" mat-header-cell *matHeaderCellDef class="status-header" (click)="sortData($event)"> Status </th>
                            <td mat-cell *matCellDef="let shop" class="status-column">
                                <div style="display: flex;">
                                        
                                        <button mat-raised-button 
                                                color="primary" 
                                                *ngIf="shop.has_pause_time === 0"  
                                                style="flex: 1; margin: 5px; background-color: #049306; min-width: 4px; max-width: 100px; max-height: 33px;" 
                                                (click)="changeOnSale(shop)">
                                            ON SALE
                                        </button>
                                        <button mat-raised-button 
                                                color="primary" 
                                                *ngIf="shop.has_pause_time === 1"  
                                                style="flex: 1; margin: 5px; background-color: #db250d; min-width: 4px; max-width: 100px; max-height: 33px;" 
                                                (click)="changePause(shop)">
                                            PAUSED
                                        </button>
                                    </div>
                                    
                                    

                              </td>                            
                    </ng-container>

                <!-- <ng-container matColumnDef="hasBento">
                    <td mat-cell *matCellDef="let shop" class="tickContent">
                      @if(shop.hasBento === 1){
                      <p style="text-align: center; width: 50%;">&#10004;</p>
                      }
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="hasSushi">
                    <th mat-header-cell *matHeaderCellDef>Sushi
                    </th>
                    <td mat-cell *matCellDef="let shop">
                      <p *ngIf="shop.hasSushi === 1" style="text-align: center; width: 50%;">&#10004;</p>
                    </td>
                  </ng-container>
  
                  <ng-container matColumnDef="OOStatus">
                    <th mat-header-cell *matHeaderCellDef>OOStatus
                    </th>
                    <td mat-cell *matCellDef="let shop">
                      <button mat-raised-button color="primary" *ngIf="shop.hasPauseTime ===0"  style="margin: 5px;background-color: #049306;min-width: 4px;max-height: 33px;">ON SALE</button>
                      <button mat-raised-button color="primary" *ngIf="shop.hasPauseTime ===1" style="margin: 5px;background-color: #db250d;min-width: 4px;max-height: 33px;">PAUSED</button>
                    </td>
                  </ng-container> -->
                           
                            <ng-container matColumnDef="action" stickyEnd>
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                        
                                            <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                    (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                    <mat-icon>more_vert</mat-icon>
                                            </a>
                                        
                                            <mat-menu #rowMenu>
                                                    <!-- <button mat-menu-item
                                                            (click)="onDelete(rowItem)">Delete</button> -->
                                                            @if(permission.can_edit){
                                                    <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                            }@else{
                                                         <button mat-menu-item (click)="onEdit(rowItem)">View</button>
                                                        } 
                                            </mat-menu>
                                    </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                            </tr>
                            <tr mat-row class="table-element-row" *matRowDef="let shop; columns: displayedColumns;"
                                    (click)="onRowSelected(shop)" (dblclick)="onEdit(shop)"
                                    [ngClass]=" {'row-selected' : shop.id===selectedItem?.id}">
                            </tr>
                    </table>
            </section>
            @if(pagingData.length<=0){
            <div class="no-data-container">
                    <img src="assets/images/no-data.png">
                    <span class="no-data-info">No data found. Please remove or redefine the filters if
                            any.</span>
            </div>
            }
    </div>

</div>
