import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemCategoryModel } from '../models/item-category.model';
import { ItemCategoryService } from '../item-category.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { DepartmentService } from '../../department/department.service';
import { PermissionService } from 'src/app/auth/permission.service';
@Component({
  selector: 'app-item-category-edit',
  templateUrl: './item-category-edit.component.html',
  styleUrl: './item-category-edit.component.scss',
  animations: [
    trigger('if', [
      // ... animation states and styles
    ])
  ]
})
export class ItemCategoryEditComponent {
  title: string = "New Item Category";
  editForm: FormGroup;
  categoryInfo: ItemCategoryModel = {
    isValid: 1, isDeleted: 0, isSystem: 0,
    parentId: 0,
    image: '',
    departmentId: 0,
    titleImage: '',
    showImageInTitle: 0,
    bgColor: '',
    fgColor: '',
    mainCategory:[],
    department:[]
   
  };
  isFormDirty: boolean = false;
  selectedFile: File | null = null;
  rootFilePath: string = environment.uplodFilePath;
  previewImage: any = '';
  @ViewChild('inputFile')
  inputFile!: ElementRef;
  @ViewChild("mainCategoryInput") mainCategoryInput! :  ElementRef<HTMLInputElement>;
  @ViewChild("departmentInput") departmentInput! :  ElementRef<HTMLInputElement>;
  parentId: number = 0;
  departmentList: any = [];
  isSubCategoryModel: boolean = false;
  categoryId: any;
  formCategoryList: any;
  filteredMainCategory: any;
  filteredDepartment: any;
  stateCtrl = new FormControl('');
  permission: any;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private categoryService: ItemCategoryService,
    private departmentService:DepartmentService,
    private snackBarService: SnackBarService,
    public auth: AuthService,
    public dialog: MatDialog,
    private permissionService: PermissionService,
    ) {
     this.permission = permissionService.getPagePermission('item-category');

    let categoryId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.categoryInfo.code, [Validators.required, Validators.maxLength(50)]),
      name: this.builder.control(this.categoryInfo.name, [Validators.required]),
      description: this.builder.control(this.categoryInfo.description),
      displayOrder: this.builder.control(this.categoryInfo.displayOrder, [Validators.pattern('^[0-9]*$')]),
      isSubCategory: [],
      parentId: { value: null, disabled: true },
      departmentId: { value: null, disabled: false },
      isValid: this.builder.control(this.categoryInfo.isValid ),
      mainCategory:this.builder.control("",[Validators.required]),
      department:this.builder.control("",[Validators.required]),
    });

  this.setControlForCategory(this.isSubCategoryModel);
   

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
      
    });
    
    if (categoryId !== null && parseInt(categoryId) !== 0) {
      this.setcategoryInfo(categoryId);
    }

  }

  ngOnInit(): void { 
    this.loadData();
    
  }

  setControlForCategory(isSubCategory:boolean){
  if(isSubCategory){
    this.editForm.controls["mainCategory"].enable();
    this.editForm.controls["department"].disable();
  }else{
    this.editForm.controls["mainCategory"].disable();
    this.editForm.controls["department"].enable();
  }
  }
  mainCategoryFilter(): void {
    const filterValue =this.mainCategoryInput.nativeElement.value.toLowerCase();
    this.filteredMainCategory= this.formCategoryList.filter((cat:any) => cat.name.toLowerCase().includes(filterValue));
    if(this.filteredMainCategory==undefined || this.filteredMainCategory.length<=0){
      this.editForm.controls['mainCategory'].setValue(undefined);
    }
  }

  mainCatDisplayText(mainCategory:any):string{
    
    return (mainCategory!=undefined)?mainCategory.name:this.mainCategoryInput.nativeElement.value;
  }


  setSelectedMainCategory(mainCategoryId: any) {
    const selectedMainCategory = this.formCategoryList.find((mainCategory:any) => mainCategory.id === mainCategoryId);
    if (selectedMainCategory) {
      this.editForm.controls['mainCategory'].setValue(selectedMainCategory);
    } else {
      console.warn(`Maincategory with ID ${mainCategoryId} not found`);
    }
  }
  

  departmentFilter(): void {
    const filterValue =this.departmentInput.nativeElement.value.toLowerCase();
    this.filteredDepartment= this.departmentList.filter((cat:any) => cat.name.toLowerCase().includes(filterValue));
    if(this.filteredDepartment==undefined || this.filteredDepartment.length<=0){
      this.editForm.controls['department'].setValue(undefined);
    }
  }

  departmentDisplayText(department:any):string{
    
    return (department!=undefined)?department.name:this.departmentInput.nativeElement.value;
  }


  setSelectedDepartment(departmentId: any) {
    const selectedDepartment = this.departmentList.find((department:any) => department.id === departmentId);
    if (selectedDepartment) {
      this.editForm.controls['department'].setValue(selectedDepartment);
    } else {
      console.warn(`Department with ID ${departmentId} not found`);
    }
  }
  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  /**
   * checks the form is edited
   */
  navBack(): void {

    this.router.navigate(['/item-category']);

  }

  /**
   * If tas is is null set an empty category info
   * if category id is not null call api to rtrive the info
   * @param categoryId 
   */
  setcategoryInfo(categoryId: any): void {

    let response = this.categoryService.getId(categoryId).subscribe({
      next: (response) => {
        if (response.successCode === 0) {
          this.isFormDirty = false;

          this.categoryInfo = response.data;
          if (this.categoryInfo.parentId !== 0) {
            this.isSubCategoryModel = true;
          }
          if (this.isSubCategoryModel === false) {
            this.actionIsSubCategory(false);
          } else {
            this.actionChangeParentCategory({'value': this.categoryInfo.parentId});
          } 
          this.editForm.patchValue(this.categoryInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
        this.setControlForCategory(this.isSubCategoryModel);
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.categoryInfo.id == 0) ? "New category" : "Editing : " + this.categoryInfo.name;
    return title;
  }

  

  /**
   * loads the department data based on the conditions
   */

  
  
  loadData(): void {
    // Load departments and categories
    // Set the selected department when a category is selected

    this.departmentService.getDepartmentList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.isFormDirty = false;
          this.departmentList = response.data;
        } else {
          console.error('Error in response:', response);
          let snackBarData: SnackBarData = {
            message: response.message || 'Failed to retrieve data. Please contact the system administrator.',
            title: 'Failed!!!',
            type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      (error: any) => {
        console.error('HTTP Error:', error);
        let snackBarData: SnackBarData = {
          message: 'Failed to retrieve data. Please contact the system administrator.',
          title: 'Failed!!!',
          type: 'error'
        };
        this.snackBarService.openSnackBar(snackBarData);
      }
    );
  
 this.categoryService.getCategoryList().subscribe(
  (response: any) => {
    if (response.successCode === 0) {
      this.isFormDirty = false;
      this.categoryId = response.data;
      
      // Assuming response.data is the list of categories
      // Filter out categories where parentId is not 0
      for (let index = 0; index < this.categoryId.length; index++) {
        if (this.categoryId[index].parentId !== 0) {
          this.categoryId.splice(index, 1);
          index--; // Adjust index after removal
        }
      }
      
      console.log(this.categoryId);
      // Create formCategoryList with categories where parentId is 0
      this.formCategoryList = this.categoryId.filter((e: { parentId: number; }) => e.parentId === 0);
      this.setSelectedMainCategory(this.categoryInfo.parentId);
      this.setSelectedDepartment(this.categoryInfo.departmentId);
    } else {
      console.error('Error in response:', response);
      let snackBarData: SnackBarData = {
        message: response.message || 'Failed to retrieve data. Please contact the system administrator.',
        title: 'Failed!!!',
        type: 'error'
      };
      this.snackBarService.openSnackBar(snackBarData);
    }
    
  },

);

      (error: any) => {
        console.error('HTTP Error:', error);
        let snackBarData: SnackBarData = {
          message: 'Failed to retrieve data. Please contact the system administrator.',
          title: 'Failed!!!',
          type: 'error'
        };
        this.snackBarService.openSnackBar(snackBarData);
      }
      // Load departments based on selected main category (initially empty)

    
  }    
  
  
  actionIsSubCategory(isSubCategoryModel: boolean) {
    if (isSubCategoryModel === true)  {
        // this.categoryInfo.departmentId = 0;
        this.editForm.controls['parentId'].setValidators(Validators.required);
        this.editForm.controls['parentId'].updateValueAndValidity();
        this.categoryInfo.parentId = this.categoryInfo.parentId;
    } else {
        this.categoryInfo.parentId = 0;
        // this.categoryInfo.departmentId = '';
        // this.apiService.getDepartmentList().subscribe(
        //   (response: any) => {
        //     this.departmentList = response.data;
        //   },
        //   (error: any) => {

        //   },
        // );
    }
    this.setControlForCategory(isSubCategoryModel);
    this.isFormDirty = false;

  }
  // tslint:disable-next-line:typedef
  actionChangeParentCategory(eventParam: { value: any; }) {
    // alert(eventParam.value);
    if (eventParam.value !== '') {
      this.categoryService.getId(eventParam.value).subscribe(
        (response: any) => { 
          this.categoryInfo.departmentId = response.data.departmentId;
          

        },
        (error: any) => {

        },
      );
    }
    this.isFormDirty = false;

  }
  actionImageRemove(): void {
    this.categoryInfo.image = '';
  }

  importFile(eventData: any): void {
    const file = <File>eventData.target.files[0];
    this.getBase64(file).then(
      (data: any) => {
        this.previewImage = data;
      }
    );

    const fd = new FormData;
    console.log(this.categoryInfo);
  fd.append('ooItemCategoryId', this.categoryInfo.id ? this.categoryInfo.id.toString() : '0'); // Convert id to string or provide a default value
    fd.append('image', file);
    this.categoryService.uploadItemCategoryImage(fd).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.isFormDirty = false;
          this.categoryService.getItemCategoryData(this.categoryInfo).subscribe(
            (res: any) => {
              if (res.successCode === 0) {
                this.isFormDirty = false;

                this.categoryInfo = res.data;
              }
            },
            (error: any ) => {

            },
          );
          let snackBarData: SnackBarData = {
            message: 'Uploaded Successfully',
            title: 'Success!!!',
            type: 'success'
          };
          this.snackBarService.openSnackBar(snackBarData);            
           this.resetFile();
        } else {
          let snackBarData: SnackBarData = {
            message: 'Failed to Upload',
            title: 'Failed!!!',
            type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);        }
      },
      (error: any) => {
        console.log(error);
      },
    );
  }

  resetFile(): void {
    this.inputFile.nativeElement.value = '';
  }


  getBase64(file: any): any {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /**
   * On save
   * if the details are valid call the API to update the changes.
   */
  save(): void {
    if (this.editForm.valid) {
      Object.assign(this.categoryInfo, this.editForm.value);

      // Assuming 1 for createdBy and updatedBy, 0 for isDeleted and isSystem
      // this.categoryInfo.parentId=this.categoryInfo.mainCategory.id;
      // this.categoryInfo.departmentId=this.categoryInfo.department.id;
      this.categoryInfo.parentId=this.editForm.controls['mainCategory'].value.id;
      this.categoryInfo.parentId=this.editForm.controls['department'].value.id;

      const activeUser: any = this.auth.getUser();

      if (this.categoryInfo.id === 0 || this.categoryInfo.id === undefined) {
        this.categoryInfo.createdBy = 1;
        this.categoryInfo.updatedBy = 1;
        this.categoryInfo.image = '';

      } else {
        this.categoryInfo.updatedBy = 1;
        this.categoryInfo.updatedBy = 1;

      }
   
      this.categoryInfo.bgColor = '#FFFFFFF';
      this.categoryInfo.fgColor = '#0000';


      this.categoryService.update(this.categoryInfo).subscribe({
        next: (response: any) => {
          if (response.successCode === 0) {
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + (this.categoryInfo ? this.categoryInfo.name : '') + ' has been updated successfully.',

            };
            this.snackBarService.openSnackBar(snckBarData);
            this.router.navigate(['item-category']);
          } else {
            let snackBarData: SnackBarData = {
              title: 'Failed!!!',
              type: 'error',
              message: 'Failed to Update'
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error',
          };
          this.snackBarService.openSnackBar(snackBarData);
        },
      });
    }
  }

  getErrorDescription(controlName: string): string | null {
    var message = null;
    const errors = this.editForm.get(controlName)?.errors;
    if (errors) {
      if (errors['required'])
        message = "This field should be entered.";
      else if (errors['maxlength'])
        message = "Max length of the code is " + errors['maxlength'].requiredLength;
    }
    return message;
  } 

  clearMainCategory() {
    this.editForm.controls['mainCategory'].setValue('');
  }

  clearDepartment() {
    this.editForm.controls['department'].setValue('');
  }
}

