<div class="user-component-container">
        <div class="page-header">
                <div class="page-title">User</div>
                <div class="page-actions">
                        <button mat-button color="primary" class="btn-add"
                                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                        <button mat-button color="accent" class="btn-export">
                                <mat-icon>save_alt</mat-icon>Export</button>
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <app-search-filter [pagingData]="pagingData"
                        [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
                        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
                </app-search-filter>
                @if(pagingData.length>0){
                @if(!isHandset){
                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="userList" (matSortChange)="sortData($event)">
                                <ng-container matColumnDef="loginid">
                                        <th mat-sort-header="loginid" mat-header-cell *matHeaderCellDef>Login Id</th>
                                        <td mat-cell *matCellDef="let user"> {{user.login_id}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                        <th mat-sort-header="name" mat-header-cell *matHeaderCellDef> Name </th>
                                        <td mat-cell *matCellDef="let user"> {{user.name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="email">
                                        <th mat-sort-header="email" mat-header-cell *matHeaderCellDef> Email </th>
                                        <td mat-cell *matCellDef="let user"> {{user.email}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="phone">
                                        <th mat-sort-header="phone" mat-header-cell *matHeaderCellDef> Phone </th>
                                        <td mat-cell *matCellDef="let user"> {{user.phone}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                                <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                        (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                        <mat-icon>more_vert</mat-icon>
                                                </a>

                                                <mat-menu #rowMenu>
                                                        <button mat-menu-item
                                                                (click)="onDelete(rowItem)">Delete</button>
                                                        <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                </mat-menu>
                                        </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row" *matRowDef="let user; columns: displayedColumns;"
                                        (click)="onRowSelected(user)" (dblclick)="onEdit(user)"
                                        [ngClass]=" {'row-selected' : user.id===selectedItem?.id}">
                                </tr>
                        </table>
                </section>
                }
                }@else{
                <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
                </div>
                }
        </div>

</div>
<app-user-summary [user]="selectedItem" (onEdit)="onEdit($event)" (onDelete)="onDelete($event)"></app-user-summary>