import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { DashboardEditComponent } from './dashboard-edit/dashboard-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from './dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { Color, NgxChartsModule, ScaleType, LegendPosition } from '@swimlane/ngx-charts';
import * as shap from "d3-shape"
interface Product {
  name: string;
  qty: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],

})

export class DashboardComponent implements OnInit {
  legendPosition: LegendPosition = LegendPosition.Below;
  todaySales: any = {
    toDeliverOrders: 0,
    toDeliverTotalAmt: 0,
    num_orders: 1,
    total_amount: 0,
    deliveredOrders: 0,
    deliveredTotalAmt: 0,


  };
  
  shape=shap.curveBasis
  tableData: Product[] = [];

  thisWeekSales = this.todaySales;
  thisMonthSales = this.todaySales;
  thisYearSales = this.todaySales;
  chartData: any;

  multi: any[] = [];





  colorScheme: Color = {
    domain: ['#a8385d', '#7aa3e5'], // Red for 2023, Blue for 2024
    name: 'custom',
    selectable: true,
    group: ScaleType.Ordinal
  };


  saleTypes = ['today', 'week', 'month', 'year'];

  constructor(private dashboardService: DashboardService, public dialog: MatDialog, private snackBar: MatSnackBar, private snackbarService: SnackBarService) { }

  ngOnInit(): void {

    // Populate tableData with data for the table

    // Today sales
    this.dashboardService.getTodaySales().subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.todaySales = response.data;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackbarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackbarService.openSnackBar(snackBarData);
      }
    });

    // This week sales
    this.dashboardService.getThisWeekSales().subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.thisWeekSales = response.data;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackbarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        // Handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackbarService.openSnackBar(snackBarData);
      }
    });

    // This month sales
    this.dashboardService.getThisMonthSales().subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.thisMonthSales  = response.data;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackbarService.openSnackBar(snackBarData);
        }

      },
      error: (error) => {
        // Handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackbarService.openSnackBar(snackBarData);
      }
    });

    // This year sales
    this.dashboardService.getThisYearSales().subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.thisYearSales = response.data;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackbarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        // Handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackbarService.openSnackBar(snackBarData);
      }
    });
    // TOP TEN PRODUCTS
    this.dashboardService.getTopTenProducts().subscribe({
      next: (response: any) => {
        if (response.status == 'SUCCESS') {
          this.tableData = response.data;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackbarService.openSnackBar(snackBarData);
        }
      },
      error: () => {
        // Handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackbarService.openSnackBar(snackBarData);
      }
    });

    // Current Year and Previous Year Data
    this.dashboardService.getDataByCurrentandPreviousYear().subscribe({
      next: (response: any) => {
        this.chartData = response.data;
        this.populateChartData();
      },
      error: (_error: any) => {
        // Handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackbarService.openSnackBar(snackBarData);
      }
    });
  }
  populateChartData(): void {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    const currentYearData = this.chartData.currentYear;
    const previousYearData = this.chartData.previousYear;

    // Create a map to store data by month
    const dataByMonth: { [key: string]: any } = {};

    // Initialize the data map with 0 values for both years
    [...currentYearData, ...previousYearData].forEach((dataPoint: any) => {
      if (!dataByMonth[dataPoint.name]) {
        dataByMonth[dataPoint.name] = {
          name: dataPoint.name,
          series: [
            { name: previousYear.toString(), value: 0 }, // 2023 value
            { name: currentYear.toString(), value: 0 }  // 2024 value
          ]
        };
      }
    });

    // Populate the map with previous year data
    previousYearData.forEach((dataPoint: any) => {
      if (dataByMonth[dataPoint.name]) {
        dataByMonth[dataPoint.name].series[0].value = dataPoint.value; // 2023 value
      }
    });

    // Populate the map with current year data
    currentYearData.forEach((dataPoint: any) => {
      if (dataByMonth[dataPoint.name]) {
        dataByMonth[dataPoint.name].series[1].value = dataPoint.value; // 2024 value
      }
    });

    // Convert the map to an array for the chart
    this.multi = Object.values(dataByMonth);
  }
  
  
  
  
  

  

}
