
  <h1 mat-dialog-title>
   Export Orders
  </h1>
  
  <div mat-dialog-content>
    <div class="option-main-container">
    
      <div class="option-container">
        <span class="option-title">Select export type</span>
        <mat-radio-group [(ngModel)]="selectedValue" class="grp-esport-type">
          <div *ngFor="let option of orderExportDataOption">
            <mat-radio-button [value]="option.value" class="rado-button-ele">
              {{option.label}}
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
        <!-- <div><p-radioButton class="rado-button-ele" styleClass='rado-button-ele' value="" label="Header with Items" [(ngModel)]="selectedValue" inputId="opt1"></p-radioButton></div>
       <div><p-radioButton class="rado-button-ele"   value="Items only" label="Items only" [(ngModel)]="selectedValue" inputId="opt2"></p-radioButton></div> -->
    
      </div> 
  </div>
  
  <div mat-dialog-actions class="mdc-action-container">
    <button  mat-button (click)="closeDialog()">Cancel</button>
    <button mat-raised-button color="primary"  [mat-dialog-close]="selectedValue">OK</button>
  </div>