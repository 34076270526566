import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ShopDetails } from "../../models/shopDetails.model";
import { CompanyDetailsProvider } from "../../models/company.details.model";
import { ShopsService } from "../../shops.service";
import { ThemePalette } from '@angular/material/core';
import { FloatLabelType } from '@angular/material/form-field';  // Import FloatLabelType
import { PermissionService } from "src/app/auth/permission.service";

@Component({
  selector: "app-shop-edit",
  templateUrl: "./shop-edit.component.html",
  styleUrls: ["./shop-edit.component.scss"]
})
export class ShopEditComponent implements OnInit {
  vaildType!: {
    isValid: 1;
    name: string;
    isDeleted: 0;
    amenityId: string;
    shopId: string;
  };
  amenityArray!: any[];
  shopAmenitiesList: any;
  amenityList: any;
  isFormDirty: boolean = false;
  filteredRegion:any;

  public paramId: any;
  public shopDetailForm: any;
  public previewImage: any = "assets/images/sample_image.png";
  formColor: ThemePalette = 'primary';  // Default value, can be 'primary', 'accent', or 'warn'
  formFontSize: number = 14;  // Example font size
  // formPlaceholders: String = "auto";
  // formRequired: Boolean = true;
  shopForm!: FormGroup;
  imageListPath: string = environment.uplodFilePath + "images/product/";
  shopDetailsProvider: any;
  regionProviderList: any;
  departListProvider: any;
  departmentLoaded: boolean = false;
  shoDetailsLoaded: boolean = false;
  departmentAndtiming: any;
  saveRequestList: any;
  istimingError: boolean = false;
  savingRequest: boolean = false;
  companydetailsProvider: any;
  isServiceFormatted: boolean = false;
  isServiceError: boolean = false;
  dayList: any;
  hourlistVar: any;
  errorList: any;
  shopHourList: any;
  tempData: any;
  blanPush: any;
  dayreUseValidation: any;
  minimumService: any = [];
  minDepartment: any = [];
  shopDepartmentListCopy: any;
  blankAppendDepartment: any;
  activeTab: number = 0;
  dummyData: any;
  shopHourEmpty: boolean = false;
  shopHourCount: number = 0;
  allItemList!: any[];
  disabledItemList!: any[];
  bodyParamsForItems: any;
  itemOffset: number = 0;
  itemLimit: number = 0;
  itemPerPage: number = 30;
  itemCurrentPage: number = 1;
  searchTearm: string = "";
  selectedRemoveList: any[] = [];
  selectedAvlList: any[] = [];
  itemsShowList: any[] = [];
  loadedItems: number = 0;
  availabeCount: number = 0;
  validValidation: any;
  incactiveFromDummy: any;
  inactiveToDummy: any;
  objectCopy: any;
  dateErrors!: any[];
  touchedDisabledList!: any[];
  pickupLocationList: any;
  pickupLocationArray!: any[];
  shopPickupLocationList: any;
  chosenPickUp: any;
  shopAdminUserData: any;
  isEditPassword!: boolean;
  formPlaceholders: FloatLabelType = 'auto';  // Default value, can be 'auto', 'always', or 'never'
  formRequired: boolean = true;  // Example required marker flag
  permission: any;
  constructor(private router: Router,
    private fb: FormBuilder,
    private snackBarService: SnackBarService,
    private shopsService: ShopsService,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    
  ) {
    this.permission = permissionService.getPagePermission('shop');
    
    this.validValidation = {
      fromDate: [],
      toDate: []
    };
   // this.paramId = this.route.snapshot.queryParamMap.get("id");
    this.paramId = this.route.snapshot.paramMap.get('id');

    this.regionProviderList = [{ id: " ", name: " " }];
  }

  ngOnInit(): void {
    this.isEditPassword = true;
    this.bodyParamsForItems = {
      search: {
        filters: {
          filter: [
            // {
            //   'column': 'oo_item_category_id',
            //   'operator': 'like',
            //   'value': '16'
            // }
          ],
          scope: {
            offset: "0",
            limit: 1000
          }
        },
        sort: []
      }
    };
    this.itemPageCalc();
    this.dayList = [
      {
        day: 1,
        label: "Monday"
      },
      {
        day: 2,
        label: "Tuesday"
      },
      {
        day: 3,
        label: "Wednesday"
      },
      {
        day: 4,
        label: "Thursday"
      },
      {
        day: 5,
        label: "Friday"
      },
      {
        day: 6,
        label: "Saturday"
      },
      {
        day: 7,
        label: "Sunday"
      }
    ];
    this.companyDetailsGet();
    this.loadDataProvider();
    this.serviceformatter();
    // this.amenityDetailsGet();
    this.getAllItemList();

    this.shopDetailForm = this.fb.group({
      code: [""],
      name: ["", Validators.required],
      description: [""],
      address: ["", Validators.required],
      city: [""],
      state: [""],
      country: [""],
      zipCode: [""],
      phone: ["", Validators.required],
      latitude: [""],
      longitude: [""],
      email: ["", Validators.required],
      ooRegionId: "",
      isValid: ["", Validators.required],
      account_number: [""],
      inactiveFrom: [""],
      inactiveTo: [""],
      companyTaxNo: [""],
      region:this.fb.control("",[Validators.required]),
      
    });
    this.shopDetailForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });
    this.touchedDisabledList = [];
    // this.searchRefresh();

  }

  @ViewChild("regionInput") regionInput! :  ElementRef<HTMLInputElement>;
  regionFilter(): void {
    const filterValue =this.regionInput.nativeElement.value.toLowerCase();
    this. filteredRegion= this.regionProviderList.filter((cat:any) => cat.name.toLowerCase().includes(filterValue));
    if(this. filteredRegion==undefined || this. filteredRegion.length<=0){
      this.shopDetailForm.controls['region'].setValue(undefined);
    }
  }
  regAcDisplayText(region:any):string{
    return (region!==undefined) ?region.name:this.regionInput.nativeElement.value;
  }

  /***
   * Set the selected cateogry
   */
  setSelectedRegion(regionId: any) {
    const selectedRegion = this.regionProviderList.find((reg:any) => reg.id === regionId);
    if (selectedRegion) {
      this.shopDetailForm.controls['region'].setValue(selectedRegion);
    } else {
      console.warn(`Region with ID ${regionId} not found`);
    }
  }

  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  setItemDisabledDateValidation(event: any, type: string, index: number): void {
    console.log(event.value);
    console.log(type);
    console.log(index);
    const saver = {
      shopId: this.shopDetailsProvider.inactiveItems[index].shopId,
      ooSaleItemId: this.shopDetailsProvider.inactiveItems[index].ooSaleItemId
    };
    this.touchedDisabledList.push(saver);
  }


  setstartDateValidation(event: any, location: string): void {
    if (location === "from") {
      this.incactiveFromDummy = event.value;
    } else if (location === "to") {
      this.inactiveToDummy = event.value;
    }
    console.log(event);
    this.validValidation = {
      fromDate: [],
      toDate: []
    };
    if (this.incactiveFromDummy !== "" && this.inactiveToDummy !== "") {
      const currentDate = new Date();
      const fromDate = this.incactiveFromDummy;
      const toDate = this.inactiveToDummy;
      if (fromDate !== "") {
        if (currentDate >= fromDate) {
          this.validValidation.fromDate.push(
            "From date should be greater than current date"
          );
        }
        if (fromDate !== "" && toDate !== "" && toDate <= fromDate) {
          this.validValidation.toDate.push(
            "To Date should be greater than from date"
          );
        }
      }
    }
  }

  refetchSaleItem(): void {
    this.itemCurrentPage = 1;
    this.itemPageCalc();
    this.getAllItemList();
  }

  searchRefresh(): void {
    this.itemPageCalc();
    this.getAllItemList();
  }

  itemPageCalc(): void {
    this.bodyParamsForItems.search.filters.filter = [
      {
        column: "name",
        operator: "like",
        value: this.searchTearm
      }
    ];
    if (this.itemCurrentPage === 1) {
      this.itemOffset = 0;
    } else {
      this.itemOffset = (this.itemCurrentPage - 1) * this.itemPerPage + 1;
    }
    this.bodyParamsForItems.search.filters.scope.offset = this.itemOffset;
    this.bodyParamsForItems.search.filters.scope.limit = this.itemPerPage;
  }

  previousPage(): void {
    if (this.itemCurrentPage !== 1) {
      this.itemCurrentPage--;
      this.searchRefresh();
    }
  }
  /**
   * checks the form is edited
   */
  navBack(): void {

    this.router.navigate(['/shop']);

  }
    /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
    getTitle(): string {


      let title = (this.shopDetailsProvider==undefined || this.shopDetailsProvider.id == 0) ? "New Shop" : "Editing : " + this.shopDetailsProvider.name;
      return title;
    }
  nextPage(): void {
    if (this.availabeCount > this.loadedItems) {
      this.itemCurrentPage++;
      this.searchRefresh();
    }
  }

  actionMoveEnable(): void {
    for (let index = 0; index < this.selectedRemoveList.length; index++) {
      const removeItem = this.selectedRemoveList[index];
      const removeIntex = this.shopDetailsProvider.inactiveItems.findIndex(
        function (el:any) {
          return el.ooSaleItemId === removeItem.ooSaleItemId;
        }
      );
      if (removeIntex !== -1) {
        this.shopDetailsProvider.inactiveItems.splice(removeIntex, 1);
      }
    }
    this.selectedRemoveList = [];
    this.getAllItemList();
  }

  actionMoveToDisable(): void {
    for (let index = 0; index < this.selectedAvlList.length; index++) {
      const loopData = this.selectedAvlList[index];
      // console.log(loopData);
      const exist = this.shopDetailsProvider.inactiveItems.filter(function (el:any) {
        return el.ooSaleItemId === loopData.ooSaleItemId;
      });
      if (exist.length === 0) {
        // no data just push
        let dummyItem = loopData;
        dummyItem.inactiveFrom = new FormControl("");
        dummyItem.inactiveTo = new FormControl("");
        dummyItem.error = [];
        this.shopDetailsProvider.inactiveItems.push(dummyItem);
      }
    }
    this.selectedAvlList = [];
    this.getAllItemList();
    /// console.log(this.shopDetailsProvider.inactiveItems);
  }

  getAllItemList(): void {
    this.shopsService
      .getItemsList(this.bodyParamsForItems)
      .subscribe((response: any) => {
        if (response.successCode === 0) {
          this.isFormDirty = false;
          this.availabeCount = response.recordsCount;
          if ((this.allItemList = response.data)) {
            this.formatItemList();
          }
          this.loadedItems =
            (this.itemCurrentPage - 1) * this.itemPerPage +
            response.data.length;
        }
      });
      
  }

  isChecked(item: any): boolean {
    const isExistData = this.selectedAvlList.filter(function (el) {
      return el.ooSaleItemId === item.id;
    });
    return isExistData.length === 0 ? false : true;
  }

  departmentStatusChange(event: any, iIndex: number): void {
    if (event.checked === false) {
      for (
        let index = 0;
        index < this.departmentAndtiming[iIndex].shopHoursList.length;
        index++
      ) {
        this.departmentAndtiming[iIndex].shopHoursList[index].from = "";
        this.departmentAndtiming[iIndex].shopHoursList[index].to = "";
      }
    }
  }

  formatItemList(): any {
    // alert(this.shoDetailsLoaded);
    this.itemsShowList = [];
    if (this.shoDetailsLoaded === true) {
      for (let index = 0; index < this.allItemList.length; index++) {
        const element = this.allItemList[index];
        const isExist = this.shopDetailsProvider.inactiveItems.filter(function (
          el:any
        ) {
          return el.ooSaleItemId === element.id;
        });
        if (isExist.length > 0) {
        } else {
          this.itemsShowList.push(element);
        }
      }
    } else {
      setTimeout(() => {
        this.formatItemList();
      }, 1000);
    }
    // console.log(this.itemsShowList);
  }

  onChangeAvaliableList(event: any, index: number, item: any): void {
    if (event.checked === true) {
      // to add list
      const current = this.selectedAvlList.filter(function (el) {
        return el.ooSaleItemId === item.id;
      });
      if (current.length === 0) {
        // to be enter
        this.selectedAvlList.push({
          shopId: this.shopDetailsProvider.shopId,
          ooSaleItemId: item.id,
          itemCategoryName: item.itemCategoryName,
          name: item.name,
          fixedPrice: item.fixedPrice
        });
      }
    } else if (event.checked === false) {
      // remove list
      const removeIntex = this.selectedAvlList.findIndex(function (el) {
        return el.ooSaleItemId === item.id;
      });
      // console.log(removeIntex);
      if (removeIntex !== -1) {
        this.selectedAvlList.splice(removeIntex, 1);
      }
    }
    // console.log(this.selectedAvlList);
  }

  onChangeRemoveList(event: any, index: number, item: any): void {
    if (event.checked === true) {
      // to add list
      const current = this.selectedRemoveList.filter(function (el) {
        return el.ooSaleItemId === item.ooSaleItemId;
      });
      if (current.length === 0) {
        // to be enter
        this.selectedRemoveList.push({
          shopId: this.shopDetailsProvider.shopId,
          ooSaleItemId: item.ooSaleItemId,
          itemCategoryName: item.itemCategoryName,
          name: item.name,
          fixedPrice: item.fixedPrice
        });
      }
    } else if (event.checked === false) {
      // remove list
      const removeIntex = this.selectedRemoveList.findIndex(function (el) {
        return el.ooSaleItemId === item.ooSaleItemId;
      });
      if (removeIntex !== -1) {
        this.selectedRemoveList.splice(removeIntex, 1);
      }
    }
  }

  serviceformatter(): void { }
  companyDetailsGet(): void {
    this.shopsService.loadCompanySetting().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.companydetailsProvider = response.data;
        }
        this.isFormDirty = false;

      },
      (error: any) => { }
    );
  }
  amenityDetailsGet(): void {
    this.shopsService.getAmenityList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.amenityList = response.data;
          const vaildShopAmenities =
            this.shopAmenitiesList !== undefined
              ? this.shopAmenitiesList.filter((sa:any) => sa.isValid === 1)
              : undefined;
          this.amenityArray = [];
          for (let i = 0; i < this.amenityList.length; i++) {
            if (this.amenityList[i].isValid === 1) {
              const amenities = {
                isValid:
                  vaildShopAmenities !== undefined &&
                    vaildShopAmenities.findIndex(
                      (vs:any) => vs.amenityId === this.amenityList[i].id
                    ) > -1
                    ? 1
                    : 0,
                smallIcon: this.amenityList[i].smallIcon,
                name: this.amenityList[i].name,
                isDeleted: this.amenityList[i].isDeleted,
                amenityId: this.amenityList[i].id,
                shopId: this.shopDetailsProvider.shopId
              };
              this.amenityArray.push(amenities);
            }
          }
        }
      },
      (error: any) => { }
    );
  }

  /**
   * Contact less option or pick up option 
   * master data
   * all list
   */
  pickUpLocationsGet() {
    this.shopsService.getPickUpLocationList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.pickupLocationList = response.data;
          this.pickupLocationArray = [];
          this.pickupLocationList.forEach((element:any) => {
            let shopPickUpLocation = this.shopPickupLocationList.find((spo:any) => spo.ooPickupLocationId === element.id);

            const pickUpOption = {
              pickUpLocationId: element.id,
              title: element.title,
              kitchenRemarks: element.kitchenRemarks,
              warnText: element.warnText,
              receiptRemarks:element.receiptRemarks,
              shopId: this.shopDetailsProvider.shopId,
              isShopPickupLocation: (shopPickUpLocation !== undefined) ? true :  ((this.shopPickupLocationList.length === 0) && (element.isDefault === 1)) ? true : false,
              isDefault: ((shopPickUpLocation !== undefined) && (shopPickUpLocation.isDefault === 1)) ? true : ((this.shopPickupLocationList.length === 0) && (element.isDefault === 1)) ? true : false
            };
            this.pickupLocationArray.push(pickUpOption);
          });
          this.isFormDirty = false;

        }
      },
      (error: any) => { }
    );
  }

  /**
   * @param event
   * @param data 
   * the radio button should be unselected,
   * whenever the checkbox is unselected.
   */
  updateCheckedOptions(event: { checked: any; }, data: { isDefault: boolean; }) {
    if (!event.checked) {
      data.isDefault = false;
    }
  }

  /**
   * @param event 
   * @param data 
   * @param pickupLocationArray 
   * all default pick up options  of the shop, set as false.
   * then, set default option as selected now.
   */
  defaultPickUpChange(event: { source: { checked: any; }; }, data: { isDefault: any; }, pickupLocationArray: any) {
    this.unSetAllDefaultPickUp(pickupLocationArray);
    data.isDefault = event.source.checked;
  }

  /**
   * 
   * @param pickupLocationArray 
   * @returns 
   * unset all default pick up options of the shop ;
   */
  unSetAllDefaultPickUp(pickupLocationArray: any) {
    for (let elem of pickupLocationArray) {
      if (elem.isDefault) {
        elem.isDefault = false;
        return;
      }
    }
  }

  actionUpdate(): void {
    this.shopsService.updateShopDetails(this.shopDetailsProvider).subscribe(
      (response: any) => {            
        this.isFormDirty = false;
      },

      (error: any) => { }
    );
    // action update scripts
  }

  actionEditPassword() {
    this.isEditPassword = !this.isEditPassword;  
  }

  loadDataProvider(): void {
    this.shopsService.getshopDetailsById(this.paramId).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.shoDetailsLoaded = true;
          this.shopDetailsProvider = response.data;
          this.isFormDirty = false;
          this.shopAdminUserData = response.data.shopAdminUser;
          this.isEditPassword = true;
          this.formatShopInactiveTiming();
          this.initialDateFormat();
          this.shopAmenitiesList = response.data.shopAmenities;
          this.shopPickupLocationList = response.data.shopPickupLocation;
          this.amenityDetailsGet();
          this.pickUpLocationsGet()
          this.loadRegionProviderList();
          // this.formatItemList();

          for (
            let index = 0;
            index < this.shopDetailsProvider.shopDepartmentsList.length;
            index++
          ) {
            if (
              this.shopDetailsProvider.shopDepartmentsList[index]
                .shopHoursList !== null
            ) {
              this.shopHourList = JSON.stringify(
                this.shopDetailsProvider.shopDepartmentsList[index]
                  .shopHoursList
              );
              this.shopDetailsProvider.shopDepartmentsList[
                index
              ].shopHoursList = [];
              this.shopHourList = JSON.parse(this.shopHourList);
              for (let index2 = 0; index2 < 7; index2++) {
                this.tempData = this.shopHourList.filter(function (el:any) {
                  return el.weekDay === index2 + 1;
                });
                // const data = this.shopDetailsProvider.shopDepartmentsList[index].shopHoursList[index2];
                // console.log(this.tempData);
                if (this.tempData.length === 0) {
                  const pusher = {
                    departmentId: this.shopDetailsProvider.shopDepartmentsList[
                      index
                    ].departmentId,
                    from: "",
                    shopId: this.shopDetailsProvider.shopDepartmentsList[index]
                      .shopId,
                    weekDay: index2 + 1,
                    to: "",
                    error: []
                  };
                  this.shopDetailsProvider.shopDepartmentsList[
                    index
                  ].shopHoursList.push(pusher);
                } else {
                  let start = this.tempData[0].from;
                  let end = this.tempData[0].to;
                  start = start.slice(0, -3);
                  end = end.slice(0, -3);
                  this.tempData[0].from = start;
                  this.tempData[0].to = end;
                  this.tempData[0].error = [];
                  this.shopDetailsProvider.shopDepartmentsList[
                    index
                  ].shopHoursList.push(this.tempData[0]);
                }
              }
            }
          }
        }


        this.shopsService.getDepartmentList().subscribe(
          (res: any) => {
            if (res.successCode === 0) {
              this.departListProvider = res.data;
              this.formatArrays();
              this.isFormDirty = false;

            }
          },
          (err: any) => { }
        );
      },
      (error: any) => { }
    );
  }

  errorDisable(): void {
    this.istimingError = false;
    this.isServiceError = false;
  }

  formatShopInactiveTiming(): void {
    for (
      let index = 0;
      index < this.shopDetailsProvider.inactiveItems.length;
      index++
    ) {
      this.shopDetailsProvider.inactiveItems[index].error = [];
      if (this.shopDetailsProvider.inactiveItems[index].inactiveFrom !== null) {
        this.shopDetailsProvider.inactiveItems[
          index
        ].inactiveFrom = new FormControl(
          new Date(this.shopDetailsProvider.inactiveItems[index].inactiveFrom)
        );
      } else {
        this.shopDetailsProvider.inactiveItems[
          index
        ].inactiveFrom = new FormControl("");
      }
      if (this.shopDetailsProvider.inactiveItems[index].inactiveTo !== null) {
        this.shopDetailsProvider.inactiveItems[
          index
        ].inactiveTo = new FormControl(
          new Date(this.shopDetailsProvider.inactiveItems[index].inactiveTo)
        );
      } else {
        this.shopDetailsProvider.inactiveItems[
          index
        ].inactiveTo = new FormControl("");
      }
    }
  }

  formatArrays(): any {
    this.shopDepartmentListCopy = Object.assign(
      this.shopDetailsProvider.shopDepartmentsList
    );
    for (let index = 0; index < this.departListProvider.length; index++) {
      const hardDepartmentListItem = Object.assign(
        this.departListProvider[index]
      );
      const isDepartmentPresents = this.shopDepartmentListCopy.findIndex(
        function (e:any) {
          return e.departmentId === hardDepartmentListItem.id;
        }
      );
      if (isDepartmentPresents !== -1) {
      } else {
        this.blankAppendDepartment = {
          departmentId: hardDepartmentListItem.id,
          isCommon: hardDepartmentListItem.isCommon,
          isDeleted: 0,
          isValid: 0,
          shopHoursList: [],
          shopId: this.shopDetailsProvider.shopId,
          departmentData: hardDepartmentListItem
        };
        for (let day = 0; day < 7; day++) {
          this.blankAppendDepartment.shopHoursList.push({
            departmentId: hardDepartmentListItem.id,
            from: "",
            shopId: this.shopDetailsProvider.shopId,
            to: "",
            weekDay: day + 1
          });
        }
        if (hardDepartmentListItem.isCommon !== 1) {
          this.shopDepartmentListCopy.push(this.blankAppendDepartment);
        }
      }
    }
    this.shopDetailsProvider.shopDepartmentsList = this.shopDepartmentListCopy;
    this.departmentAndtiming = [];
    for (let index = 0; index < this.departListProvider.length; index++) {
      for (
        let j = 0;
        j < this.shopDetailsProvider.shopDepartmentsList.length;
        j++
      ) {
        if (
          this.departListProvider[index].id ===
          this.shopDetailsProvider.shopDepartmentsList[j].departmentId &&
          this.shopDetailsProvider.shopDepartmentsList[j].isCommon === 0
        ) {
          const length = this.departmentAndtiming.length;
          this.departmentAndtiming[
            length
          ] = this.shopDetailsProvider.shopDepartmentsList[j];
          this.departmentAndtiming[
            length
          ].departmentData = this.departListProvider[index];
        }
      }
    }
    for (
      let index = 0;
      index < this.companydetailsProvider.orderServices.length;
      index++
    ) {
      for (
        let index2 = 0;
        index2 < this.shopDetailsProvider.shopServicesList.length;
        index2++
      ) {
        if (
          this.companydetailsProvider.orderServices[index].id ===
          this.shopDetailsProvider.shopServicesList[index2].serviceId
        ) {
          this.shopDetailsProvider.shopServicesList[
            index
          ].serviceData = this.companydetailsProvider.orderServices[index];
          this.shopDetailsProvider.shopServicesList[
            index
          ].serviceName = this.companydetailsProvider.orderServices[index].name;
        }
      }
    }
    for (
      let index = 0;
      index < this.companydetailsProvider.orderServices.length;
      index++
    ) {
      const data = JSON.stringify(
        this.companydetailsProvider.orderServices[index]
      );
      const filterInput = JSON.parse(data);
      const filterData = this.shopDetailsProvider.shopServicesList.filter(
        function (el:any) {
          return el.serviceId === filterInput.id;
        }
      );

      if (filterData.length > 0) {
        //
      } else {
        const topusher = {
          shopId: this.shopDetailsProvider.shopId,
          serviceId: filterInput.id,
          advOrderDays: filterInput.advOrderDays,
          isValid: 0,
          shopServiceTimeSlotsList: [],
          serviceData: filterInput,
          serviceName: filterInput.name
        };
        this.shopDetailsProvider.shopServicesList.push(topusher);
      }
    }
    for (
      let index = 0;
      index < this.shopDetailsProvider.shopServicesList.length;
      index++
    ) {
      if (index === 0) {
        this.shopDetailsProvider.shopServicesList[index].isActive = true;
      } else {
        this.shopDetailsProvider.shopServicesList[index].isActive = false;
      }
    }

    // formatting for view

    for (
      let index = 0;
      index < this.shopDetailsProvider.shopServicesList.length;
      index++
    ) {
      for (
        let index2 = 0;
        index2 <
        this.shopDetailsProvider.shopServicesList[index]
          .shopServiceTimeSlotsList.length;
        index2++
      ) {
        const data = this.shopDetailsProvider.shopServicesList[index]
          .shopServiceTimeSlotsList[index2];
        let start = data.from;
        let end = data.to;
        start = start.slice(0, -3);
        end = end.slice(0, -3);
        this.shopDetailsProvider.shopServicesList[
          index
        ].shopServiceTimeSlotsList[index2].from = start;
        this.shopDetailsProvider.shopServicesList[
          index
        ].shopServiceTimeSlotsList[index2].to = end;
      }
    }

    for (
      let index = 0;
      index < this.shopDetailsProvider.shopDepartmentsList.length;
      index++
    ) {
      if (
        this.shopDetailsProvider.shopDepartmentsList[index].shopHoursList ===
        null
      ) {
        this.shopDetailsProvider.shopDepartmentsList[index].shopHoursList = [];
        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
          this.shopDetailsProvider.shopDepartmentsList[
            index
          ].shopHoursList.push({
            departmentId: this.shopDetailsProvider.shopDepartmentsList[index]
              .departmentId,
            from: "",
            shopId: this.shopDetailsProvider.shopId,
            to: "",
            weekDay: dayIndex + 1
          });
        }
      }
    }
  }

  actionActive(indexTo: number): void {
    for (
      let index = 0;
      index < this.shopDetailsProvider.shopServicesList.length;
      index++
    ) {
      if (index === indexTo) {
        this.shopDetailsProvider.shopServicesList[index].isActive = true;
      } else {
        this.shopDetailsProvider.shopServicesList[index].isActive = false;
      }
    }

  }

  loadRegionProviderList(): void {
    this.shopsService.getRegionList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          const region = response.data;
          this.regionProviderList = region.filter((e:any) => e.isValid !== 0);
          this.isFormDirty = false;
          this.setSelectedRegion(this.shopDetailsProvider.ooRegionId);

        }
      },
      (error: any) => { }
    );
  }

  actionRemove(key: number, data: any): void {
    for (
      let index = 0;
      index < this.shopDetailsProvider.shopServicesList.length;
      index++
    ) {
      const servicelist = this.shopDetailsProvider.shopServicesList[index];
      for (
        let index2 = 0;
        index2 < servicelist.shopServiceTimeSlotsList.length;
        index2++
      ) {
        const timeSlot = servicelist.shopServiceTimeSlotsList[index2];
        if (data === timeSlot) {
          this.shopDetailsProvider.shopServicesList[
            index
          ].shopServiceTimeSlotsList.splice(index2, 1);
        }
      }
    }
  }

  addService(data: any): void {
    for (
      let index = 0;
      index < this.shopDetailsProvider.shopServicesList.length;
      index++
    ) {
      const element = this.shopDetailsProvider.shopServicesList[index];
      if (data === element) {
        this.blanPush = {
          id: 10000,
          shopid: this.shopDetailsProvider.shopId,
          serviceId: element.serviceId,
          weekday: "",
          from: "",
          to: "",
          delay: ""
        };
        element.shopServiceTimeSlotsList.push(this.blanPush);
      }
    }
  }

  customStringify(v: any): any {
    const cache = new Set();
    return JSON.stringify(v, function (key: any, value: any): any {
      if (typeof value === "object" && value !== null) {
        if (cache.has(value)) {
          // Circular reference found
          try {
            // If this value does not reference a parent it can be deduped
            return JSON.parse(JSON.stringify(value));
          } catch (err) {
            // discard key if value cannot be deduped
            return;
          }
        }
        // Store value in our set
        cache.add(value);
      }
      return value;
    });
  }

  async actionSave(): Promise<void> {
    this.validate();
    if (this.istimingError === false && this.isServiceError === false) {
      this.shopDetailsProvider.ooRegionId=this.shopDetailForm.controls['region'].value.id;
      const inactiveList = this.shopDetailsProvider.inactiveItems;
      this.objectCopy = this.customStringify(this.shopDetailsProvider);
      this.objectCopy = JSON.parse(this.objectCopy);
      for (
        let index = 0;
        index < this.objectCopy.inactiveItems.length;
        index++
      ) {
        this.objectCopy.inactiveItems[
          index
        ].inactiveFrom = this.objectCopy.inactiveItems[
          index
        ].inactiveFrom.value;
        this.objectCopy.inactiveItems[
          index
        ].inactiveTo = this.objectCopy.inactiveItems[index].inactiveTo.value;
      }
      this.saveRequestList = JSON.stringify(this.objectCopy);
      this.saveRequestList = JSON.parse(this.saveRequestList);
      this.saveRequestList.isValid =
        (this.saveRequestList.isValid === false || this.saveRequestList.isValid === 0) ? 0 : 1;
      this.saveRequestList.shopAmenities = [];
      const validAmenity = this.amenityArray.filter(
        e => e.isValid === 1 || e.isValid === true
      );
      for (let j = 0; j < validAmenity.length; j++) {
        this.vaildType = {
          isValid: 1,
          name: "",
          isDeleted: 0,
          amenityId: "",
          shopId: ""
        };
        this.vaildType.isValid = 1;
        this.vaildType.name = validAmenity[j].name;
        this.vaildType.isDeleted = validAmenity[j].isDeleted;
        this.vaildType.amenityId = validAmenity[j].amenityId;
        this.vaildType.shopId = validAmenity[j].shopId;

        this.saveRequestList.shopAmenities.push(this.vaildType);
      }
      /**
      * save pick up locations  of shop
      * (contactless location or pickup location)
      */
      this.savingRequest = true;
      this.saveRequestList.shopPickupLocation = [];
      const pickUpCount = this.pickupLocationArray.filter(ca => ca.isShopPickupLocation === true).length;
      const defaultCount = this.pickupLocationArray.filter(ca => ca.isDefault === true).length;
      if (pickUpCount > 0 && defaultCount === 0) {
        this.savingRequest = false;
        // if (this.snackBarService.snackBars.length < 1) { // Assuming snackBars is a similar array in SnackBarService
          let snackBarData: SnackBarData = {
              message: "Please choose a default pickup location.",
              title: 'Error',
              type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
      //}
        return;
      }
      this.pickupLocationArray.forEach(element => {
        const shopOptions = {
          shopId: element.shopId,
          ooPickupLocationId: (element.isShopPickupLocation) ? element.pickUpLocationId : undefined,
          isDefault: (element.isDefault) ? 1 : 0
        };
        this.saveRequestList.shopPickupLocation.push(shopOptions);
      });

      /**
       * oo shop admin user data.
       */
      this.savingRequest = true;
      if (this.saveRequestList.shopAdminUser !== undefined && this.saveRequestList.shopAdminUser !== null) {
        this.saveRequestList.shopAdminUser.isactive = (this.saveRequestList.shopAdminUser.isactive) ? 1 : 0;
        if (this.saveRequestList.shopAdminUser.isactive && this.saveRequestList.shopAdminUser.password.toString().trim().length === 0) {
          this.savingRequest = false;
          // if (this.snackBarService.snackBars.length < 1) { // Assuming snackBars is a similar array in SnackBarService
            let snackBarData: SnackBarData = {
                message: "Please enter shop manager password.",
                title: 'Error',
                type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
       // }
          return;
        }
      }
      
      for (
        let index = 0;
        index < this.saveRequestList.shopDepartmentsList.length;
        index++
      ) {
        if (
          this.saveRequestList.shopDepartmentsList[index].departmentData !==
          undefined
        ) {
          delete this.saveRequestList.shopDepartmentsList[index].departmentData;
        }
      }
      for (
        let index = 0;
        index < this.saveRequestList.shopDepartmentsList.length;
        index++
      ) {
        if (this.saveRequestList.shopDepartmentsList[index].isValid === true) {
          this.saveRequestList.shopDepartmentsList[index].isValid = 1;
        } else if (
          this.saveRequestList.shopDepartmentsList[index].isValid === false
        ) {
          this.saveRequestList.shopDepartmentsList[index].isValid = 0;
        }
        if (
          this.saveRequestList.shopDepartmentsList[index].shopHoursList !== null
        ) {
          for (
            let index2 = 0;
            index2 <
            this.saveRequestList.shopDepartmentsList[index].shopHoursList
              .length;
            index2++
          ) {
            // console.log(this.saveRequestList.shopDepartmentsList[index].shopHoursList[index2]);
            this.hourlistVar = this.saveRequestList.shopDepartmentsList[
              index
            ].shopHoursList[index2];
            if (this.hourlistVar.from === "" && this.hourlistVar.to === "") {
              this.saveRequestList.shopDepartmentsList[
                index
              ].shopHoursList.splice(index2, 1);
              continue;
            }
            if (
              this.saveRequestList.shopDepartmentsList[index].shopHoursList[
                index2
              ].from === ""
            ) {
            }

            if (
              this.saveRequestList.shopDepartmentsList[index].shopHoursList[
                index2
              ].to === ""
            ) {
            }
          }
        }
      }

      for (
        let index = 0;
        index < this.saveRequestList.shopDepartmentsList.length;
        index++
      ) {
        if (
          this.saveRequestList.shopDepartmentsList[index].shopHoursList !== null
        ) {
          let remover: any = [];
          for (
            let index2 = 0;
            index2 <
            this.saveRequestList.shopDepartmentsList[index].shopHoursList
              .length;
            index2++
          ) {
            if (
              this.saveRequestList.shopDepartmentsList[index].shopHoursList[
                index2
              ].from === "" &&
              this.saveRequestList.shopDepartmentsList[index].shopHoursList[
                index2
              ].to === ""
            ) {
              remover.push(index2);
            } else {
              this.saveRequestList.shopDepartmentsList[index].shopHoursList[
                index2
              ].from =
                this.saveRequestList.shopDepartmentsList[index].shopHoursList[
                  index2
                ].from + ":00";
              this.saveRequestList.shopDepartmentsList[index].shopHoursList[
                index2
              ].to =
                this.saveRequestList.shopDepartmentsList[index].shopHoursList[
                  index2
                ].to + ":00";
            }
          }
          remover.forEach((element:any) => {
            this.saveRequestList.shopDepartmentsList[
              index
            ].shopHoursList.splice(element, 1);
          });
        }
      }

      for (
        let index = 0;
        index < this.saveRequestList.shopServicesList.length;
        index++
      ) {
        const repeater = this.saveRequestList.shopServicesList[index];
        if (this.saveRequestList.shopServicesList[index].isValid === true) {
          this.saveRequestList.shopServicesList[index].isValid = 1;
        } else if (
          this.saveRequestList.shopServicesList[index].isValid === false
        ) {
          this.saveRequestList.shopServicesList[index].isValid = 0;
        }
        delete this.saveRequestList.shopServicesList[index].serviceData;
        for (
          let index2 = 0;
          index2 < repeater.shopServiceTimeSlotsList.length;
          index2++
        ) {
          const timeList = repeater.shopServiceTimeSlotsList[index2];
          let from = timeList.from;
          let to = timeList.to;
          if (from.length === 2) {
            timeList.from = from + ":00:00";
          } else if (from.length === 1) {
            timeList.from = "0" + from + ":00:00";
          } else if (from.length === 5) {
            timeList.from = from + ":00";
          }
          if (to.length === 2) {
            timeList.to = to + ":00:00";
          } else if (to.length === 1) {
            timeList.to = "0" + to + ":00:00";
          } else if (to.length === 5) {
            timeList.to = to + ":00";
          }
        }
      }
      // return;
      //  remove 1 row of null record
      for (
        let index = 0;
        index < this.saveRequestList.shopDepartmentsList.length;
        index++
      ) {
        this.dummyData = this.saveRequestList.shopDepartmentsList[
          index
        ].shopHoursList;
        if (this.dummyData !== null) {
          for (let index2 = 0; index2 < this.dummyData.length; index2++) {
            if (
              this.dummyData[index2].from === "" &&
              this.dummyData[index2].to === ""
            ) {
              this.dummyData.splice(index2, 1);
            }
          }
        }
      }
      // remove the department timing if not ticked
      for (
        let index = 0;
        index < this.saveRequestList.shopDepartmentsList.length;
        index++
      ) {
        if (this.saveRequestList.shopDepartmentsList[index].isValid === 0) {
          this.saveRequestList.shopDepartmentsList[index].shopHoursList = [];
        }
      }
      this.shopHourCount = 0;
      this.shopHourEmpty = false;
      for (
        let index = 0;
        index < this.saveRequestList.shopDepartmentsList.length;
        index++
      ) {
        const dataLoop = this.saveRequestList.shopDepartmentsList[index];
        if (
          dataLoop.isCommon === 0 &&
          dataLoop.isValid === 1 &&
          dataLoop.shopHoursList.length === 0
        ) {
          this.shopHourEmpty = true;
          this.shopHourCount++;
        }
      }
      if (this.shopHourCount === 0) {
        this.shopHourEmpty = false;
      }
      if (this.shopHourEmpty === true) {
        this.isServiceError = true;
        this.dayreUseValidation.push(
          "Please add atleast one time slot in Enabled Department."
        );
       // if (this.snackBarService.snackBars.length < 1) { // Assuming snackBars is a similar array in SnackBarService
          let snackBarData: SnackBarData = {
              message: this.dayreUseValidation[0],
              title: 'Error',
              type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
      //}
      
        return;
      }

      this.savingRequest = true;
      if (this.shopDetailForm.status === "VALID") {
        if (
          this.validValidation.fromDate.length > 0 ||
          this.validValidation.toDate.length > 0
        ) {
          if (this.validValidation.fromDate.length > 0) {
           // if (this.snackBarService.snackBars.length < 1) { // Assuming snackBars is a similar array in SnackBarService
              let snackBarData: SnackBarData = {
                  message: this.validValidation.fromDate[0],
                  title: 'Error',
                  type: 'error'
              };
              this.snackBarService.openSnackBar(snackBarData);
         // }
          
          }
          if (this.validValidation.toDate.length > 0) {
           // if (this.snackBarService.snackBars.length < 1) { // Assuming snackBars is a similar array in SnackBarService
              let snackBarData: SnackBarData = {
                  message: this.validValidation.toDate[0],
                  title: 'Error',
                  type: 'error'
              };
              this.snackBarService.openSnackBar(snackBarData);
          //}
          
          }
          this.savingRequest = false;
          return;
        }
        this.saveRequestList.inactiveFrom = this.incactiveFromDummy;
        this.saveRequestList.inactiveTo = this.inactiveToDummy;
        for (
          let index = 0;
          index < this.saveRequestList.inactiveItems.length;
          index++
        ) {
          this.saveRequestList.inactiveItems[index].error = [];
          const element = this.saveRequestList.inactiveItems[index];
          if (element.inactiveFrom === "" && element.inactiveTo === "") {
            continue;
          }
          const touchedFinder = this.touchedDisabledList.find(function (
            el: any
          ): any {
            return el.ooSaleItemId === element.ooSaleItemId;
          });
          if (touchedFinder === undefined) {
            continue;
          }
          const dateFrom = new Date(element.inactiveFrom);
          const dateTo = new Date(element.inactiveTo);
          if (dateFrom < new Date()) {
            // error from date should be grather than current date
            this.saveRequestList.inactiveItems[index].error.push(
              "From date should be greater than current date"
            );
          }
          if (element.inactiveTo === "") {
            continue;
          }
          if (dateTo < dateFrom) {
            // to date should be grather than current date
            this.saveRequestList.inactiveItems[index].error.push(
              "To date should be greater than from from date"
            );
          }
        }
        this.dateErrors = [];
        for (
          let index = 0;
          index < this.saveRequestList.inactiveItems.length;
          index++
        ) {
          if (this.saveRequestList.inactiveItems[index].error.length > 0) {
            this.dateErrors.push(
              this.saveRequestList.inactiveItems[index].error
            );
          }
          this.shopDetailsProvider.inactiveItems[
            index
          ].error = this.saveRequestList.inactiveItems[index].error;
        }
        if (this.dateErrors.length > 0) {
          //if (this.snackBarService.snackBars.length < 1) { // Assuming snackBars is a similar array in SnackBarService
            let snackBarData: SnackBarData = {
                message: this.dateErrors[0][0],
                title: 'Error',
                type: 'error'
            };
            this.snackBarService.openSnackBar(snackBarData);
        //}
        
          this.savingRequest = false;
          return;
        }
       
        
        this.shopsService.updateShopDetails(this.saveRequestList).subscribe(
          (response: any) => {
            if (response.successCode === 0) {
              // if (this.snackBarService.snackBars.length === 0) { // Assuming snackBars is a similar array in SnackBarService
                let snackBarData: SnackBarData = {
                    message: "Shop details updated",
                    title: 'Success',
                    type: 'success'
                };
                this.isFormDirty = false;
                this.snackBarService.openSnackBar(snackBarData);
              // }
              this.savingRequest = false;
            }
          },
       
          (error: any) => {
            // if (this.snackBarService.snackBars.length === 0) { // Assuming snackBars is a similar array in SnackBarService
              let snackBarData: SnackBarData = {
                  message: JSON.stringify(error),
                  title: 'Error',
                  type: 'error'
              };
              this.snackBarService.openSnackBar(snackBarData);
          // }
          
            this.savingRequest = false;
          }
        );
      } else {
        this.activeTab = 0;
        this.savingRequest = false;
        // if (this.snackBarService.snackBars.length === 0) { // Assuming snackBars is a similar array in SnackBarService
          let snackBarData: SnackBarData = {
              message:"Please fill out the required fields",
              title: 'Error',
              type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
      // }
      
      }
    } else {
      if (this.isServiceError === true) {
        // service error
        // if (this.snackBarService.snackBars.length === 0) {
          if (this.activeTab === 0) {
            this.activeTab = 1;
          }
          let snackBarData: SnackBarData = {
            message:this.dayreUseValidation[0],
            title: 'Error',
            type: 'error'
        };
        this.snackBarService.openSnackBar(snackBarData);
        // }
        
      } else if (this.istimingError === true) {
        if (this.activeTab === 0) {
          this.activeTab = 1;
        }
        // if (this.snackBarService.snackBars.length === 0) { // Assuming snackBars is a similar array in SnackBarService
          let snackBarData: SnackBarData = {
              message: "Not a valid time slots. please correct before saving..",
              title: 'Error',
              type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
      // }
      
      } else {
      }
    }
  }

  initialDateFormat(): void {
    if (
      this.shopDetailsProvider.inactiveFrom !== null &&
      this.shopDetailsProvider.inactiveTo !== null
    ) {
      this.incactiveFromDummy = this.shopDetailsProvider.inactiveFrom;
      this.inactiveToDummy = this.shopDetailsProvider.inactiveTo;
      this.shopDetailsProvider.inactiveFrom = new FormControl(
        new Date(this.shopDetailsProvider.inactiveFrom)
      );
      this.shopDetailsProvider.inactiveTo = new FormControl(
        new Date(this.shopDetailsProvider.inactiveTo)
      );
    }
  }

  serviceChange(event: any, data: any): void {
    // if (event.checked === true) {
    //   // enabled
    // } else if (event.checked === false) {
    //   for (let index = 0; index < this.shopDetailsProvider.shopServicesList.length; index++) {
    //     const service = this.shopDetailsProvider.shopServicesList[index];
    //     if (data === service) {
    //       this.shopDetailsProvider.shopServicesList[index].shopServiceTimeSlotsList = [];
    //     }
    //   }
    // }
  }

  onchange(event: any, value: any): void {
    const currentValue = event.target.value;
    const valueArray = currentValue.split(":");
    if (
      valueArray.length === 1 &&
      valueArray[0] < 24 &&
      valueArray[0] > 9 &&
      valueArray[0].length === 2
    ) {
      event.target.value = event.target.value + ":00";
    } else if (
      valueArray.length === 1 &&
      valueArray[0] < 10 &&
      valueArray[0] > 0 &&
      valueArray[0].length === 1
    ) {
      event.target.value = "0" + event.target.value + ":00";
    } else if (
      valueArray.length === 1 &&
      valueArray[0] < 10 &&
      valueArray[0] > 0 &&
      valueArray[0].length === 2
    ) {
      event.target.value = event.target.value + ":00";
    }
  }

  validate(): void {
    this.errorList = [];
    this.istimingError = false;
    this.minimumService = [];
    this.minDepartment = [];
    for (
      let index = 0;
      index < this.shopDetailsProvider.shopDepartmentsList.length;
      index++
    ) {
      const departmentListItem = this.shopDetailsProvider.shopDepartmentsList[
        index
      ];
      // tslint:disable-next-line:max-line-length
      if (
        departmentListItem.isCommon !== 1 &&
        (JSON.stringify(departmentListItem.isValid) === "true" ||
          JSON.stringify(departmentListItem.isValid) === "1")
      ) {
        this.minDepartment.push(departmentListItem.id);
      }
      if (departmentListItem.shopHoursList !== null) {
        for (
          let index2 = 0;
          index2 < departmentListItem.shopHoursList.length;
          index2++
        ) {
          this.hourlistVar = departmentListItem.shopHoursList[index2];
          this.errorList = Object.create([]);
          if (
            this.hourlistVar.from.length === 2 &&
            !isNaN(this.hourlistVar.from)
          ) {
            this.hourlistVar.from = this.hourlistVar.from + ":00";
          }
          if (this.hourlistVar.to.length === 2 && !isNaN(this.hourlistVar.to)) {
            this.hourlistVar.to = this.hourlistVar.to + ":00";
          }
          if (this.hourlistVar.to.length === 1 && !isNaN(this.hourlistVar.to)) {
            this.hourlistVar.to = "0" + this.hourlistVar.to + ":00";
          }
          if (
            this.hourlistVar.from.length === 1 &&
            !isNaN(this.hourlistVar.from)
          ) {
            this.hourlistVar.from = "0" + this.hourlistVar.from + ":00";
          }
          if (this.hourlistVar.from === "" && this.hourlistVar.to === "") {
            // no error just skip
          } else {
            this.errorList = [];
            if (this.hourlistVar.from === "" && this.hourlistVar.to !== "") {
              this.errorList.push("Empty from time");
            }
            if (this.hourlistVar.to === "" && this.hourlistVar.from !== "") {
              this.errorList.push("Empty to List");
            }
            const timefrom = this.hourlistVar.from.split(":");
            const timeTo = this.hourlistVar.to.split(":");
            if (timefrom.length < 2) {
              this.errorList.push("Not a valid time format");
            }
            if (timeTo.length < 2) {
              this.errorList.push("Not a valid time format");
            }
            if (
              timefrom.length === 2 &&
              isNaN(timefrom[0]) &&
              isNaN(timefrom[1])
            ) {
              this.errorList.push("Not a valid start time");
            } else {
            }
            if (timeTo.length === 2 && isNaN(timeTo[0]) && isNaN(timeTo[1])) {
              this.errorList.push("Not a valid end time");
            } else {
            }
            if (!isNaN(timefrom[0]) && timefrom[0] > 23) {
              this.errorList.push("Not a valid hour");
            }
            if (!isNaN(timefrom[1]) && timefrom[1] > 59) {
              this.errorList.push("Not a valid minut");
            }
            if (!isNaN(timeTo[0]) && timeTo[0] > 23) {
              this.errorList.push("Not a valid hour");
            }
            if (!isNaN(timeTo[1]) && timeTo[1] > 59) {
              this.errorList.push("Not a valid minut");
            }
            const fromDateStamp = new Date(
              "1999-01-01T" + this.hourlistVar.from + ":00"
            );
            const toDateStamp = new Date(
              "1999-01-01T" + this.hourlistVar.to + ":00"
            );
            if (fromDateStamp > toDateStamp) {
              this.errorList.push(
                "start time should be less than the end time"
              );
            }
          }
          if (this.errorList === undefined) {
            this.errorList = [];
          }
          this.hourlistVar.error = this.errorList;
          //console.log("root");
          if (this.errorList.length > 0 && this.istimingError === false) {
            this.istimingError = true;
          }
        }
      }
    }

    this.dayreUseValidation = Object.assign([]);
    for (
      let index = 0;
      index < this.shopDetailsProvider.shopServicesList.length;
      index++
    ) {
      if (
        JSON.stringify(
          this.shopDetailsProvider.shopServicesList[index].isValid
        ) === "false"
      ) {
        this.shopDetailsProvider.shopServicesList[
          index
        ].shopServiceTimeSlotsList = [];
      }
      // tslint:disable-next-line:max-line-length
      if (
        JSON.stringify(
          this.shopDetailsProvider.shopServicesList[index].isValid
        ) === "true" ||
        this.shopDetailsProvider.shopServicesList[index].isValid === 1
      ) {
        this.minimumService.push(
          this.shopDetailsProvider.shopServicesList[index].serviceId
        );
      }
      const isValidServic = JSON.stringify(
        this.shopDetailsProvider.shopServicesList[index].isValid
      );
      if (isValidServic === "true" || isValidServic === "1") {
        if (
          this.shopDetailsProvider.shopServicesList[index]
            .shopServiceTimeSlotsList.length < 1
        ) {
          // tslint:disable-next-line:max-line-length
          this.dayreUseValidation.push(
            "Please add atleast one time slot in " +
            this.shopDetailsProvider.shopServicesList[index].serviceData
              .name +
            " service in order to save"
          );
        }
      }
      let dayArray = [];
      for (
        let index2 = 0;
        index2 <
        this.shopDetailsProvider.shopServicesList[index]
          .shopServiceTimeSlotsList.length;
        index2++
      ) {
        const element = this.shopDetailsProvider.shopServicesList[index]
          .shopServiceTimeSlotsList[index2];
        if (element.from === "" && element.to === "") {
          this.shopDetailsProvider.shopServicesList[
            index
          ].shopServiceTimeSlotsList.splice(index2, 1);
          continue;
        }
        dayArray.push(element.weekday);
        this.hourlistVar = element;
        if (this.hourlistVar.delay === "") {
          this.hourlistVar.delay = "00";
        }
        if (
          this.hourlistVar.from.length === 2 &&
          !isNaN(this.hourlistVar.from)
        ) {
          this.hourlistVar.from = this.hourlistVar.from + ":00";
        }
        if (this.hourlistVar.to.length === 2 && !isNaN(this.hourlistVar.to)) {
          this.hourlistVar.to = this.hourlistVar.to + ":00";
        }
        if (this.hourlistVar.to.length === 1 && !isNaN(this.hourlistVar.to)) {
          this.hourlistVar.to = "0" + this.hourlistVar.to + ":00";
        }
        if (
          this.hourlistVar.from.length === 1 &&
          !isNaN(this.hourlistVar.from)
        ) {
          this.hourlistVar.from = "0" + this.hourlistVar.from + ":00";
        }
        if (this.hourlistVar.from === "" && this.hourlistVar.to === "") {
          // no error just skip
          this.errorList = [];
        } else {
          this.errorList = [];
          if (this.hourlistVar.from === "" && this.hourlistVar.to !== "") {
            this.errorList.push("Empty from time");
          }
          if (this.hourlistVar.to === "" && this.hourlistVar.from !== "") {
            this.errorList.push("Empty to List");
          }
          const timefrom = this.hourlistVar.from.split(":");
          const timeTo = this.hourlistVar.to.split(":");
          if (timefrom.length < 2) {
            this.errorList.push("Not a valid time format");
          }
          if (timeTo.length < 2) {
            this.errorList.push("Not a valid time format");
          }
          if (
            timefrom.length === 2 &&
            isNaN(timefrom[0]) &&
            isNaN(timefrom[1])
          ) {
            this.errorList.push("Not a valid start time");
          } else {
          }
          if (timeTo.length === 2 && isNaN(timeTo[0]) && isNaN(timeTo[1])) {
            this.errorList.push("Not a valid end time");
          } else {
          }
          if (!isNaN(timefrom[0]) && timefrom[0] > 23) {
            this.errorList.push("Not a valid hour");
          }
          if (!isNaN(timefrom[1]) && timefrom[1] > 59) {
            this.errorList.push("Not a valid minut");
          }
          if (!isNaN(timeTo[0]) && timeTo[0] > 23) {
            this.errorList.push("Not a valid hour");
          }
          if (!isNaN(timeTo[1]) && timeTo[1] > 59) {
            this.errorList.push("Not a valid minut");
          }
          const fromDateStamp = new Date(
            "1999-01-01T" + this.hourlistVar.from + ":00"
          );
          const toDateStamp = new Date(
            "1999-01-01T" + this.hourlistVar.to + ":00"
          );
          if (fromDateStamp > toDateStamp) {
            this.errorList.push("start time should be less than the end time");
          }
        }
        if (this.errorList === undefined) {
          this.errorList = [];
        }
        if (this.errorList.length > 0) {
          this.dayreUseValidation.push("Invalid Time format");
        }
        this.hourlistVar.error = this.errorList;
      }
      if (this.hasDuplicates(dayArray)) {
        this.dayreUseValidation.push(
          "Duplicate day selected on service & time slots in " +
          this.shopDetailsProvider.shopServicesList[index].serviceData.name +
          " Tab"
        );
      }
    }
    if (this.minimumService.length < 1) {
      this.dayreUseValidation.push(
        "You should enable atleast  one service in order to update"
      );
    }
    if (this.minDepartment.length < 1) {
      this.dayreUseValidation.push(
        "You should enable atleast one Department in order to update"
      );
    }
    if (this.dayreUseValidation.length > 0) {
      this.isServiceError = true;
    } else {
      this.isServiceError = false;
    }
  }

  hasDuplicates(array: any): any {
    let valuesSoFar = [];
    for (let i = 0; i < array.length; ++i) {
      let value = array[i];
      if (valuesSoFar.indexOf(value) !== -1) {
        return true;
      }
      valuesSoFar.push(value);
    }
    return false;
  }
  clearRegion() {
    this.shopDetailForm.controls['region'].setValue('');
  }
}
