<div class="master-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">
                <mat-list-item class="mat-list-item" routerLink="/amenity" [routerLinkActive]="['is-active']" (click)="resetState()"  [matTooltip]="!show ? 'Amenity' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/amenity.svg">
                        <span class="master-title">Amenity</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/company" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Company' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" >
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/company.svg">
                        <span class="master-title">Company</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/calendar" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Calendar' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/calendar.svg">
                        <span class="master-title">Calendar</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/region" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Region' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/region.svg">
                        <span class="master-title">Region</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/pickup-location" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Pickup Location' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/pickup.svg">
                        <span class="master-title">Pickup Location</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/department" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Department' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/department.svg">
                        <span class="master-title">Department</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/item-category" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Item Category' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/item-category.svg">
                        <span class="master-title">Item Category</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/item" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Item' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/items.svg">
                        <span class="master-title">Item</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/customer" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Customers' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/customer.svg">
                        <span class="master-title">Customers</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/shop" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Shops' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/shop.svg">
                        <span class="master-title">Shops</span>
                    </div>
                </mat-list-item>
            </div>
        </div>
    </div>
</div>
