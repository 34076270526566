import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-order-summary-card',
  templateUrl: './order-summary-card.component.html',
  styleUrl: './order-summary-card.component.scss'
})
export class OrderSummaryCardComponent {
  @Input() data: any;
}
