import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-shop-message-dialog',
  templateUrl: './shop-message-dialog.component.html',
  styleUrl: './shop-message-dialog.component.scss'
})
export class ShopMessageDialogComponent {


  constructor(
    
    public dialogRef: MatDialogRef<ShopMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    
  ) {
    
   }

   onClose() {
    this.dialogRef.close();
  }
}
