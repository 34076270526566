import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchFilterComponent, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { ItemsModel } from './models/items.model';
import { ItemsService } from './items.service';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/auth/permission.service';
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrl: './items.component.scss'
})
export class ItemsComponent {
  itemsList: ItemsModel[]=[];
  public CATEGORY_SELECT_ID: any = 0;
  showSearch: boolean = false;
  isLoading = true;
  IsDownloading = false;
  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "oo_sale_item.code",
    },
    {
      title: "Name",
      column: "oo_sale_item.name",
    },
    {
      title: "Category",
      column: "oo_sale_item.oo_item_category_id",
      type: "list",
      values: []
    },
    {
      title: "Item Type",
      column: "sale_item.item_type",
      type: "list",
      values: [
        { title: "Regular Item", value: "1" },
        { title: "Customizable", value: "2" },
        { title: "Combo", value: "3" },


      ]
    },

      { title: "Customizable",
      column: "oo_sale_item.is_customizable",
      type: "boolean",
      values: [
        { title: "Yes", value: "1" },
        { title: "No", value: "0" },

      ]
    },
   
    {
      title: "Price",
      column: "sale_item.fixed_price",
    },
    {
      title: "Valid",
      column: "oo_sale_item.is_valid",
      values: [
        { title: "Yes", value: "1" },
        { title: "No", value: "0" },

      ]
    },
  ];


  displayedColumns = ['item', 'code', 'name', 'category','item_type', 'isCustomizable', 'price','isValid', 'action'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: ItemsModel;

  dataSource = new MatTableDataSource(this.itemsList);
  categoryList: any;
  categoryListFiltered: any;
  permission: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private itemsService: ItemsService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router,
    private stateManagerService: StateManagerService,private permissionService:PermissionService) {

    this.itemsList = [];
    this.permission = permissionService.getPagePermission('item');
  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });
  }

  /** 
  * Set the datasource
  */
  setDataSource(itemList:ItemsModel[]){
    this.dataSource=new MatTableDataSource(itemList);
    this.dataSource.sort=this.sort;
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  ngAfterViewInit(): void {

    this.loadPreviousState();
    this.loadProvider();
  }

  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {

    var previousState = this.stateManagerService.getState(this.constructor.name);

    if (previousState !== undefined && this.searchFilter != undefined) {

      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
      }

      if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
        this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
      }

      this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
      this.searchFilter.setSearchFilter(this.searchColumFilter,true);

    } else {
      this.setSortColumn('oo_sale_item.name','asc');
      this.loadData();
    }

  }
  loadProvider(): void {
    this.itemsService.getItemCategoryList().subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.categoryList = response.data;
          this.categoryList.splice(0, 0, { 'id': this.CATEGORY_SELECT_ID, 'code': 'SELECT', 'name': 'SELECT' });
          this.categoryListFiltered = this.categoryList.filter((cl:any) => cl.parentId !== 0 && cl.parentId !== null);
           // Sort shopList by shop name in ascending order
           this.categoryListFiltered.sort((a: any, b: any) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
  
          // Assign sorted values to filter[0].values
          this.filter[2].values = this.categoryListFiltered.map((category: any) => ({
            title: category.name,
            value: category.id
          }));
        }
      },
      (error: any) => {}
    );
  }

  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction});
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;
    

  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }


  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  // buildJWSRequsetData(requestData: RequestData): any {
  //   var jwsRequestData: {
  //     search: {
  //       filters: {
  //         filter: {
  //           column: string;
  //           operator: string;
  //           value: any;
  //         }[], scope: { offset: number, limit: number }
  //       }, sort: []
  //     }
  //   } = {
  //     search: {
  //       filters: {
  //         filter:
  //           [], scope: { offset: 0, limit: 10 }
  //       }, sort: []
  //     }
  //   }
  //   var offset = requestData.search?.filters?.scope?.offset;
  //   var limit = requestData.search?.filters?.scope?.limit;
  //   jwsRequestData.search.filters.scope.offset = (offset != undefined) ? offset : 0;
  //   jwsRequestData.search.filters.scope.limit = (limit != undefined) ? limit : 0;

  //   if (requestData.search?.filters?.simpleSearch != undefined) {
  //     var searchValue = requestData.search?.filters?.simpleSearch[0].value;
  //     var nameFilter = { column: "name", operator: "like", value: searchValue };
  //     var codeFilter = { column: "code", operator: "like", value: searchValue };
  //     jwsRequestData.search.filters.filter.push(nameFilter);
  //     jwsRequestData.search.filters.filter.push(codeFilter);


  //   }

  //   return jwsRequestData;
  // }

  directToImportItem(): any {
    this.router.navigate(['import-item']);
  }

  /**
   * loads the items data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    // var jwsRequestData = this.buildJWSRequsetData(requestData);
    let response = this.itemsService.getItemsList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.setDataSource(response.data.saleitems );
        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
        } else {
          this.stateManagerService.setState(this.constructor.name, requestData);
          // if(this.columnSortData!=undefined && this.columnSortData.length>0){
          //   var sort=this.columnSortData[0];
          //    this.setSortColumn(sort.column,sort.order);
          // }
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }

  /**
   * On Add clicked
   * Show the dialog to enter new items
   */
  addClicked(): void {

    this.router.navigate(['item-items-edit', 0]);
  }

  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }


  /**
  * On edit the item
  * Show the edit dialog
  */
  onEdit(itemsInfo: ItemsModel): void {
    if(!this.permission.can_edit) return;
    if (itemsInfo && itemsInfo.id !== undefined) {
      this.router.navigate(['item-edit', itemsInfo.id]);
    } else {
      // Handle the case where the id is undefined or not present
      console.error('Invalid itemsInfo object for editing:', itemsInfo);
      // Optionally, display a message to the user or log the issue
    }
  }



  /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }

  /**
   * On delete menu item selected
   * Confirm the items action and call the api to update
   */
  onDelete(itemsInfo: ItemsModel): void {
    const itemsId = itemsInfo.id;
    const message = `Are you sure, you want to delete ` + (itemsInfo.name + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.itemsService.delete(itemsInfo).subscribe({
          next: (response) => {
            if (response.successCode === 0) {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + itemsInfo.name + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
  sortData($event: any): void {
    var sd=this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
 * On items selected
 * @param itemsInfo 
 */
  onRowSelected(itemsInfo: ItemsModel): void {
    this.selectedItem = itemsInfo;
  }

  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }
  exportClicked() {
    let requestData = this.buildRequestData();
    this.IsDownloading = true;
  
    this.itemsService.downloadExcel(requestData)
      .subscribe(
        blob => {
          // Download logic
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'items.xlsx'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;
          this.IsDownloading = false;
        },
        error => {
          // Handle errors
          console.error('Error downloading Excel:', error);
          this.isLoading = false;
          this.IsDownloading = false;
          // Display user-friendly error message to the user
        }
      );
  }

}
