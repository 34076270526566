<div class="edit-page-root-container">
  <div class="page-content">
    <div class="page-header" style="margin-bottom:20px;min-height: 56px;">
      <button mat-button class="nav-button" (click)="navBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="title">
        {{ getTitle() }}
      </div>
      <div class="action-btn-container">
        @if(permission.can_edit){
        <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
        <!-- <button mat-flat-button class="action-btn btn-save">SAVE</button> -->
        <button mat-flat-button class="action-btn btn-save" #dataclick (click)="actionSave()"
          [disabled]="savingRequest">{{savingRequest === true ? 'Saving..' : 'Save'}} </button>
        }
      </div>
    </div>
    <div class="page-details" style="width: 1300px">
      <div fxLayout="column" fxLayout.lg="row" fxLayoutGap="16px">
        <div class="portal-widget" fxFlex="1 0 auto" fxFlex.lg="100">
          <!-- <p class="portal-widget__heading mat-subheading-2 ">Content Title </p> -->
          <!-- content component -->



          <mat-tab-group [(selectedIndex)]="activeTab" *ngIf="shopDetailsProvider"
            style="flex: 1 1 auto; min-width: 950px; text-align: center;background-color:white;">
            <mat-tab label="Details">
              <form [formGroup]="shopDetailForm">
                <div fxLayout="row" class="custom_flex" style="background: #fff">
                  <div class="form-block" style="width: 50%; padding: 15px;">
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Code</mat-label>
                        <input formControlName="code" matInput placeholder="Code" type="text"
                          [(ngModel)]="shopDetailsProvider.code" readonly="true">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field style="text-align: left" [color]="formColor"
                        [ngStyle]="{ 'font-size': formFontSize + 'px' }" [floatLabel]="formPlaceholders"
                        [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Name</mat-label>
                        <input formControlName="name" matInput placeholder="Name" type="text"
                          [(ngModel)]="shopDetailsProvider.name">
                        <mat-error *ngIf="shopDetailForm.get('name').hasError('required')">
                          This is a required field
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" style="height:160px;"
                        [ngStyle]="{ 'font-size': formFontSize + 'px' }" [floatLabel]="formPlaceholders"
                        [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Address</mat-label>
                        <textarea matInput formControlName="address" maxlength="255" rows="4"
                          [(ngModel)]="shopDetailsProvider.address" placeholder="Address"></textarea>
                        <mat-hint align="end">{{ shopDetailForm.value.address?.length || 0 }}/255</mat-hint>
                      </mat-form-field>
                    </div>


                    <div fxLayout="row" style="width: 100%">
                      <!-- <mat-form-field style="width: 100%">
                        <mat-label>Region</mat-label>

                        <mat-select style="width: 100%" formControlName="ooRegionId" placeholder="Region"
                          [(ngModel)]="shopDetailsProvider.ooRegionId">
                          <mat-option value="">--select--</mat-option>
                          <mat-option *ngFor="let item of regionProviderList" [value]="item.id">
                            {{item.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field> -->
                      <mat-form-field style="width:100%;">
                        <mat-label>Region</mat-label>
                        <input #regionInput
                               type="text"
                               formControlName="region"
                               placeholder="Region"
                               matInput
                               [matAutocomplete]="regionAutoComplete"
                               (input)="regionFilter()"
                               (focus)="regionFilter()"
                             >
                             <button (click)="clearRegion()" matSuffix mat-icon-button aria-label="Clear">
                              <mat-icon
                                  style="color: rgb(29 28 28 / 38%); font-weight: 500;  font-size: 22px;">close</mat-icon>
                          </button>
                        <mat-autocomplete [displayWith]="regAcDisplayText.bind(this)"  #regionAutoComplete="matAutocomplete"   >
                            @for (item of filteredRegion; track item) {
                              <mat-option [value]="item">
                                <span>{{item.name}}</span> 
                              </mat-option>
                            }
                          </mat-autocomplete>
                           <mat-error *ngIf="shopDetailForm.controls['region'].hasError('required')">This field is invalid.</mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>City</mat-label>
                        <input formControlName="city" matInput placeholder="City" type="text"
                          [(ngModel)]="shopDetailsProvider.city">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Zip Code</mat-label>

                        <input formControlName="zipCode" matInput placeholder="Zip Code" type="text"
                          [(ngModel)]="shopDetailsProvider.zipCode">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Email ID</mat-label>

                        <input formControlName="email" matInput placeholder="Email ID" type="text"
                          [(ngModel)]="shopDetailsProvider.email">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field>
                        <mat-label>Start Date</mat-label>

                        <input matInput [matDatepicker]="picker1" placeholder="Start Date"
                          (dateChange)="setstartDateValidation($event, 'from')" formControlName="inactiveFrom"
                          [formControl]="shopDetailsProvider.inactiveFrom">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>

                      </mat-form-field>
                      <img with="20" height="20" style="margin-top: 27px;"
                        src="assets/images/warnings/warning_image.png" matTooltip="{{validValidation.fromDate[0]}}"
                        *ngIf="validValidation.fromDate.length > 0">
                      <mat-form-field style="margin-left: 10px">
                        <mat-label>End Date</mat-label>

                        <input matInput [matDatepicker]="picker2" placeholder="End Date"
                          (dateChange)="setstartDateValidation($event, 'to')" formControlName="inactiveTo"
                          [formControl]="shopDetailsProvider.inactiveTo">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>

                      </mat-form-field>
                      <img with="20" height="20" style="margin-top: 27px;"
                        src="assets/images/warnings/warning_image.png" matTooltip="{{validValidation.toDate[0]}}"
                        *ngIf="validValidation.toDate.length > 0">
                    </div>


                  </div>
                  <div class="form-block" style="width: 50%; padding: 15px;">
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" style="height:159px;"
                        [ngStyle]="{ 'font-size': formFontSize + 'px' }" [floatLabel]="formPlaceholders"
                        [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Description</mat-label>

                        <textarea matInput formControlName="description" maxlength="255" rows="4"
                          [(ngModel)]="shopDetailsProvider.description" placeholder="Description"></textarea>
                        <mat-hint align="end">{{ shopDetailForm.value.description?.length || 0 }}/255</mat-hint>
                      </mat-form-field>

                    </div>
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>State</mat-label>
                        <input formControlName="state" matInput placeholder="State" type="text"
                          [(ngModel)]="shopDetailsProvider.state">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Country</mat-label>
                        <input formControlName="country" matInput placeholder="Country" type="text"
                          [(ngModel)]="shopDetailsProvider.country">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Phone</mat-label>

                        <input formControlName="phone" matInput placeholder="Phone" type="text"
                          [(ngModel)]="shopDetailsProvider.phone">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Latitude</mat-label>

                        <input formControlName="latitude" matInput placeholder="Latitude" type="text"
                          [(ngModel)]="shopDetailsProvider.latitude">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Longitude</mat-label>

                        <input formControlName="longitude" matInput placeholder="Longitude" type="text"
                          [(ngModel)]="shopDetailsProvider.longitude">

                      </mat-form-field>
                    </div>
                    <!-- <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Account Number</mat-label>
                        <input formControlName="account_number" matInput placeholder="Account Number" type="text"
                          [(ngModel)]="shopDetailsProvider.accountNumber">

                      </mat-form-field>
                      
                    </div> -->
                    <div fxLayout="row">
                      <mat-form-field [color]="formColor" [ngStyle]="{ 'font-size': formFontSize + 'px' }"
                        [floatLabel]="formPlaceholders" [hideRequiredMarker]="!formRequired" fxFlex>
                        <mat-label>Tax. No.</mat-label>
                        <input formControlName="companyTaxNo" matInput placeholder="Tax. No." type="text"
                          [(ngModel)]="shopDetailsProvider.companyTaxNo">

                      </mat-form-field>
                    </div>

                    <div class="status-toggle-container" fxLayout="row" [ngStyle]="{ 'margin-top': 22 + 'px' }">
                      <span class="status-toggle-title">Valid</span>
                      <mat-slide-toggle formControlName="isValid" class="status-toggle"
                        [(ngModel)]="shopDetailsProvider.isValid"></mat-slide-toggle>
                    </div>
                    <!-- <div fxLayout="row" [ngStyle]="{ 'margin-top': 22 + 'px' }">

                      <mat-checkbox formControlName="isValid" [(ngModel)]="shopDetailsProvider.isValid"> Enabled
                      </mat-checkbox>
                     
                    </div> -->



                  </div>

                </div>
                <!-- <div class="form-buttons">
              <button mat-raised-button color="primary" #dataclick (click)="actionSave()"
                [disabled]="savingRequest">{{savingRequest === true ? 'Saving..' : 'Save'}} </button>
            </div> -->
              </form>
            </mat-tab>
            <mat-tab label="Department & Services" *ngIf="shopDetailsProvider">
              <div fxLayout="row" class="custom_flex" style="background: #fff; padding: 15px;">
                <div class="" style="width: 100%; text-align: left;">
                  <h2 style="width: 100%"> Department & Timing <span style="font-size: 14px; color: red"> (Timing in 24
                      hr
                      format) </span> </h2>
                  <table style="width: 100; margin-top: 10px; border: 1px solid #a7a7a7;
                    border-radius: 13px;
                    padding: 13px;" *ngIf="shopDetailsProvider.shopDepartmentsList">
                    <tr>
                      <td style="width:3%" class="td"> </td>
                      <td style="width:6.5%" class="td dayCap"> Name </td>
                      <td style="width:125px" class="td daydata"> Monday </td>
                      <td style="width:125px" class="td daydata"> Tuesday </td>
                      <td style="width:125px" class="td daydata"> Wednesday </td>
                      <td style="width:125px" class="td daydata"> Thursday </td>
                      <td style="width:125px" class="td daydata"> Friday </td>
                      <td style="width:125px" class="td daydata"> Saturday </td>
                      <td style="width:125px" class="td daydata"> Sunday </td>
                    </tr>
                    <tr *ngFor="let department of departmentAndtiming let depIndex = index">
                      <td class="td">
                        <mat-checkbox [(ngModel)]="department.isValid"
                          (change)="departmentStatusChange($event, depIndex)">
                        </mat-checkbox>
                      </td>
                      <td class="td" style="width:7.5%"> <span *ngIf="department.departmentData.code"> {{
                          department.departmentData.code }}
                        </span> </td>
                      <td *ngFor="let timing of department.shopHoursList" class="td time-container">
                    <tr style="width: 100%">
                      <td style="text-align: center;"> <input type="text" [(ngModel)]="timing.from"
                          (change)="onchange($event, timing.from)"
                          class="time {{timing.error && timing.error.length > 0 ? 'error': ''}}"
                          style="height: 22px; width: 80%; font-size: 16px !important;margin-left:3px"> </td>
                      <td style="text-align: center;"> <span style="padding:2px; background: #6d6d6d;"> </span> </td>
                      <td style="text-align: center;"> <input
                          class="time {{timing.error && timing.error.length > 0 ? 'error': ''}}" [(ngModel)]="timing.to"
                          (change)="onchange($event, timing.from)" type="text"
                          style="height: 22px; width: 80%; font-size: 16px !important;margin-left:-3px;"> </td>
                    </tr>
                    </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div fxLayout="row" class="custom_flex" style="background: #fff; padding: 15px;">
                <div class="errorMessages" style="width: 100%" *ngIf="istimingError">
                  <p class="closeError" style="text-align: right;
                  margin-right: 11px;
                  color: #f00;
                  font-weight: bold;
                  cursor: pointer;
                  font-size: 26px;" (click)="errorDisable()"> x </p>
                  <ul class="errorlist" *ngFor="let department of departmentAndtiming">
                    <p> {{department.departmentData.code}} </p>
                    <div class="listtime" *ngFor="let timing of department.shopHoursList">
                      <li *ngIf="timing.error.length > 0">
                        {{timing.weekDay | daysCustom }}
                        <ul *ngIf="timing.error" #dataif>
                          <li *ngFor="let err of timing.error" class="erorlabel"> {{err}} </li>
                        </ul>
                      </li>
                    </div>
                  </ul>
                </div>

                <div class="errorMessages" style="width: 100%" *ngIf="!istimingError && isServiceError">
                  <p class="closeError" style="text-align: right;
                  margin-right: 11px;
                  color: #f00;
                  font-weight: bold;
                  cursor: pointer;
                  font-size: 26px;" (click)="errorDisable()"> x </p>
                  <ul class="errorlist">
                    <ul>
                      <li *ngFor="let error of dayreUseValidation" class="erorlabel"> {{error}} </li>
                    </ul>
                  </ul>
                </div>


              </div>
              <div fxLayout="row" class="custom_flex"
                style="background: #fff; padding: 15px; padding-left: 0px; width: 100%;">
                <h2 style="width: 100%; margin-left: 16px; text-align: left;"> SERVICE & TIME SLOTS <span
                    style="font-size: 14px; color: red">
                    (From and To times are in 24hr format. Preparation time in minutes) </span> </h2>
              </div>
              <div fxLayout="row" class="custom_flex"
                style="background: #fff; padding: 15px;  padding-left: 0px; width: 100%;">
                <div class="serviceContainer">
                  <div class="lister" *ngFor="let service of shopDetailsProvider.shopServicesList  let i = index">
                    <div class="servicebox {{service.isActive ? 'activeItem': ''}}" (click)="actionActive(i)"
                      *ngIf="service.serviceName">
                      <mat-checkbox [(ngModel)]="service.isValid"> </mat-checkbox> {{service.serviceName}}
                    </div>
                  </div>

                </div>
              </div>

              <div fxLayout="row" class="custom_flex" style="background: #fff; padding: 15px; padding-left: 0px;">
                <div [class]="!service.isActive ? 'removed': ''" style="width: 100%; float: left; clear: both"
                  *ngFor="let service of shopDetailsProvider.shopServicesList  let i = index">
                  <table style="width: 60%; margin-left: 18px; " [hidden]="!service.isActive">
                    <tr>
                      <td class="td daydata" style="width: 120px; text-align: left;padding-left:7px;"> Day </td>
                      <td class="td daydata"> From </td>
                      <td class="td daydata"> To </td>
                      <td class="td daydata"> Preparation Time </td>
                      <!-- <td class="td" style="width:7%; cursor: pointer;" class="add" (click)="addService(service)"> <span
                      class=""> + </span> </td> -->
                    </tr>
                    <tr *ngFor="let timeslot of service.shopServiceTimeSlotsList let timeslotIndex = index">
                      <td style="width: 120px; text-align: left;">
                        <mat-select placeholder="Day" class=selectday [(ngModel)]="timeslot.weekday"
                          style="padding-left:5px;">
                          <mat-option *ngFor="let item of dayList" [value]="item.day">
                            {{item.label}}
                          </mat-option>
                        </mat-select>
                      </td>
                      <td class="tdtime"> <input type="text"
                          class="time {{timeslot.error && timeslot.error.length > 0 ? 'error': ''}}"
                          [(ngModel)]="timeslot.from" (change)="onchange($event, timeslot.from)"> </td>
                      <td class="tdtime"> <input type="text"
                          class="time {{timeslot.error && timeslot.error.length > 0 ? 'error': ''}}"
                          [(ngModel)]="timeslot.to" (change)="onchange($event, timeslot.to)"> </td>
                      <td class="tdtime"> <input type="text" class="lengthtime" [(ngModel)]="timeslot.delay"> </td>
                      <!-- <td class="close" style="width:7%; cursor: pointer;" (click)="actionRemove(i,timeslot)"> <span
                      class="closeitem pointer"> x </span></td> -->
                    </tr>
                  </table>
                </div>
              </div>
              <!-- <div fxLayout="row" style="background: #fff; padding: 15px; width: 100%;">
                <div class="serviceActions">
                  <div class="service actions" *ngFor="let service of shopDetailsProvider.shopServicesList  let i = index">
                    <table [hidden]="!service.isActive" style="width:60%; border: none;">
                      <tr>
                        <td> Day </td>
                        <td> From </td>
                        <td> To </td>
                        <td> Preparation Time </td>
                        <td>  </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div> -->

              <!-- <div class="form-buttons">
            <button mat-raised-button color="primary" (click)="actionSave()" [disabled]="savingRequest">{{savingRequest
              === true ? 'Saving..' : 'Save'}} </button>
          </div> -->

            </mat-tab>
            <mat-tab label="Misc">
              <div fxLayout="row" class="custom_flex" style="background: #fff">
                <div class="form-block" style="width: 100%; padding: 15px;">
                  <div fxLayout="row">
                    <div style="width:100%;display: flex;">
                      <div style="width:50%">
                        <table class="amenity-table">
                          <tr>
                            <td class="amenity-column"></td>
                            <td class="amenity-column"></td>
                            <td class="amenity-column amenity-hdr">Amenities</td>
                          </tr>
                          <tr *ngFor="let amenity of amenityArray let i = index">
                            <td class="amenity-column valid-amenity">
                              <div>
                                <mat-checkbox [(ngModel)]="amenity.isValid"> </mat-checkbox>
                              </div>
                            </td>
                            <td class="amenity-column image-amenity">
                              <img src="{{amenity.smallIcon}}" alt="Smiley face" height="30" width="30">
                            </td>
                            <td class="amenity-column name-amenity">{{amenity.name}}</td>
                          </tr>

                        </table>
                        <div class="shop-admin-user-data-main"
                          *ngIf="shopAdminUserData !== undefined && shopAdminUserData !== null">
                          <div class="shop-admin-user-data-active">
                            <div class="shop-login">Shop Login</div>
                            <div class="active-check">
                              <mat-checkbox [(ngModel)]="shopAdminUserData.isactive"> </mat-checkbox>
                            </div>
                          </div>
                          <div class="shop-admin-user-data-sub">
                            <div class="shop-login-user">{{shopAdminUserData.userId}}</div>
                            <div class="shop-active-user"><input
                                [disabled]="!shopAdminUserData.isactive || (shopAdminUserData.isactive && this.isEditPassword)"
                                class="password-sec" type="{{this.isEditPassword?'password':'text'}}"
                                [(ngModel)]="shopAdminUserData.password" size="10"></div>
                            <div class="edit-password">
                              <button mat-raised-button color="primary" class="shop-admin-user-action"
                                [disabled]="!shopAdminUserData.isactive"
                                (click)="actionEditPassword()">{{this.isEditPassword?'Show':'Hide'}}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="width:100%;margin-left: 10%;">
                        <table class="pickup-option-table">
                          <tr>
                            <td class="pickup-option-column"></td>
                            <td class="pickup-option-column location-hdr">Pick-up Location</td>
                            <td class="pickup-option-column default-hdr">Default</td>
                          </tr>
                          <tr *ngFor="let pickupLoc of pickupLocationArray let i = index">
                            <td class="pickup-option-column default-pickup-option">
                              <div>
                                <mat-checkbox [(ngModel)]="pickupLoc.isShopPickupLocation"
                                  (change)="updateCheckedOptions($event,pickupLoc)"> </mat-checkbox>
                              </div>
                            </td>
                            <td class="pickup-option-column name-pickup-option">
                              <div class="pickup-option-title">
                                {{pickupLoc.title}}
                              </div>
                            </td>
                            <td class="pickup-option-column dafault-pickup">
                              <div class="default-pickup-div">
                                <mat-radio-button class="picku-option-sel" [checked]="pickupLoc.isDefault"
                                  (change)="defaultPickUpChange($event,pickupLoc,pickupLocationArray)"
                                  [disabled]="!pickupLoc.isShopPickupLocation">
                                </mat-radio-button>
                              </div>
                            </td>
                          </tr>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="form-buttons">
            <button mat-raised-button color="primary" (click)="actionSave()" [disabled]="savingRequest">{{savingRequest
              === true ? 'Saving..' : 'Save'}} </button>
          </div> -->
            </mat-tab>
            <mat-tab label="Sale Items">
              <!-- <div class="actionblock" style="width: 100%; height: 45px;
          margin-top: 11px; float:left;">
                <button mat-stroked-button color="primary" (click)="previousPage()"
                  [disabled]="itemCurrentPage === 1 ? true: false"> Previous </button>
                <button mat-stroked-button color="primary" style="margin-left: 10px;" (click)="nextPage()"
                  [disabled]="availabeCount > loadedItems ? false: true"> Next </button>
                <input type="text" class="searchTearmItem" (keyup)="refetchSaleItem()" [(ngModel)]="searchTearm"
                  placeholder="Search item">
              </div> -->
              <div class="actionblock"
                style="display: flex; flex-direction: row; gap:10px;padding-left:20px; padding-right:20px; padding-top:16px;">

                <div class="search-input-container ">
                  <div class="free-search">
                    <button mat-button class="search-icon" style="height:48px;" disabled>
                      <mat-icon>search</mat-icon>
                    </button>
                    <div class="search-input">
                      <input class="search-input" type="text" (keyup)="refetchSaleItem()" [(ngModel)]="searchTearm"
                        placeholder="Search item">
                      <button *ngIf="searchTearm" matSuffix mat-icon-button aria-label="Clear"
                        (click)="searchTearm='';refetchSaleItem();">
                        <mat-icon
                          style="color: rgb(29 28 28 / 38%); font-weight: 500;  font-size: 22px;">close</mat-icon>
                      </button>
                    </div>



                  </div>

                </div>
                <div>
                  <button mat-button class="search-nav-button" color="primary" style="height:50px;" (click)="previousPage()"
                        [disabled]="itemCurrentPage === 1 ? true: false"> Previous </button>
                      <button mat-button class="search-nav-button"  color="primary" style="margin-left: 10px;height:50px;" (click)="nextPage()"
                        [disabled]="availabeCount > loadedItems ? false: true"> Next </button>
                      <!-- <input type="text" class="searchTearmItem" (keyup)="refetchSaleItem()" [(ngModel)]="searchTearm"
                        placeholder="Search item"> -->
                </div>
              </div>

              <div class="avalableList" style="
          width:48%; float: left;  overflow-y: visible;margin-left:28px;
          ">
                <div
                  style="display:flex; gap:180px;align-items:center;justify-content: center;height:45px;position:relative;background: #fff; margin-top:10px;margin-bottom:10px;">
                  <h2 class="item-head"> Items Avaliable </h2>
                  <button mat-raised-button color="accent" class="customaction" style="right:5px;"
                    (click)="actionMoveToDisable()"> >>
                  </button>
                </div>

                <table class="availableItemList" *ngIf="allItemList">
                  <tr>
                    <th class="itemClass" style="width: 300px;"> Name </th>
                    <th class="itemClass" style="width: 180px;"> Category</th>
                    <th class="itemClass" style="width: 50px;"> Price</th>
                    <th class="itemClass" style="width: 40px;"> </th>
                  </tr>
                  <tr *ngFor="let saleItem of itemsShowList let i = index" style="height: 70.7px;">
                    <td class="itemClass"> {{saleItem.name}} </td>
                    <td class="itemClass"> {{saleItem.itemCategoryName}} </td>
                    <td class="itemClass"> {{saleItem.fixedPrice | currency}}</td>
                    <td class="itemClass">
                      <mat-checkbox (change)="onChangeAvaliableList($event, i, saleItem)"
                        [checked]="isChecked(saleItem)">
                      </mat-checkbox>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="avalableList" style="
          width:48%; float: left; overflow-y: visible;
          ">
                <div
                  style="display:flex; gap:10px;align-items:center;justify-content: center;height:45px;position:relative;background:#fff;margin-top:10px;margin-bottom:10px;">
                  <button mat-raised-button color="accent" class="customaction" style="left:0px;"
                    (click)="actionMoveEnable()">
                    << </button>
                      <h2 class="item-head"> Items Disabled </h2>

                </div>
                <form>
                  <table class="availableItemList" *ngIf="allItemList">
                    <tr>
                      <th class="itemClass"> Name </th>
                      <th class="itemClass"> From </th>
                      <th class="itemClass"> To </th>
                      <th class="itemClass"> </th>
                    </tr>
                    <tr *ngFor="let saleItem of shopDetailsProvider.inactiveItems let i = index"
                      class="{{saleItem.error.length > 0 ? 'errorItem': ''}}">
                      <td class="itemClass"> {{saleItem.name}} </td>
                      <td class="itemClass" style="text-align: center">
                        <mat-form-field class="date-picker" style="width: 150px;padding-top:10px;padding-bottom:10px;">
                          <input matInput [matDatepicker]="picker1" placeholder="From"
                            (dateChange)="setItemDisabledDateValidation($event, 'from', i)"
                            [formControl]="saleItem.inactiveFrom">
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                      </td>
                      <td class="itemClass" style="text-align: center">
                        <mat-form-field class="date-picker" style="width:150px;">
                          <input matInput [matDatepicker]="picker2" placeholder="To"
                            (dateChange)="setItemDisabledDateValidation($event, 'to', i)"
                            [formControl]="saleItem.inactiveTo">
                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                          <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                      </td>
                      <td class="itemClass">
                        <mat-checkbox (change)="onChangeRemoveList($event, i, saleItem)"> </mat-checkbox>
                      </td>
                    </tr>
                  </table>
                </form>
              </div>
              <!-- <div class="form-buttons">
            <button mat-raised-button color="primary" (click)="actionSave()" [disabled]="savingRequest">{{savingRequest
              === true ? 'Saving..' : 'Save'}} </button>
          </div> -->


            </mat-tab>


          </mat-tab-group>
        </div>

      </div>
    </div>

  </div>