<mat-dialog-content class="edit-form-content">
    <div *ngIf="!dataShopStatus.is_pause"  style="margin:15px;">
      <div class="shop-status-title">
          <h2 class="mat-headline">{{dataShopStatus.title}}</h2>
      </div>
      <div class="online-shop-status-info" style="margin-top: 10px;">
        Online ordering has been paused for {{shopPauseTime.pause_duration}} minutes since {{shopPauseTime.fromTimeFormatted}}.
      </div>
      <div class="online-shop-status-info-sub" style="color:black;">
        Are you sure you want to resume online ordering for <span class="shop-name-status">{{dataShopStatusProvider.name}}?</span>
      </div>
      <div fxLayout="row" style="justify-content: flex-end;">
          <div class="action_block stop-pause" style="margin-top:15px;">
              <button mat-raised-button color="primary" style="margin: 5px; background-color: #f3eeed; min-width: 4px; max-height: 33px; float: right; color: black;" (click)="actionCancel()">CLOSE</button>
              <button mat-raised-button color="primary" style="margin: 5px; background-color: #db250d; min-width: 4px; max-height: 33px; float: right" (click)="pauseStopClick()">RESUME</button>
          </div>
      </div>
    </div>
  
    <div *ngIf="dataShopStatus.is_pause" style="margin:15px;">
      <div class="shop-status-title">
          <h2 class="mat-headline">{{dataShopStatus.title}}</h2>
      </div>
      <div class="online-shop-status-pause-info" style="margin-top:10px;">
        Temporarily pause the online ordering of <span class="shop-to-pause" style="gap:10x;">{{dataShopStatusProvider.name}}</span> for the next  
        <mat-form-field style="width: 70px; padding: 6px;">
          <mat-select [(ngModel)]="pauseTimeIntra">
            <mat-option *ngFor="let pauseTimeInterval of pauseTimeIntervalArray" [value]="pauseTimeInterval">{{pauseTimeInterval}}</mat-option>
          </mat-select>
        </mat-form-field>
        minutes.
      </div>
      
      <div class="action_block start-pause">
        <div class="start-pause-sub">
          <button mat-raised-button color="primary" style="margin: 5px; background-color: #f3eeed; min-width: 4px; max-height: 33px; float: right; color: black;" (click)="actionCancel()">CANCEL</button>
          <button mat-raised-button color="primary" style="margin: 5px; background-color: #db250d; min-width: 4px; max-height: 33px; float: right;" (click)="createPause()">CONFIRM</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  