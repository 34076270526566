export const environment = {
  production: false,
  buildNo:202408311121,
  ver:'TEST 2.0.1',
  baseUrl: 'https://test.admin.oov2.mojosoft.work/ws/',
  wsPhp: 'https://test.admin.oov2.mojosoft.work/ws-ext/',
  apiReportUrl: 'https://test.admin.oov2.mojosoft.work/rpt-ws/',
  uplodFilePath: 'https://test.admin.oov2.mojosoft.work/resources',
  itemImageWidth: 1000,
  itemImageHeight: 1000,
  userId: 'oos',
  password: '1234',
  appKey: "e568458e9121478125d076b344e26c9e",
  crm: {
    apiUrl: 'https://test.crm.mojosoft.work/ws',
    userId: 'crm',
    password: '1234',
    appKey: "e568458e9121478125d076b344e26c9e",
  }
};
