<app-masters-optionbar> </app-masters-optionbar>
<div class="edit-page-root-container holiday-root-container page-root-container">
  <div class="page-content">
      
    <div class="page-header">
      <div class="page-title"><img class="icon" src="assets/images/menu/svg/calendar.svg"><span style=" margin-top: 5px;">{{title}}</span></div>
    </div>

    <!-- <mat-tab-group style="background: transparent;">
        <mat-tab label="Sale items" style="text-align: left;"> -->
    <div class="page-calendar-details" >

      <div fxLayout="column" fxLayout.lg="row" fxLayoutGap="16px" class="calander-container" >
        <div class="portal-widget calander-container" fxFlex="1 0 auto" fxFlex.lg="100">
          <div class="calendar-filter">
            <div class="row">
              <div class="col-xs-2">
                <mat-form-field class="holidayController" style="margin-left:11px;">
                  <mat-select placeholder="Calendar" [(ngModel)]="typeOfHoliday"
                    (selectionChange)="changeHolidayType()">
                    <mat-option value="1"> National </mat-option>
                    <mat-option value="3"> Region </mat-option>
                    <mat-option value="2"> Shop </mat-option>               
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xs-4" *ngIf="typeOfHoliday==='2'" >
                <!-- <mat-form-field class="holidayController item-name-mat-field">
                  <mat-select placeholder="Shop Name" [(ngModel)]="selectedShop"
                  (selectionChange)="changeHolidayType()">
                      <mat-option [value]="item" *ngFor="let item of shopListProvider"> {{item.name}} </mat-option>
                  </mat-select>
                </mat-form-field> -->
                <mat-form-field class="holidayController item-name-mat-field">
                  <input #shopInput
                         type="text"
                         [(ngModel)]="selectedShop"
                         placeholder="Shop Name"
                         matInput
                         [matAutocomplete]="shopAutoComplete"
                         (input)="shopFilter()"
                         (focus)="shopFilter()"
                         
                            
                       >
                       <button (click)="clearShop()" *ngIf="shopInput.value" matSuffix mat-icon-button aria-label="Clear">
                        <mat-icon
                            style="color: rgb(29 28 28 / 38%); font-weight: 500;  font-size: 22px;">close</mat-icon>
                    </button>
                  <mat-autocomplete [displayWith]="shopAcDisplayText.bind(this)"  (optionSelected)="changeHolidayType()"  #shopAutoComplete="matAutocomplete">
                      @for (item of filteredShop; track item) {
                        <mat-option [value]="item">
                          <span>{{item.name}}</span> 
                        </mat-option>
                      }
                    </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-xs-4" *ngIf="typeOfHoliday==='3'">
                <!-- <mat-form-field class="holidayController" >
                  <mat-select placeholder="Region Name" [(ngModel)]="selectedRegion"
                  (selectionChange)="changeRegionViseShop()">
                      <mat-option [value]="region" *ngFor="let region of regionListProvider"> {{region.name}} </mat-option>
                  </mat-select>
                 
                </mat-form-field> -->
                <mat-form-field class="holidayController">
                  <input #regionInput
                         type="text"
                         [(ngModel)]="selectedRegion"
                         placeholder="Region"
                         matInput
                         [matAutocomplete]="regionAutoComplete"
                         (input)="regionFilter()"
                         (focus)="regionFilter()"
                        

                         
                       >
                       <button (click)="clearRegion()" *ngIf="regionInput.value" matSuffix mat-icon-button aria-label="Clear">
                        <mat-icon
                            style="color: rgb(29 28 28 / 38%); font-weight: 500;  font-size: 22px;">close</mat-icon>
                    </button>
                  <mat-autocomplete [displayWith]="regAcDisplayText.bind(this)"  (optionSelected)="changeRegionViseShop()" #regionAutoComplete="matAutocomplete">
                      @for (item of filteredRegion; track item) {
                        <mat-option [value]="item">
                          <span>{{item.name}}</span> 
                        </mat-option>
                      }
                    </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-xs-2 allShopCheckDiv"  *ngIf="typeOfHoliday==='3'">
                <mat-form-field appearance="fill" class="holidayController item-allshop-mat-field">
                 
                  <mat-select [(value)]="allCheck" (selectionChange)="allCheckBoxCheck($event)">
                    <mat-option [value]="true">All Shops</mat-option>
                    <mat-option [value]="false">Specific Shops</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-checkbox class ="allShopCheckBox" type="checkbox"  [checked]="allCheck"
                (change)="allCheckBoxCheck()"
              ><a>All Shops</a></mat-checkbox>  -->
                </div>
              <div class="col-xs-4" *ngIf="typeOfHoliday==='3' && allCheck === false">
                <mat-form-field class="holidayController item-regionShopItem-mat-field">
                  <mat-select placeholder="Shops" [formControl]="regionShopsCtrl"  
                  multiple   >
                  <mat-select-trigger>
                    {{regionShopsCtrl.value ? regionShopsCtrl.value[0].name : ''}}
                    <span *ngIf="regionShopsCtrl.value?.length > 1" class="additional-selection">
                      (+{{regionShopsCtrl.value.length - 1}} {{regionShopsCtrl.value?.length === 2 ? 'other' : 'others'}})
                    </span>
                  </mat-select-trigger>
    
                  <mat-option *ngFor="let regionShopItem of regionShopListProvider " [value]="regionShopItem"> {{regionShopItem.name}} </mat-option>
                </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="navBlock">
          
            <button mat-stroked-button style="float: left; left: 67px; background: #F44336;
            color: #fff !important;" (click)="previousMonth()">
              <span class=""><mat-icon>keyboard_arrow_left</mat-icon></span> </button> <h3 class="month"> {{activeMonth | monthsCustom }} - {{activeYear}} </h3>
             
               <button mat-stroked-button style="float: right; margin-top: -55px; right: 71px; background: #F44336;
                color: #fff !important;" (click)="nextMonth()"> <span ><mat-icon>keyboard_arrow_right</mat-icon></span>
                </button>
          </div>
          <div style="display:flex;Width:100%; Justfy-content:center;padding:2px;">
            <span  style="display:block;Text-align:center;Width:100%;Color:yellow;Background:red;Font-weight:bold;Font-size:14px;"> {{this.calanderInfo}}</span>
           
          </div>
          
          <div class="clendar-block">
           
            <div class="calendar-list" 
              style="position: relative; float: left;"
              >
              <div class="cal_list_in_div" *ngIf="loadingCalendar">
                  <div class="loading_img"><img src="assets/loading2.gif"></div>
              </div> 
              <div class="day prepend calendar-heading" *ngFor="let item of daysLabel let i = index">
                <div class="days_label"> {{ item }} </div>
              </div>
              <div class="day prepend" *ngFor="let item of dummyDaysPrepend let i = index">
    
              </div>
              <div class="day daymainblock {{item.festiveType==1 && item.isValid === 1 ? 'holiday': ''}} {{item.festiveType===2 && item.isValid === 1 ? 'festive-day': ''}} " *ngFor="let item of monthArrays let i = index" (click)="actionEditPopup(item)">
                <div *ngIf="(item.holidayType==1 || item.holidayType==2) && (item.holidayType==typeOfHoliday)" class="holiday-delete-icon-container">
                  <!-- <i class="fa fa-trash-o holiday-delete-icon" (click)="deleteHoliday(item);$event.stopPropagation();"></i> -->
                  <mat-icon class="holiday-delete-icon" (click)="deleteHoliday(item);$event.stopPropagation();">delete</mat-icon>

                  <!-- <i class="material-icons holiday-delete-icon">delete</i> -->
                </div>
                <div class="daynumber" > {{i+1}}
                  <div *ngIf="item.holidayType===1 && item.isValid === 1 " class="national"> <span class="description">  {{item.description }}  </span> </div>  
                  <div *ngIf="item.holidayType===2 && item.isValid === 1" class="regional"><mat-icon style="font-size:18px;height:14px;">home</mat-icon><span > {{item.description }}  </span> </div>  
                  <div *ngIf="item.festiveType===2 && item.isValid === 1" class="festive-time"> <span > {{item.displayTime }}  </span> </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


</div>
  
</div>

