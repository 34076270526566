<div class="edit-page-root-container category-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                @if(permission.can_edit){
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
                }
                
            </div>
        </div>
        <div class="page-details ">
            <div class="detail-section">
                <div class="content-container">
                    <form class="edit-form" [formGroup]="editForm">
                        <div class="form-content-container">
                            <div class="common-ug-partion">
                                <mat-form-field class="code">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="code" maxlength="10" [readonly]="categoryInfo.id!==undefined && categoryInfo.id!==0">
                                    <mat-error [@if]="getErrorDescription('code')">{{getErrorDescription('code')}}</mat-error>
                                </mat-form-field>
                                <mat-form-field class="name">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name"  maxlength="150">
                                    <mat-error [@if]="getErrorDescription('name')">{{getErrorDescription('name')}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="common-ug-partion" style="gap:6px;">
                                <mat-form-field class="description">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput  maxlength="250" formControlName="description" style="height:153px;"></textarea>
                                </mat-form-field> 
                                @if(categoryInfo.id!== undefined && categoryInfo.id !== 0){
                                    <div class="image-upload">
                                        <input name="imageUrl" #inputFile type="file" accept="image/*" (change)="importFile($event)"  style="display: none;"/>
                                      </div> 
                                    <div style=" width: 150px; float: left;" class="image-upload-container">
                                        <div class="image-container">
                                            <img [src]=" rootFilePath + 'images/category/' +  categoryInfo.image" *ngIf="categoryInfo.image != ''  && previewImage == '' " style="width: 155px;height:155px">
                                          <img src="assets/images/no-image.png" *ngIf="categoryInfo.image == '' && previewImage == '' " style="width: 155px;height:155px">
                                          <img [src]="previewImage" *ngIf="previewImage != '' "style="width: 155px;">
                                          <div class="actionIcons">
                                            @if(permission.can_edit){
                                            <div class="change icon" (click)="inputFile.click()"> Change </div>
                                            <div class="clearIcon" (click)="actionImageRemove()"> x </div>
                                            }@else{
                                                <div class="change icon"> Change </div>
                                                <div class="clearIcon"> x </div>
                                            }
                                          </div>
                                        </div>
                                    </div>
                                    <div style="clear: both;"></div>
                                }
                            </div>
                            <div class="common-ug-partion" style="padding-top:20px;">
                                <div class="category-and-department-container">
                                    <mat-checkbox style="margin-top: 10px"
                                    formControlName="isSubCategory"
                                   [(ngModel)]="isSubCategoryModel"
                                   
                                   (change)="actionIsSubCategory(isSubCategoryModel)"
                                 > Sub Category </mat-checkbox>
                               
    
                                 <mat-form-field class="main-category" *ngIf="isSubCategoryModel">
                                    <mat-label>Main Category</mat-label>
                                    <input #mainCategoryInput
                                           type="text"
                                           formControlName="mainCategory"
                                           placeholder="Main Category"
                                           matInput
                                           [matAutocomplete]="mainCategoryAutoComplete"
                                           (input)="mainCategoryFilter()"
                                           (focus)="mainCategoryFilter()"
                                           
                                         >
                                         
                                          <button (click)="clearMainCategory()"  matSuffix mat-icon-button aria-label="Clear">
                                            <mat-icon
                                                style="color: rgb(29 28 28 / 38%); font-weight: 500;  font-size: 22px;">close</mat-icon>
                                        </button>
                                    <mat-autocomplete  [displayWith]="mainCatDisplayText.bind(this)"  #mainCategoryAutoComplete="matAutocomplete"   >
                                        @for (item of filteredMainCategory; track item) {
                                          <mat-option [value]="item">
                                            <span>{{item.name}}</span> 
                                          </mat-option>
                                        }
                                      </mat-autocomplete>
                                      <mat-error *ngIf="editForm.controls['mainCategory'].hasError('required')">This field is invalid.</mat-error>
                                  </mat-form-field>
                                  <mat-form-field *ngIf="!isSubCategoryModel" class="department">
                                    <mat-label>Department</mat-label>
                                    <input #departmentInput
                                           type="text"
                                           formControlName="department"
                                           placeholder="Department"
                                           matInput
                                           [matAutocomplete]="departmentAutoComplete"
                                           (input)="departmentFilter()"
                                           (focus)="departmentFilter()"
                                           
                                         >
                                         <button (click)="clearDepartment()"  matSuffix mat-icon-button aria-label="Clear">
                                            <mat-icon
                                                style="color: rgb(29 28 28 / 38%); font-weight: 500;  font-size: 22px;">close</mat-icon>
                                        </button>
                                    <mat-autocomplete  [displayWith]="departmentDisplayText.bind(this)"  #departmentAutoComplete="matAutocomplete"   >
                                        @for (item of filteredDepartment; track item) {
                                          <mat-option [value]="item">
                                            <span>{{item.name}}</span> 
                                          </mat-option>
                                        }
                                      </mat-autocomplete>
                                      <mat-error *ngIf="editForm.controls['department'].hasError('required')">This field is invalid.</mat-error>
                                  </mat-form-field>
                                </div>
                                <mat-form-field class="displayOrder">
                                    <mat-label>Display Order</mat-label>
                                    <input matInput formControlName="displayOrder" maxlength="50" type="number">
                                    <mat-error [@if]="editForm.controls['displayOrder'].hasError('required')">Display
                                        Order
                                        should
                                        be
                                        entered</mat-error>
                                    <mat-error [@if]="editForm.controls['displayOrder'].hasError('maxlength')">Maximum
                                        length is
                                        50.</mat-error>
                                </mat-form-field>
                               
                            </div>
                            <div class="status-toggle-container">
                                <span class="status-toggle-title">Valid</span>
                                <mat-slide-toggle formControlName="isValid" class="status-toggle"></mat-slide-toggle>
                            </div>
                                             
                            <div style="gap:15px;display:flex;"> 
                                <!-- @if(categoryInfo.parentId!= categoryInfo.departmentId){
                                <mat-checkbox formControlName="isSubcategory" [checked]="parentId"  (change)="onCheckboxChange($event)">
                                    Sub Category
                                  </mat-checkbox>
                                }@else if(categoryInfo.parentId === categoryInfo.departmentId){
                                    <mat-checkbox formControlName="isSubcategory"  (change)="onCheckboxChange($event)">
                                        Sub Category
                                      </mat-checkbox> 
                                }
                                  
                                <mat-form-field>
                                    <mat-label>MainCategory</mat-label>
                                    <mat-select formControlName="parentId">
                                        <mat-option *ngFor="let cat of parentId" [value]="cat.parentId">
                                            {{ cat.parentName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            
                                <mat-form-field>
                                    <mat-label>Department</mat-label>
                                    <mat-select formControlName="departmentId">
                                        <mat-option *ngFor="let dep of departmentId" [value]="dep.id">
                                            {{ dep.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->

                            
                            </div> 
                           
                           

                        </div>

                    </form>

                </div>
            </div>


        </div>
    </div>
</div>