import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const token =sessionStorage.getItem(AppConstants.SESSION_VAR_TOKEN);

    if (!this.isExcludedUrl(request.url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`
        }
      });
    }

    return next.handle(request);
  }

  private isExcludedUrl(url: string): boolean {
    // Define the URLs that should be excluded from token insertion
    const excludedUrls = ['authenticate','/customer/update'];
   // return true;
    return excludedUrls.some(excludedUrl =>
      url.includes(excludedUrl)
    );
  }
}
