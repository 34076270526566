<div class="edit-page-root-container amenity-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                @if(permission.can_edit){
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
                }
            </div>
        </div>
        <div class="page-details ">
            <div class="detail-section">
                <div class="content-container">
                    <form class="edit-form" [formGroup]="editForm">
                        <div class="form-content-container">
                            <div class="common-ug-partion">
                                <mat-form-field class="code">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="code" maxlength="10">
                                    <mat-error [@if]="getErrorDescription('code')">{{getErrorDescription('code')}}</mat-error>
                                </mat-form-field>
                                <mat-form-field class="name">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name" maxlength="150">
                                    <mat-error [@if]="getErrorDescription('name')">{{getErrorDescription('name')}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div style="display:flex; gap:15px;">
                                <mat-form-field class="description">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput formControlName="description" style="height:110px;"></textarea>
                                </mat-form-field>
                                <div   class="image-main-container">

                                    <div class="image-container">
                                        <img [src]="imagePreview"  style="height:100px;object-fit:contain;margin:10px;">
                                      <div class="actionIcons">
                                        <input type="file" #fileInput (change)="onImageChange($event)" accept="image/*" hidden>
                                        @if(permission.can_edit){
                                         <div class="change icon" (click)="fileInput.click()">Choose File</div>
                                        <div class="clearIcon" (click)="removeImage()"> x </div>
                                        }@else{
                                            <div class="change icon">Choose File</div>
                                        <div class="clearIcon"> x </div>
                                        }
                                        
                                      </div>
                                    </div>
                                </div>
                               
                                    </div>
                            <mat-form-field class="displayOrder">
                                <mat-label>Display Order</mat-label>
                                <input matInput formControlName="displayOrder" maxlength="50" type="number">
                                <mat-error [@if]="editForm.controls['displayOrder'].hasError('required')">Display
                                    Order
                                    should
                                    be
                                    entered</mat-error>
                                <mat-error [@if]="editForm.controls['displayOrder'].hasError('maxlength')">Maximum
                                    length is
                                    50.</mat-error>
                            </mat-form-field>
                            <div class="status-toggle-container">
                                <span class="status-toggle-title">Valid</span>
                                <mat-slide-toggle formControlName="isValid" class="status-toggle"  [disabled]="amenityInfo.isSystem === 1"></mat-slide-toggle>
                            </div>
                        </div>
                    </form>

                </div>
            </div>


        </div>
    </div>
</div>