import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ToolbarComponent } from './common/components/toolbar/toolbar.component';
import { SidenavComponent } from './common/components/sidenav/sidenav.component';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";

import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserInfoComponent } from './common/components/user-info/user-info.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { ConfirmDialogComponent } from './common/components/confirm-dialog/confirm-dialog.component';
import { DashboardEditComponent } from './pages/dashboard/dashboard-edit/dashboard-edit.component';
import { MasterFloatingMenuComponent } from './common/components/master-floating-menu/master-floating-menu.component';
import { UserListItemComponent } from './pages/user/components/user-list-item/user-list-item.component';
import { UserComponent } from './pages/user/user.component';
import { SnackBarComponent } from './common/components/snackbar/snackbar.component';
import { UserEditDialogComponent } from './pages/user/dialogs/user-edit-dialog/user-edit-dialog.component';
import { StylePaginatorDirective } from './common/components/paginator/style-paginator.directive';
import { SimplePaginatorComponent } from './common/components/paginator/simple-paginator/simple-paginator.component';
import { TokenInterceptorService } from './auth/token-interceptor.service';
import { SorterComponent } from './common/components/sorter/sorter.component';
import { LoaderComponent } from './common/components/loader/loader.component';
import { SearchFilterComponent } from './common/components/search-filter/search-filter.component';
import { FilterItemComponent } from './common/components/search-filter/filter-item/filter-item.component';

import { UserSummaryComponent } from './pages/user/components/user-summary/user-summary.component';
import { RewardSubmenuComponent } from './common/components/sidenav/submenu/reward-submenu/reward-submenu.component';
import { ExtraPagesMenuComponent } from './common/components/extra-pages-menu/extra-pages-menu.component';
import { SearchFilterSaveDialogComponent } from './common/components/search-filter/dialogs/search-filter-save-dialog/search-filter-save-dialog.component';
import { SearchFilterLoadDialogComponent } from './common/components/search-filter/dialogs/search-filter-load-dialog/search-filter-load-dialog.component';
import { FilterDialogComponent } from './common/components/search-filter/dialogs/filter-dialog/filter-dialog.component';
import { ChangePasswordDialogComponent } from './pages/user/dialogs/change-password-dialog/change-password-dialog.component';
import { RegionComponent } from './pages/region/region.component';
import { RegionEditComponent } from './pages/region/region-edit/region-edit.component';
import { PickupLocationComponent } from './pages/pickup-location/pickup-location.component';
import { PickupLocationEditComponent } from './pages/pickup-location/pickup-location-edit/pickup-location-edit.component';
import { DepartmentComponent } from './pages/department/department.component';
import { DepartmentEditComponent } from './pages/department/department-edit/department-edit.component';
import { AmenityComponent } from './pages/amenity/amenity.component';
import { AmenityEditComponent } from './pages/amenity/amenity-edit/amenity-edit.component';
import { ItemCategoryComponent } from './pages/item-category/item-category.component';
import { ItemCategoryEditComponent } from './pages/item-category/item-category-edit/item-category-edit.component';
import { ItemsComponent } from './pages/items/items.component';
import { ItemEditComponent } from './pages/items/item-edit/item-edit.component';
import { CompanyComponent } from './pages/company/company.component';
import { EditTimingComponent } from './pages/company/edit-timing/edit-timing.component';
import { MastersalesReportComponent } from './pages/mastersales-report/mastersales-report.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrderViewComponent } from './pages/orders/order-view/order-view.component';
import { OrderStatusPipe } from './common/pipes/order-status.pipe';
import { ReversePipe } from './common/pipes/reverse.pipe';
import { OrderExportComponent } from './pages/orders/order-export/order-export.component';
import { DaysCustomPipe } from './common/pipes/days.pipe';
import { OrderOptionbarComponent } from './pages/orders/order-optionbar/order-optionbar.component';
import { HolidayComponent, HolidayEditPopUp } from './pages/holiday/holiday.component';
import { MonthsCustomPipe } from './common/pipes/month.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OODatePipe } from './common/pipes/date.pipe';
import { ImportItemComponent } from './pages/items/import-item/import-item.component';
import { ShopsComponent } from './pages/shops/shops.component';
import { ShopEditComponent } from './pages/shops/shop-edit/shop-edit/shop-edit.component';
import { ShopStatusComponent } from './pages/shops/shops.component';
import { ImportShopComponent } from './pages/shops/import-shop/import-shop.component';
import { QuillModule } from 'ngx-quill';
import { HtmlEditComponent } from './pages/company/html-edit/html-edit.component';
import { MastersOptionbarComponent } from './common/components/masters-optionbar/masters-optionbar.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomerViewComponent } from './pages/customer/customer-view/customer-view.component';
import { CustomerEditComponent } from './pages/customer/customer-edit/customer-edit.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';
import { SalesSummaryCardComponent } from './pages/dashboard/components/sales-summary-card/sales-summary-card.component';
import { OrderSummaryCardComponent } from './pages/orders/order-summary-card/order-summary-card.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { ShopMessageDialogComponent } from './pages/shops/shop-message-dialog/shop-message-dialog.component';
@NgModule({ declarations: [
        AppComponent,
        ToolbarComponent,
        SidenavComponent,
        LoginComponent,
        DashboardComponent,
        UserInfoComponent,
        ConfirmDialogComponent,
        DashboardEditComponent,
        MasterFloatingMenuComponent,
        UserComponent,
        UserListItemComponent,
        SnackBarComponent,
        UserEditDialogComponent,
        StylePaginatorDirective,
        SimplePaginatorComponent,
        SorterComponent,
        SearchFilterComponent,
        FilterItemComponent,
        LoaderComponent,
        UserEditComponent,
        SearchFilterComponent,
        UserSummaryComponent,
        RewardSubmenuComponent,
        ExtraPagesMenuComponent,
        ChangePasswordDialogComponent,
        SearchFilterLoadDialogComponent,
        SearchFilterSaveDialogComponent,
        SearchFilterSaveDialogComponent,
        FilterDialogComponent,
        RegionComponent,
        RegionEditComponent,
        PickupLocationComponent,
        PickupLocationEditComponent,
        DepartmentComponent,
        DepartmentEditComponent,
        AmenityComponent,
        AmenityEditComponent,
        ItemCategoryComponent,
        ItemCategoryEditComponent,
        ItemsComponent,
        ItemEditComponent,
        CompanyComponent,
        EditTimingComponent,
        MastersalesReportComponent,
        OrdersComponent,
        OrderStatusPipe,
        ReversePipe,
        OrderViewComponent,
        OrderExportComponent,
        DaysCustomPipe,
        OrderOptionbarComponent,
        HolidayComponent,
        HolidayEditPopUp,
        MonthsCustomPipe,
        OODatePipe,
        ImportItemComponent,
        ShopsComponent,
        ShopEditComponent,
        ShopStatusComponent,
        ImportShopComponent,
        HtmlEditComponent,
        MastersOptionbarComponent,
        CustomerComponent,
        CustomerViewComponent,
        CustomerEditComponent,
        SalesSummaryCardComponent,
        OrderSummaryCardComponent,
        AccessDeniedComponent,
        ShopMessageDialogComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        MatAutocompleteModule,
        BrowserAnimationsModule,
        NgxChartsModule,
        ReactiveFormsModule,
        MaterialModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        FlexLayoutModule,
        QuillModule.forRoot({
          modules: {
            resize: {
              
              showSize: false,
              toolbar: {
                sizeTools: false,
                alingTools: true
              },
            },
            
          },
          theme: 'snow'
        })],
         providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
        DatePipe
    ] })
export class AppModule { }