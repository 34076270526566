export class AppConstants{
   
   
    static readonly SESSION_VAR_USER= 'user';
    static readonly SESSION_VAR_TOKEN='auth_token';
    static readonly SESSION_VAR_CRM_TOKEN='auth_crm_token';

    static readonly SESSION_VAR_TAGS='tags';
    static readonly SESSION_VAR_SEGMENTS='tags';
    static readonly SESSION_SYSTEMPARAM='system_param';
    


};
// export const  SESSION_VAR_USER= 'user';
// export const SESSION_VAR_TOKEN='auth_token';

// export const  SESSION_VAR_TAGS='tags';
// export const SESSION_VAR_SEGMENTS='tags';
export const SESSION_MASTER_CUSTMIZATION = 'customisation_option_detail';
export const SESSION_MASTER_COMBO = 'combo_option_detail';

export const  SESSION_VAR_STATE='page_state';

