import { CompanyService } from '../company.service';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import Quill from 'quill';
import QuillResizeModule from '@botom/quill-resize-module';
Quill.register("modules/resize", QuillResizeModule);
@Component({
  selector: 'app-html-edit',
  templateUrl: './html-edit.component.html',
  styleUrl: './html-edit.component.scss'
})
export class HtmlEditComponent {
  quillEditor: any;
 
  constructor(
    private builder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<HtmlEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companyService: CompanyService,// Inject your RewardService
    private snackBarService: SnackBarService
  ) {
    console.log(this.data);
   }

   ngOnInit(): void {
    
  }

   actionClose(): void {
    this.dialogRef.close();
  }
  actionCancel(): void {
    this.dialogRef.close();
  }
  actionSave(): void {
    
    this.companyService.updateCompanySettings(this.data.data).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          let snckBarData: SnackBarData = {
            type: 'success',
            title: 'Saved',
            message: 'Company details updated  successfully.',
          };
          this.snackBarService.openSnackBar(snckBarData);
          this.dialogRef.close();
        }
      },
      (error: any) => {
        let snackBarData: SnackBarData = {
          title: 'Failed!!!',
          type: 'error',
          message: 'Failed to Update'
        };
        this.snackBarService.openSnackBar(snackBarData);
        this.dialogRef.close();
      }
    );
  }

}
