<div class="shop-component-container page-root-container">
    <div class="page-header">
      <button mat-button class="nav-button" (click)="navBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
      <div class="page-title">Shop Import</div>
      <div class="page-actions">
        <button mat-button class="btn-discard" (click)="navBack()">DISCARD</button>
        <button mat-button class="btn-add" (click)="saveImportedShop()">SAVE</button>
      </div>
    </div>
  
    <div class="data-container ">
      <app-loader [show]="isLoading"></app-loader>
      <app-search-filter [pagingData]="pagingData"
        [pageSizeOptions]="[5, 10, 20, 50, 100]" [filter]="filter"
        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true"  [hideAdvancedFilter]="hideAdvancedFilter">
      </app-search-filter>
  
      <ng-container *ngIf="pagingData.length > 0; else noData">
        <ng-container *ngIf="!isHandset">
          <section tabindex="0" class="table-container">
            <table matSort mat-table [dataSource]="showSelectedOnly ? displayedShops : shopsList" (matSortChange)="sortData($event)">
              <ng-container matColumnDef="moreVert">
                <th mat-header-cell *matHeaderCellDef style="width: 10px;  padding: 0px;">
                  <a mat-icon-button [matMenuTriggerFor]="rowMenu">
                    <mat-icon>more_vert</mat-icon>
                  </a>
                  <mat-menu #rowMenu>
                    <button mat-menu-item (click)="showAllShops()">All</button>
                    <button mat-menu-item (click)="showSelectedShops()">Selected</button>
                  </mat-menu>
                </th>
                <td mat-cell *matCellDef="let shop" style="width: 10px; padding: 0px;"></td>
              </ng-container>
              <ng-container matColumnDef="shop">
                <th mat-sort-header="shop" mat-header-cell *matHeaderCellDef style="width: 10px; padding: 0px;">Item#
                <td mat-cell *matCellDef="let shop; let i = index">{{ i + 1 }}</td>
              </ng-container>
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef style="width: 50px; padding: 0px;">
                  Select
                </th>
                <td mat-cell *matCellDef="let shop; let i = index">
                  <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="shop.selected"
                    (change)="$event ? selection.toggle(shop) : null"
                    [checked]="selection.isSelected(shop)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="code" >
                <th mat-sort-header="code" style="width:150px;" mat-header-cell *matHeaderCellDef>Code</th>
                <td mat-cell *matCellDef="let shop">{{ shop.code }}</td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-sort-header="name" mat-header-cell *matHeaderCellDef>Shop Name</th>
                <td mat-cell *matCellDef="let shop" style="padding:0">{{ shop.name }}</td>
              </ng-container>
              <ng-container matColumnDef="region">
                <th mat-header-cell *matHeaderCellDef style="padding: 0px; width: 250px;">Region</th>
                <td mat-cell *matCellDef="let shop; let i = index" style="padding:0;">
    
                  <!-- <mat-form-field style="margin-top:10px;"> -->
                    <mat-select [(ngModel)]="shop.ooRegionId" placeholder="" [disabled]="shop.selected === false">
                      <mat-option *ngFor="let region of regionList" [value]="region.id" >
                        {{ region.name }}
                      </mat-option>
                    </mat-select>
                  <!-- </mat-form-field> -->
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row class="table-item-row" *matRowDef="let item; columns: displayedColumns;"></tr>
            </table>
          </section>
        </ng-container>
      </ng-container>
  
      <ng-template #noData>
        <div class="no-data-container">
          <img src="assets/images/no-data.png">
          <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
        </div>
      </ng-template>
    </div>
  </div>
  