<app-masters-optionbar> </app-masters-optionbar>
<div class="item-component-container  page-root-container">
        <div class="page-header">
                <div class="page-title"><img class="icon" src="assets/images/menu/svg/items.svg"><span style=" margin-top: 3px;">Item</span></div>                
                <div class="page-actions">
                        @if(permission.can_export){
                        <button mat-button color="accent" class="btn-export" (click)="exportClicked()" [disabled]="IsDownloading">
                                <img class="download-icon" [src]="IsDownloading ? 'assets/images/download-animation/download-anim.svg' : 'assets/images/download-animation/download.svg'">
                                Export</button>
                        }
                        @if(permission.can_execute){
                        <button mat-button color="accent" class="btn-export" (click)="directToImportItem()">
                                <div style="display: flex; align-items: center;">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px"
                                        viewBox="0 0 24 24" style=" height: 16px; width: 16px;fill: rgb(58 35 35 / 85%);margin-right: 8px;transform: rotate(90deg);">
                                        <path
                                                d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Z">
                                        </path>
                                       
                                </svg>
                                Import
                                </div>
                        </button>}
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <app-search-filter #searchFilter  [pagingData]="pagingData"
                        [pageSizeOptions]="[5,10,20,50,100]"  [filter]="filter"
                        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
                </app-search-filter>
                <!-- @if(pagingData.length>0){
                @if(!isHandset){ -->
                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="dataSource" > 
                                <ng-container matColumnDef="item">
                                        <th mat-header-cell *matHeaderCellDef class="item-header">#</th>
                                        <td  mat-cell *matCellDef="let item; let i = index" class="item-column">{{ (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                                </ng-container>
                                <ng-container matColumnDef="code">
                                        <th mat-sort-header="oo_sale_item.code" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="code-header">Code</th>
                                        <td mat-cell *matCellDef="let item" class="code-column"> {{item.code}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                        <th mat-sort-header="oo_sale_item.name" mat-header-cell *matHeaderCellDef (click)="sortData($event)"> Name </th>
                                        <td mat-cell *matCellDef="let item"> {{item.name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="category">
                                        <th mat-sort-header="item_category.name" mat-header-cell *matHeaderCellDef (click)="sortData($event)"> Category </th>
                                        <td mat-cell *matCellDef="let item"> {{item.itemCategoryName}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="item_type">
                                        <th mat-sort-header="sale_item.item_type" mat-header-cell *matHeaderCellDef (click)="sortData($event)"> Type </th>
                                        <td mat-cell *matCellDef="let item"> 
                                                @if(item.item_type === 1){
                                                        Regular Item
                                                }@else if(item.item_type === 2){
                                                        Customizable
                                                }@else if(item.item_type === 3){
                                                        Combo
                                                }@else{}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="isCustomizable">
                                        <th mat-sort-header="is_customizable" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="customizable-header"> 
                                                Customizable </th>
                                        <td mat-cell *matCellDef="let item" class="customizable-column">
                                                <mat-icon *ngIf="item.is_customizable || item.item_type === 3">check</mat-icon>
                                              
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="price">
                                        <th mat-sort-header="sale_item.fixed_price" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="price-header"> Price</th>
                                        <td mat-cell *matCellDef="let item"  class="price-column"> {{item.fixed_price
                                                | currency:'USD':'symbol':'1.2-2' }}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="isValid">
                                        <th mat-sort-header="oo_sale_item.is_valid" (click)="sortData($event)" mat-header-cell *matHeaderCellDef class="valid-header"> Valid </th>
                                        <td mat-cell *matCellDef="let item" class="valid-column">@if(item.is_valid === 1){<mat-icon>check</mat-icon>
                                        }@else{}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                        
                                                <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                        (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                        <mat-icon>more_vert</mat-icon>
                                                </a>

                                                <mat-menu #rowMenu>
                                                       @if(permission.can_edit){
                                                        <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                       }@else{
                                                        <button mat-menu-item (click)="onEdit(rowItem)">View</button>
                                                } 
                                                </mat-menu>
                                        
                                        </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row" *matRowDef="let item; columns: displayedColumns;"
                                        (click)="onRowSelected(item)" (dblclick)="onEdit(item)"
                                        [ngClass]=" {'row-selected' : item.id===selectedItem?.id}">
                                </tr>
                        </table>
                </section>
                @if(pagingData.length<=0){
                <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
                </div>
                }
        </div>

</div>