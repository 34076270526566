import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppConstants } from '../common/app.constants';



@Injectable({
  providedIn: 'root'
})
export class AuthCrmService {
  crmEnv: any;
  constructor(private http: HttpClient) {
    this.crmEnv = environment.crm;
  }

  /**
   * Returns token from the local storage
   * if not found any token call the API to obtain the token and set to storage and return it.
   * @returns 
   */
  getToken(): Observable<any> {

    const storedToken = sessionStorage.getItem(AppConstants.SESSION_VAR_CRM_TOKEN);

    if (storedToken) {
      // Token found in local storage, return it as an Observable
      return of(storedToken);
    } else {
      // Token not found, make an API request to fetch it
      let data = { user_name: this.crmEnv.userId, password: this.crmEnv.password, apikey: this.crmEnv.appKey };
      return this.http.post(this.crmEnv.apiUrl + '/auth', data).pipe(
        map((response) => response),
        catchError((error) => {
          console.error('Error fetching token:', error);
          return of(''); // Return an empty string in case of error
        }),
        switchMap((response: any) => {
          let newToken = '';
          if (response.status == 'SUCCESS') {
            newToken = response.token;
          }
          // Store the new token in local storage
          sessionStorage.setItem(AppConstants.SESSION_VAR_CRM_TOKEN,newToken);
          // Return the new token as an Observable
          return of(newToken);
        })
      );
    }
  }
}
