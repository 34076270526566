import { Component, input, Input } from '@angular/core';

@Component({
  selector: 'app-sales-summary-card',
  templateUrl: './sales-summary-card.component.html',
  styleUrl: './sales-summary-card.component.scss'
})
export class SalesSummaryCardComponent {



  @Input() salesType: any;
  @Input() salesData: any = {
    toDeliverOrders: 0,
    toDeliverTotalAmt: 0,
    num_orders: 1,
    total_amount: 0,
    deliveredOrders: 0,
    deliveredTotalAmt: 0
  };

  constructor() {

  }

  /**
   * Returns the class according to saleType
   */
  get saleClass(): string {

    var className = 'sales-today';
    switch (this.salesType) {
      case 'today':
        className = 'sales-today';
        break;
      case 'week':
        className = 'sales-this-week';
        break;
      case 'month':
        className = 'sales-this-month';
        break;
      case 'year':
        className = 'sales-this-year';
        break;
    }

    return className;
  }

  get title():string{

    var title = 'sales-today';
    switch (this.salesType) {
      case 'today':
        title = 'TODAY';
        break;
      case 'week':
        title = 'THIS WEEK';
        break;
      case 'month':
        title = 'THIS MONTH';
        break;
      case 'year':
        title = 'THIS YEAR';
        break;
    }

    return title;
  }
}
