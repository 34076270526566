import { Injectable } from "@angular/core";
import { DepartmentModel } from "./models/department.model";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class DepartmentService {
  constructor(private http: HttpClient) {}


  /**
   *
   * @param departmentInfo
   * Calls the API to delete the record
   */
  delete(departmentInfo: DepartmentModel): Observable<any> {
    return this.http.get(environment.baseUrl + "department/delete?id=" + departmentInfo.id);

  }
  getDepartmentList(): any {
    return this.http.get<any>(environment.baseUrl + "department/get-list");
  }

  getDepartmentListExt(requestData:RequestData): Observable<any> {

   
    return this.http.post(environment.wsPhp+'/department/get-list', requestData);
  
}
getDepartmentListExtJava(staticPayload: { search: { filters: { filter: never[]; scope: { offset: string; limit: number; }; }; sort: { column: string; order: string; }[]; }; }): Observable<any> {
  return this.http.post<Response>(
    environment.baseUrl + "department/get-list-ext",
    staticPayload
  );
}

  getId(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + "department/get?id=" + id);
    
  }
  /**
   *
   * @param userInfo
   * Calls the update API to insert/update the record
   */
  update(requestParams: any) {
    return this.http.post(
      environment.baseUrl + "department/save",
      requestParams
    );
  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.wsPhp + '/department/export', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

}

