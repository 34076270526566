import { Injectable } from '@angular/core';
import {AppConstants} from '../common/app.constants'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( ) { }

  /**
   * 
   * @returns Check the sesion for loged in user
   */
  isloggedin():boolean{
    
    return sessionStorage.getItem(AppConstants.SESSION_VAR_USER)!=null;
  }

  /**
   * Returns the user from session
   */
  getUser():any{
    let user=sessionStorage.getItem(AppConstants.SESSION_VAR_USER);
    return (user!==null)?JSON.parse(user):undefined;
  }

  
}
