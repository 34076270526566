<div class="edit-page-root-container mstsalesrpt-edit-root-container  page-root-container">
    <div class="page-content">
        <!-- <app-loader [show]="isLoading"></app-loader> -->
        <div class="page-header">
            <div class="page-title"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="volume-analysis" style="width:27px">
                <path
                    d="M7,25.08H5V13a3,3,0,0,1,3-3H23v2H8a1,1,0,0,0-1,1ZM45,38H43V29H41v9H7V35H21V33H3v2H5v3H3a1,1,0,0,0-1,1v2a5,5,0,0,0,5,5H41a5,5,0,0,0,5-5V39A1,1,0,0,0,45,38ZM24,10H46V25a3,3,0,0,1-3,3H27a3,3,0,0,1-3-3Zm5,5H41V13H29Zm0,4H41V17H29Zm0,4H41V21H29ZM46,5V8H24V5a3,3,0,0,1,3-3H43A3,3,0,0,1,46,5ZM31,4H28V6h3Zm5,0H33V6h3ZM11,27a1,1,0,0,0-1-1H6a1,1,0,0,0-1,1v5h6Zm3-6a1,1,0,0,0-1,1V32h6V22a1,1,0,0,0-1-1Z"
                    data-name="33 Volume Analysis, Bar Chart, Bar Graph, Column Graph, Graphical Representation">
                </path>
            </svg><span class="title"> {{title}}</span></div>
            <div class="action-btn-container">
                <!-- <button mat-flat-button class="action-btn btn-discard">DISCARD</button> -->
                <!-- <button mat-flat-button class="action-btn btn-save" (click)="actionSaveBussiness()">SAVE</button> -->
                @if(permission.can_export){
                <!-- <button mat-flat-button class="action-btn btn-export" color="accent"  (click)="export()">
                    <mat-icon>save_alt</mat-icon>Export</button> -->
                    <button mat-button  class="btn-export" 
                    (click)="export()" 
                    >
              <img class="download-icon" 
                   [src]="IsDownloading ? 'assets/images/download-animation/download-anim.svg' : 'assets/images/download-animation/download.svg'">
              Export
            </button>}
            </div>
        </div>
        <div class="page-details ">
            <div class="detail-section">
                <div class="content-container">
                    <div class="msr-criteria-container">

                        <form [formGroup]="fgPeriod">
                            <div class="date-period-container">
                                <div class="date-period-title">
                                    <span>Date period</span>
                                </div>
                                <div class="date-period-from">
                                    <mat-form-field style="margin-left: 10px">
                                        <mat-label>Date From</mat-label>
                                        <input matInput [matDatepicker]="pickerFrom" placeholder="Date From"
                                            formControlName="periodFrom" [(ngModel)]="periodFrom">
                                        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerFrom></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="date-period-to">
                                    <mat-form-field style="margin-left: 10px">
                                        <mat-label>Date To</mat-label>
                                        <input matInput [matDatepicker]="pickerTo" placeholder="Date To"
                                            formControlName="periodTo" [(ngModel)]="periodTo">
                                        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerTo></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field>
                                        <mat-label>Based on</mat-label>
                                        <mat-select placeholder="Based on" [(value)]="dateType">
                                            <mat-option *ngFor="let item of dateTypeList" [value]="item.value">
                                                {{item.title}} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                        <!--SHOPS-->
                        <div class="list-container shops">
                            <div class="header" (click)="shopsIsOpen=!shopsIsOpen;">
                                <span>Shops ({{getSelectedCount(shops)}})</span>
                                <div class="toggle-arrow-container">
                                    <button *ngIf="shopsIsOpen" mat-flat-button color="warn" class="btn-rest"
                                        (click)="$event.stopPropagation();reset(shops);">Reset</button>
                                    <mat-icon *ngIf="shopsIsOpen">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="!shopsIsOpen">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div *ngIf="shopsIsOpen" class="list">
                                <div class="item-list all">
                                    <ng-container *ngFor="let item of shops">
                                        <div *ngIf="!item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_right</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="item-list selected">
                                    <ng-container *ngFor="let item of shops">
                                        <div *ngIf="item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_left</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!--REGION-->
                        <div class="list-container regions">
                            <div class="header" (click)="regionsIsOpen=!regionsIsOpen;">
                                <span>Regions ({{getSelectedCount(regions)}})</span>
                                <div class="toggle-arrow-container">
                                    <button *ngIf="regionsIsOpen" mat-flat-button color="warn" class="btn-rest"
                                        (click)="$event.stopPropagation();reset(regions);">Reset</button>
                                    <mat-icon *ngIf="regionsIsOpen">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="!regionsIsOpen">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div *ngIf="regionsIsOpen" class="list">
                                <div class="item-list all">
                                    <ng-container *ngFor="let item of regions">
                                        <div *ngIf="!item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_right</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="item-list selected">
                                    <ng-container *ngFor="let item of regions">
                                        <div *ngIf="item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_left</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <!--DEPARTMENT-->
                        <div class="list-container departments">
                            <div class="header" (click)="departmentIsOpen=!departmentIsOpen;">
                                <span>Departments ({{getSelectedCount(departments)}})</span>
                                <div class="toggle-arrow-container">
                                    <button *ngIf="departmentIsOpen" mat-flat-button color="warn" class="btn-rest"
                                        (click)="$event.stopPropagation();reset(departments);">Reset</button>
                                    <mat-icon *ngIf="departmentIsOpen">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="!departmentIsOpen">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div *ngIf="departmentIsOpen" class="list">
                                <div class="item-list all">
                                    <ng-container *ngFor="let item of departments">
                                        <div *ngIf="!item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_right</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="item-list selected">
                                    <ng-container *ngFor="let item of departments">
                                        <div *ngIf="item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_left</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <!--itemMainCategories-->
                        <div class="list-container itemMainCategories">
                            <div class="header" (click)="itemMainCategoriesIsOpen=!itemMainCategoriesIsOpen;">
                                <span>Main Item Categories ({{getSelectedCount(itemMainCategories)}})</span>
                                <div class="toggle-arrow-container">
                                    <button *ngIf="itemMainCategoriesIsOpen" mat-flat-button color="warn"
                                        class="btn-rest"
                                        (click)="$event.stopPropagation();reset(itemMainCategories);">Reset</button>
                                    <mat-icon *ngIf="itemMainCategoriesIsOpen">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="!itemMainCategoriesIsOpen">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div *ngIf="itemMainCategoriesIsOpen" class="list">
                                <div class="item-list all">
                                    <ng-container *ngFor="let item of itemMainCategories">
                                        <div *ngIf="!item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_right</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="item-list selected">
                                    <ng-container *ngFor="let item of itemMainCategories">
                                        <div *ngIf="item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_left</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <!--itemCategories-->
                        <div class="list-container itemCategories">
                            <div class="header" (click)="itemCategoriesIsOpen=!itemCategoriesIsOpen;">
                                <span>Sub Item Categories ({{getSelectedCount(itemCategories)}})</span>
                                <div class="toggle-arrow-container">
                                    <button *ngIf="itemCategoriesIsOpen" mat-flat-button color="warn" class="btn-rest"
                                        (click)="$event.stopPropagation();reset(itemCategories);">Reset</button>
                                    <mat-icon *ngIf="itemCategoriesIsOpen">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="!itemCategoriesIsOpen">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div *ngIf="itemCategoriesIsOpen" class="list">
                                <div class="item-list all">
                                    <ng-container *ngFor="let item of itemCategories">
                                        <div *ngIf="!item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_right</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="item-list selected">
                                    <ng-container *ngFor="let item of itemCategories">
                                        <div *ngIf="item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_left</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <!--items-->
                        <div class="list-container items">
                            <div class="header" (click)="itemsIsOpen=!itemsIsOpen;">
                                <span>Items ({{getSelectedCount(items)}})</span>
                                <div class="toggle-arrow-container">
                                    <button *ngIf="itemsIsOpen" mat-flat-button color="warn" class="btn-rest"
                                        (click)="$event.stopPropagation();reset(items);">Reset</button>
                                    <mat-icon *ngIf="itemsIsOpen">keyboard_arrow_up</mat-icon>
                                    <mat-icon *ngIf="!itemsIsOpen">keyboard_arrow_down</mat-icon>
                                </div>
                            </div>
                            <div *ngIf="itemsIsOpen" class="list">
                                <div class="item-list all">
                                    <ng-container *ngFor="let item of items">
                                        <div *ngIf="!item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_right</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="item-list selected">
                                    <ng-container *ngFor="let item of items">
                                        <div *ngIf="item.selected" class="list-item"
                                            (click)="item.selected=!item.selected;">
                                            <div class="name">
                                                <span>{{item.item.name}}</span>
                                            </div>
                                            <div class="selection">
                                                <mat-icon>chevron_left</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="sort-container">
                            <div class="title">
                                <span>Sort options</span>
                            </div>
                            <div class="sort-item-list">
                                <mat-form-field class="filter-select">
                                    <mat-label>Sort On</mat-label>
                                    <mat-select placeholder="Sort On" [(value)]="sortBy">
                                        <mat-option *ngFor="let item of sortByList" [value]="item.value"> {{item.title}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="sort-dir-list">
                                <mat-form-field class="filter-select">
                                    <mat-label>Order By</mat-label>
                                    <mat-select placeholder="Order By" [(value)]="orderBy">
                                        <mat-option *ngFor="let item of orderByList" [value]="item.value">
                                            {{item.title}} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="include-options">
                            <mat-checkbox class="example-margin" [(ngModel)]="includeShopDetails">Include Shop
                                Details</mat-checkbox>
                            <mat-checkbox class="example-margin" [(ngModel)]="includeAddColumns"> Show Department/Item
                                Category</mat-checkbox>
                        </div>


                    </div>

                </div>
            </div>


        </div>
    </div>
</div>