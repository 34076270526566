import { Injectable } from "@angular/core";
import { ItemsModel } from "./models/items.model";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  constructor(private http: HttpClient) {}


  /**
   *
   * @param itemsInfo
   * Calls the API to delete the record
   */
  delete(itemsInfo: ItemsModel): Observable<any> {
    return this.http.get(environment.baseUrl + "itemcategory/delete?id=" + itemsInfo.id);

  }
  // deleteItemImage(itemsInfo: ItemsModel): Observable<any> {
  //   return this.http.get(environment.baseUrl + "itemcategory/delete?id=" + itemsInfo.id);

  // }
  uplaodItemImage(formData: any): any {
    return this.http.post<any>(
      environment.baseUrl + "saleitem/upload-files/",
      formData
    );
  }

  // getItemsList(staticPayload: { search: { filters: { filter: never[]; scope: { offset: string; limit: number; }; }; sort: { column: string; order: string; }[]; }; }): Observable<any> {
  //   return this.http.post<Response>(
  //     environment.baseUrl + "saleitem/get-list",
  //     staticPayload
  //   );
  // }
  getItemsList(requestData:RequestData): Observable<any> {

   
    return this.http.post(environment.wsPhp+'/saleitem/get-list', requestData);
  
}


  getId(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + "saleitem/get?id=" + id);
    
  }
  /**
   *
   * @param userInfo
   * Calls the update API to insert/update the record
   */
  update(requestParams: any) {
    return this.http.post(
      environment.baseUrl + "saleitem/save",
      requestParams
    );
  }
  deleteItemImage(requestParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "saleitem/delete-files/",
      requestParams
    );
  }
  loadCustomizationOptionMasterDtl(): any {
    return this.http.get<any>(environment.baseUrl + "customization-info/getCustomizationOptionDtl");
  }
  loadComboOptionMasterDtl(): any {
    return this.http.get<any>(environment.baseUrl + "combo-info/getComboOptionDtl");
  }
  getBusinessType(): any {
    return this.http.get<any>(environment.baseUrl + "businesstype/get-list");
  }
  getItemCategoryList(): any {
    return this.http.get<any>(environment.baseUrl + "itemcategory/get-list");
  }
  saveItemDetails(bodyParams: any): any {
    return this.http.post<any>(
      environment.baseUrl + "saleitem/save",
      bodyParams
    );
  }
  getSaleItemImportList(jwsRequestData:RequestData): Observable<any>  {
    return this.http.post<any>(
      environment.baseUrl + "saleitem/get-list-for-import",
      jwsRequestData
    );
  }

  saveImportedItemDetails(selectedItems: any): any {
    return this.http.post<any>(
      environment.baseUrl + "saleitem/save-list",
      selectedItems
    );
  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.wsPhp + '/saleitem/export', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

}

