<div class="item-component-container page-root-container">
        <div class="page-header">
          <button mat-button class="nav-button" (click)="navBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
          <div class="page-title">Item Import</div>
          <div class="page-actions">
            <button mat-button class="btn-discard" (click)="navBack()">DISCARD</button>
            <button mat-button class="btn-add" (click)="saveImportedItem()">SAVE</button>
          </div>
        </div>
      
        <div class="data-container ">
          <app-loader [show]="isLoading"></app-loader>
          <app-search-filter  [pagingData]="pagingData"
            [pageSizeOptions]="[5, 10, 20, 50, 100]"  [filter]="filter"
            (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true" [hideAdvancedFilter]="hideAdvancedFilter">
          </app-search-filter>
          <div [style.height]="getNotificationBarHeight()" class="notification-bar">
            <span class="notification">{{getSelectionNotificationMsg()}}</span>
          </div>
          <ng-container *ngIf="pagingData.length > 0; else noData">
            <ng-container *ngIf="!isHandset">
              <section tabindex="0" class="table-container">
                <table matSort mat-table [dataSource]="displayedItems">
                  <ng-container matColumnDef="moreVert">
                    <th mat-header-cell *matHeaderCellDef style="padding: 0px;">
                      <a mat-icon-button [matMenuTriggerFor]="rowMenu" style="width: 28px; height: 28px; min-width: 28px; padding: 0;">
                        <mat-icon>more_vert</mat-icon>
                      </a>
                      <mat-menu #rowMenu>
                        <button mat-menu-item (click)="toggleDisplaySelected()">
                          {{ showSelectedItems ? 'Show All' : 'Show Selected' }}
                        </button>
                         <button mat-menu-item (click)="clearAllSelection()">Clear All Selection</button>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            

                      </mat-menu>

                    </th>
                    <td mat-cell *matCellDef="let item" style="width: 10px; padding: 0px;"></td>
                  </ng-container>
                  <ng-container matColumnDef="item">
                    <th  mat-header-cell *matHeaderCellDef class="item-header">#</th>
                    <td mat-cell *matCellDef="let item; let i = index" class="item-column">{{ (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                  </ng-container>
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef style="width: 50px;">
                      Select
                    </th>
                    <td mat-cell *matCellDef="let item">
                      <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="onRowSelected(item)"
                      [checked]="selection.isSelected(item)">
        </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="code" >
                    <th mat-sort-header="sale_item.code" style="width:150px;" mat-header-cell *matHeaderCellDef>Code</th>
                    <td mat-cell *matCellDef="let item">{{ item.code }}</td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-sort-header="sale_item.name" mat-header-cell *matHeaderCellDef>Item Name</th>
                    <td mat-cell *matCellDef="let item">{{ item.name }}</td>
                  </ng-container>
                 
                  <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef style="padding: 0px; width:250px">Category</th>
                    <td mat-cell *matCellDef="let item; let i = index" 
                        [ngClass]="{'category-select-error': isSaveAttempted && item.selected && item.ooItemCategoryId === CATEGORY_SELECT_ID}" 
                        style="padding:0;">
                      <mat-select [(ngModel)]="item.ooItemCategoryId" placeholder="" [disabled]="!item.selected">
                        <mat-option *ngFor="let category of categoryListFiltered" [value]="category.id">
                          {{ category.name }}
                        </mat-option>
                      </mat-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-sort-header="fixed_price" mat-header-cell *matHeaderCellDef class="price-header">Price</th>
                    <td mat-cell *matCellDef="let item" class="price-column">
                      {{ item.fixedPrice | currency:'USD':'symbol':'1.2-2' }}
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row class="table-element-row" *matRowDef="let item; columns: displayedColumns;"></tr>
                </table>
              </section>
            </ng-container>
          </ng-container>
      
          <ng-template #noData>
            <div class="no-data-container">
              <img src="assets/images/no-data.png">
              <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
            </div>
          </ng-template>
        </div>
      </div>
      