import { CompanyService } from '../company.service';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-timing',
  templateUrl: './edit-timing.component.html',
  styleUrl: './edit-timing.component.scss'
})
export class EditTimingComponent {
  dataProvider: any;
  tempTimeObj: any;
  dummyDataProvider: any;
  istimingError: boolean = false;
  hourlistVar: any;
  errorList: any;
  dayList: any;
  disableSelect: boolean = true;
  //  { day: number; label: string; }[];
  constructor(
    private builder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditTimingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companyService: CompanyService,// Inject your RewardService
    private snackBarService: SnackBarService
  ) { }


  ngOnInit(): void {
    this.dayList = [
      {
        day: 1,
        label: 'Monday',
      },
      {
        day: 2,
        label: 'Tuesday',
      },
      {
        day: 3,
        label: 'Wednesday',
      },
      {
        day: 4,
        label: 'Thursday',
      },
      {
        day: 5,
        label: 'Friday',
      },
      {
        day: 6,
        label: 'Saturday',
      },
      {
        day: 7,
        label: 'Sunday',
      }
    ];
      console.log(this.dayList[0].label);
    this.loadDataProvider();
  }
  actionClose(): void {
    this.dialogRef.close();
  }


  /**
     * 
     * load order service timing info
     */
  loadDataProvider(): any {
    this.companyService.getOrderServiceTimingByService(this.data.id).subscribe(
      (response: any) => {
        if (response.successCode === 0) {
          this.dataProvider = response.data;
          this.arrayFormatter();
        }
      },
      (error: any) => {
        console.log(error);
      },
    );

  }
  arrayFormatter(): void {
    for (let index = 1; index <= 7; index++) {
      const isData = this.dataProvider.filter(function (el: any) {
        return el.weekday === index;
      });
      if (isData.length < 1) {
        // no data
        this.dataProvider.push({
          delay: 30,
          from: '',
          to: '',
          serviceId: this.data.id,
          weekday: index,
        });
      } else {
        const dataIndex = this.dataProvider.findIndex(function (el: any) {
          return el === isData[0];
        });
        if (dataIndex !== -1) {
          this.tempTimeObj = Object.assign(this.dataProvider[dataIndex]);
          this.tempTimeObj.from = this.tempTimeObj.from.slice(0, -3);
          this.tempTimeObj.to = this.tempTimeObj.to.slice(0, -3);
        }
        // have the data
        this.dataProvider[dataIndex] = this.tempTimeObj;
      }
    }
    this.dataProvider.sort(function (a: any, b: any) {
      return a.weekday - b.weekday;
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }



  onchange(event: any, value: any, i: number, type: string): void {
    const currentValue = event.target.value;
    const valueArray = currentValue.split(':');
    if (valueArray.length === 1 && valueArray[0] < 24 && valueArray[0] > 9 && valueArray[0].length === 2) {
      event.target.value = event.target.value + ':00';
    } else if (valueArray.length === 1 && valueArray[0] < 10 && valueArray[0] > 0 && valueArray[0].length === 1) {
      event.target.value = '0' + event.target.value + ':00';
    } else if (valueArray.length === 1 && valueArray[0] < 10 && valueArray[0] > 0 && valueArray[0].length === 2) {
      event.target.value = event.target.value + ':00';
    }
    if (type === 'from') {
      this.dataProvider[i].from = event.target.value;
    } else if (type === 'to') {
      this.dataProvider[i].to = event.target.value;
    }
  }
  /**
   * On save
   * if the details are valid call the API to update the changes.
   */
  actionSave(): void {
    this.istimingError = false;
    for (let index = 0; index < this.dataProvider.length; index++) {
      if (this.dataProvider[index].from === '' && this.dataProvider[index].to === '') {
        // this.dataProvider[index] = null;
      } else if (this.dataProvider[index].delay === '') {
        this.dataProvider[index].delay = 30;
      }
    }
    this.dummyDataProvider = JSON.stringify(this.dataProvider);
    this.dummyDataProvider = JSON.parse(this.dummyDataProvider);
    for (let index = 0; index < this.dataProvider.length; index++) {
      if (this.dummyDataProvider[index].from === '' && this.dummyDataProvider[index].to === '') {
        this.dummyDataProvider[index] = null;
      } else if (this.dummyDataProvider[index].delay === '') {
        this.dummyDataProvider[index].delay = 30;
      }
    }
    this.dummyDataProvider = this.dummyDataProvider.filter(Boolean);
    for (let index = 0; index < this.dummyDataProvider.length; index++) {
      this.hourlistVar = this.dummyDataProvider[index];
      this.errorList = Object.create([]);
      if (this.hourlistVar.from.length === 2 && !isNaN(this.hourlistVar.from)) {
        this.hourlistVar.from = this.hourlistVar.from + ':00';
      }
      if (this.hourlistVar.to.length === 2 && !isNaN(this.hourlistVar.to)) {
        this.hourlistVar.to = this.hourlistVar.to + ':00';
      }
      if (this.hourlistVar.to.length === 1 && !isNaN(this.hourlistVar.to)) {
        this.hourlistVar.to = '0' + this.hourlistVar.to + ':00';
      }
      if (this.hourlistVar.from.length === 1 && !isNaN(this.hourlistVar.from)) {
        this.hourlistVar.from = '0' + this.hourlistVar.from + ':00';
      }
      if (this.hourlistVar.from === '' && this.hourlistVar.to === '') {
        // no error just skip
        continue;
      } else {
        this.errorList = [];
        if (this.hourlistVar.from === '' && this.hourlistVar.to !== '') {
          this.errorList.push('Empty from time');
        }
        if (this.hourlistVar.to === '' && this.hourlistVar.from !== '') {
          this.errorList.push('Empty to List');
        }
        const timefrom = this.hourlistVar.from.split(':');
        const timeTo = this.hourlistVar.to.split(':');
        if (timefrom.length < 2) {
          this.errorList.push('Not a valid time format');
        }
        if (timeTo.length < 2) {
          this.errorList.push('Not a valid time format');
        }
        if ((timefrom.length === 2) && (isNaN(timefrom[0])) && (isNaN(timefrom[1]))) {
          this.errorList.push('Not a valid start time');
        } else {
        }
        if ((timeTo.length === 2) && (isNaN(timeTo[0])) && (isNaN(timeTo[1]))) {
          this.errorList.push('Not a valid end time');
        } else {
        }
        if (!isNaN(timefrom[0]) && timefrom[0] > 23) {
          this.errorList.push('Not a valid hour');
        }
        if (!isNaN(timefrom[1]) && timefrom[1] > 59) {
          this.errorList.push('Not a valid minut');
        }
        if (!isNaN(timeTo[0]) && timeTo[0] > 23) {
          this.errorList.push('Not a valid hour');
        }
        if (!isNaN(timeTo[1]) && timeTo[1] > 59) {
          this.errorList.push('Not a valid minut');
        }
        const fromDateStamp = new Date('1999-01-01T' + this.hourlistVar.from + ':00');
        const toDateStamp = new Date('1999-01-01T' + this.hourlistVar.to + ':00');
        if (fromDateStamp > toDateStamp) {
          this.errorList.push('start time should be less than the end time');
        }
      }

      if (this.errorList === undefined) {
        this.errorList = [];
      }
      this.hourlistVar.error = this.errorList;
      if (this.errorList.length > 0 && this.istimingError === false) {
        this.istimingError = true;
      }
    }
    for (let index = 0; index < this.dummyDataProvider.length; index++) {
      this.dataProvider[index].error = this.dummyDataProvider[index].error;
    }
    if (this.istimingError === false) {
      // submit
      for (let index = 0; index < this.dummyDataProvider.length; index++) {
        if (this.dummyDataProvider[index].from.length === 5) {
          this.dummyDataProvider[index].from = this.dummyDataProvider[index].from + ':00';
        }
        if (this.dummyDataProvider[index].to.length === 5) {
          this.dummyDataProvider[index].to = this.dummyDataProvider[index].to + ':00';
        }
      }
      const requestProvider = {
        serviceId: this.data.id,
        serviceTimeSlots: this.dummyDataProvider,
      };
      this.companyService.saveOrderServiceTimingData(requestProvider).subscribe({
        next: (response: any) => {
          if (response.successCode === 0) {
            //  this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: response.message
            };
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              title: 'Failed!!!',
              type: 'error',
              message: 'Failed to Update'
            };
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error: any) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error',
          };
          this.snackBarService.openSnackBar(snackBarData);
        },
      });

    } else {
      let snackBarData: SnackBarData = {
        message: 'Please enter the valid timeslots',
        title: 'Failed!!!',
        type: 'error',
      };
      this.snackBarService.openSnackBar(snackBarData);

    }
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 58)) {
      return false;
    }
    return true;

  }
}
