import { CustomerService } from '../customer.service';
import { Component, Inject, Pipe, PipeTransform, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrl: './customer-view.component.scss'
})
export class CustomerViewComponent {
  detailProvider: any;
  constructor(
    private builder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CustomerViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,// Inject your RewardService
    private snackBarService: SnackBarService
  ) {
    this.detailProvider = this.data.customerInfo;

  }
  ngOnInit() { }

  actionClose(): void {
    this.dialogRef.close();
  }
  actionCancel(): void {
    this.dialogRef.close();
  }
  // update customer data
  actionSave(): void {
    if (this.detailProvider.isValid === true) {
      this.detailProvider.isValid = 1;
    }
    if (this.detailProvider.isValid === false) {
      this.detailProvider.isValid = 0;
    }
    this.customerService
      .setCustomerInfo({
        id: this.detailProvider.id,
        is_valid: this.detailProvider.is_valid
      })
      .subscribe(
        (response: any) => {
          if (response.status == 'SUCCESS') {
            console.log(response.data);
            this.detailProvider.is_valid = response.data.is_valid;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'Customer Detail updated.',
            };
            this.snackBarService.openSnackBar(snckBarData);

          }
        },
        (error: any) => {
          let snackBarData: SnackBarData = {
            title: 'Failed!!!',
            type: 'error',
            message: 'Failed to Update'
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      );

  }
}
