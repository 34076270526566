import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AppConstants } from '../common/app.constants';
import { StorageService } from '../common/services/storage.service';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  users: any;
  permissionArr: any;
  pagePermission: any;


  constructor(private service: AuthService,
    private router: Router,
    private storageSevice: StorageService,
    private permissionService: PermissionService
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let isAuthenticated: boolean = false;

    if (this.service.isloggedin()) {
      isAuthenticated = true;
    }
    else {
      this.router.navigate(['login']);
      isAuthenticated = false;
    }

    let currentPath = route.url[0].path;

    if (currentPath == undefined || !this.permissionService.hasPermission(currentPath, "view")) {
      this.router.navigate(['access-denied']);
      isAuthenticated = false
    } else {
      isAuthenticated = true;
    }

    return isAuthenticated;
  }





}
