<app-masters-optionbar> </app-masters-optionbar>
<div class="customer-component-container  page-root-container">
    <div class="page-header">
            <div class="page-title"><img class="icon" src="assets/images/menu/svg/customer.svg"><span style=" margin-top: 5px;">Customer</span></div>                

            <div class="page-actions">
              @if(permission.can_export){
                    <button mat-button color="accent" class="btn-export" (click)="exportData()"[disabled]="IsDownloading" >
                        <img class="download-icon" [src]="IsDownloading ? 'assets/images/download-animation/download-anim.svg' : 'assets/images/download-animation/download.svg'">Export</button>
                    }
            </div>
    </div>

    <div class="data-container ">
            <app-loader [show]="isLoading"></app-loader>

            <app-search-filter #searchFilter  [pagingData]="pagingData"
                    [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
                    (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
            </app-search-filter>
            <!-- @if(pagingData.length>0){
            @if(!isHandset){ -->
            <section tabindex="0" class="table-container">
                <table matSort mat-table [dataSource]="dataSource" > 
                                        <ng-container matColumnDef="item">
                                    <th mat-header-cell *matHeaderCellDef class="item-header">#</th>
                                    <td  mat-cell *matCellDef="let element; let i = index" class="item-column">{{ (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                            </ng-container>
                            <ng-container matColumnDef="Given Name">
                                    <th mat-sort-header="v_oo_admin_customer_details_ext.first_name" mat-header-cell *matHeaderCellDef (click)="sortData($event)">Given Name</th>
                                    <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}}
                                    </td>
                            </ng-container>

                            <!-- <ng-container matColumnDef="Last Name">
                                    <th mat-sort-header="v_oo_admin_customer_details_ext.last_name" mat-header-cell *matHeaderCellDef (click)="sortData($event)">Last Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.last_name}}
                                    </td>
                            </ng-container> -->
                            <ng-container matColumnDef="Email Address">
                                    <th mat-sort-header="v_oo_admin_customer_details_ext.email" mat-header-cell *matHeaderCellDef (click)="sortData($event)"> Email Address </th>
                                    <td mat-cell *matCellDef="let element"> {{element.email}}
                                    </td>
                            </ng-container>
                            <ng-container matColumnDef="Phone">
                                <th mat-sort-header="v_oo_admin_customer_details_ext.phone_number" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="phone-header"> Phone </th>
                                <td mat-cell *matCellDef="let element" class="phone-column"> {{element.phone_number}}
                                </td>
                        </ng-container>
                            <ng-container matColumnDef="Active">
                                    <th mat-sort-header="v_oo_admin_customer_details_ext.is_valid" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="active-header">
                                            Active </th>
                                    <td mat-cell *matCellDef="let element" class="active-column">
                                            <mat-icon *ngIf="element.is_valid">check</mat-icon>
                                          
                                    </td>
                            </ng-container>
                            <ng-container matColumnDef="Last Order Date">
                                <th mat-sort-header="v_oo_admin_customer_details_ext.last_order_date" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="last-order-date-header"> Last Order Date
                                </th>
                                <td mat-cell *matCellDef="let element" class="last-order-date-column"> {{element.last_order_date | date:dateFormat  }} </td>
                        </ng-container>
                        <ng-container matColumnDef="Orders">
                            <th style="text-align:center;" mat-sort-header="v_oo_admin_customer_details_ext.order_count" mat-header-cell *matHeaderCellDef  (click)="sortData($event)" class="order-header"> Orders
                            </th>
                            <td mat-cell *matCellDef="let element" style="text-align:right;" class="order-column"> {{element.order_count  }} </td>
                    </ng-container>



                            <ng-container matColumnDef="Total Amount">
                                    <th mat-sort-header="v_oo_admin_customer_details_ext.total_amount" mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="amount-header">Amount</th>
                                    <td mat-cell *matCellDef="let element" style="text-align:right;" class="amount-column"> {{element.total_amount
                                            | currency:'USD':'symbol':'1.2-2' }}
                                    </td>
                            </ng-container>

                            <ng-container matColumnDef="action" stickyEnd>
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                        <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                               <mat-icon>more_vert</mat-icon>
                                        </a>

                                        <mat-menu #rowMenu>
                                                @if(permission.can_edit) {
                                                <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                }@else{
                                                <button mat-menu-item (click)="onEdit(rowItem)">View</button>  
                                                }
                                        </mat-menu>
                                </td>
                        </ng-container>


                            
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                            </tr>
                            <tr mat-row class="table-element-row" *matRowDef="let element; columns: displayedColumns;"
                                    (click)="onEdit(element)">
                            </tr>
                    </table>
            </section>
            @if(pagingData.length<=0){
            <div class="no-data-container">
                    <img src="assets/images/no-data.png">
                    <span class="no-data-info">No data found. Please remove or redefine the filters if
                            any.</span>
            </div>
            }
    </div>

</div>