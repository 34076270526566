<div class="row">
  <span class="closex" (click)="actionClose()"> X </span>
</div>

<div fxLayout="row" class="custom_flex" style="background: #fff; padding: 15px; padding-left: 0px;">
  <div style="width: 100%; float: left; clear: both">
    <table style="margin-left: 18px; ">
      <tr>
        <td class="td daydata" style="width: 120px"> Day </td>
        <td class="td daydata">
          <div> From<br><span style="font-size: 13px;">HH:mm</span></div>
        </td>
        <td class="td daydata">
          <div> To<br><span style="font-size: 13px;">HH:mm</span></div>
        </td>
        <td class="td daydata"> Preparation<br> Time </td>
      </tr>
      <tr *ngFor="let timeslot of dataProvider let i = index">
        <td style="width: 120px">
          <div class="week-day">
            @if(timeslot.weekday=== 1){
            <mat-label>Monday</mat-label>
            }@else if(timeslot.weekday === 2){
            <mat-label>Tuesday</mat-label>
            }@else if(timeslot.weekday === 3){
            <mat-label>Wednesday</mat-label>
            }@else if(timeslot.weekday === 4){
            <mat-label>Thursday</mat-label>
            }@else if(timeslot.weekday === 5){
            <mat-label>Friday</mat-label>
            }@else if(timeslot.weekday === 6){
            <mat-label>Saturday</mat-label>
            }@else if(timeslot.weekday === 7){
            <mat-label>Sunday</mat-label>
            }



            <!-- <mat-select placeholder="Day" class=selectday [(ngModel)]="timeslot.weekday" [disabled]="disableSelect">
              <mat-option *ngFor="let item of dayList" [value]="item.day">
                {{item.label}}
              </mat-option> 
            </mat-select> -->
          </div>
        </td>
        <td class="tdtime"> <input type="text" (keypress)="numberOnly($event)"
            class="time {{timeslot.error && timeslot.error.length > 0 ? 'error': ''}}" [(ngModel)]="timeslot.from"
            (change)="onchange($event, timeslot.from, i, 'from')"> </td>
        <td class="tdtime"> <input type="text" (keypress)="numberOnly($event)"
            class="time {{timeslot.error && timeslot.error.length > 0 ? 'error': ''}}" [(ngModel)]="timeslot.to"
            (change)="onchange($event, timeslot.to, i, 'to')"> </td>
        <td class="tdtime"> <input type="text" (keypress)="numberOnly($event)" class="lengthtime" [(ngModel)]="timeslot.delay"> </td>
      </tr>
    </table>
  </div>

</div>

<mat-dialog-actions align="end" class="footer">
  <button mat-raised-button color="primary" (click)="actionSave()">Save</button>
  <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
</mat-dialog-actions>