<div class="option-bar-root-container" [style.width]="show? 'var(--left-side-optionbar-width)':'0px'">
    <div class="menu-list-container"></div>
    <div class="filter-container">
  
   
        <!-- Filter List -->
        <div class="section-container filter-list-container">
            <div class="title" (click)="showFilter=!showFilter">
                <mat-icon [style.transform]="showFilter ? 'rotate(180deg)' : 'rotate(0)'">arrow_drop_down</mat-icon>
                <span>Filters</span>
                <div *ngIf="this.selectedFilter!==undefined" class="close-btn"
                    (click)="resetQuickFilters(true);$event.stopPropagation();"><svg xmlns="http://www.w3.org/2000/svg"
                        width="18" viewBox="0 0 92 92" id="tag">
                        <path style="fill: #ff0057 !important"
                            d="M88 20H29c-1 0-1.9.4-2.6 1l-25 22c-.9.8-1.4 1.9-1.4 3 0 1.2.5 2.2 1.4 3l25 22c.7.6 1.7 1 2.6 1h59c2.2 0 4-1.8 4-4V24c0-2.2-1.8-4-4-4zm-4 44H30.5L10.1 46l20.5-18H84v36zM44.1 51.4l5.4-5.4-5.4-5.4c-1.4-1.4-1.4-3.6 0-4.9 1.4-1.4 3.6-1.4 4.9 0l5.4 5.4 5.4-5.4c1.4-1.4 3.6-1.4 4.9 0 1.4 1.4 1.4 3.6 0 4.9L59.4 46l5.4 5.4c1.4 1.4 1.4 3.6 0 4.9-.7.7-1.6 1-2.5 1s-1.8-.3-2.5-1l-5.4-5.4-5.4 5.4c-.7.7-1.6 1-2.5 1-.9 0-1.8-.3-2.5-1-1.2-1.3-1.2-3.5.1-4.9z">
                        </path>
                    </svg></div>
            </div>
            <div class="content item-content" [style.max-height]=" showFilter ? '350px' : '0px' ">
                <ng-container *ngFor="let filter of filters">
                    <div [ngClass]=" {'selected-option' : filter.id===selectedFilter?.id}" class="item-container"
                        (click)="onFilterSelected(filter)">
                        <div class="item-icon">
                            
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="filter">
                                @if(filter.id>100000){
                                <path style="fill: #f30e0e  !important"
                                    d="m1 10.399 19 20v18.405l10-6.25V30.399l19-20V1H1v9.399zM3 3h44v6.601l-19 20v11.845l-6 3.75V29.601l-19-20V3z">
                                </path>
                            }@else{
                                <path style="fill: #018786  !important"
                                d="m1 10.399 19 20v18.405l10-6.25V30.399l19-20V1H1v9.399zM3 3h44v6.601l-19 20v11.845l-6 3.75V29.601l-19-20V3z">
                            </path>
                            }

                            </svg>
                        </div>
                        <span class="item-name">{{filter.name}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>