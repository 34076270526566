import { Injectable } from "@angular/core";
import { CustomerModel } from "./models/customer.model";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable, catchError, map, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthCrmService } from "src/app/auth/auth-crm.service";
@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  

  constructor(private http: HttpClient,private authCrmService:AuthCrmService) { }
  /**
     *
     *  customer listing 
     */
  getCustomersList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.wsPhp + '/customer/get-list', requestData);
  }
  /**
   *
   *  update is_valid to oo_customer table 
   */

  setCustomerInfo(bodyParams: any): any {
    return this.http.post<any>(
      environment.wsPhp + "/customer/set-active",
      bodyParams
    );
  }
  /**
   *
   *  customer list export 
   */
  exportCustomerList(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(environment.wsPhp + '/customer/get-list-export', requestData, { headers, responseType: 'blob' })
      .pipe(
      //catchError(this.handleError)
    );

  }
  getId(id: number): Observable<any> {
    return this.http.post(environment.crm.apiUrl + '/customer/getById/' + id, '');

  }

  /**
   * 
   * @param customerInfo 
   * Calls the update API to insert/update the record
   */
  // update(customerInfo: CustomerModel): Observable<any> {
  //   if (customerInfo.hasOwnProperty("id")) {
  //     return this.http.post(environment.apiCrmUrl + '/customer/update', customerInfo);
  //   } else {
  //     return this.http.post(environment.apiCrmUrl + '/customer/addCustomer', customerInfo);
  //   }
  // }
    /**
  * 
  * @param customerEmail
  * Calls the API to update the customer email
  */
    // updateCustomerEmail(customerEmail: any): Observable<any> {
    //   return this.http.post(environment.crm.apiUrl + '/customer/updateCustomerEmail', customerEmail);
  
    // }
    updateCustomerEmail(customerEmail: any): Observable<any> {
      return this.authCrmService.getToken().pipe(
        map((response) => response),
        catchError((error) => {
          throw error;
        }),
        switchMap((response: any) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: response,
            }),
          };
  
          return this.http.post(
            environment.crm.apiUrl + '/customer/updateCustomerEmail',
            {origin:"oo",...customerEmail},

            httpOptions
          );
        })
      );
    }
    update(customer: any): Observable<any> {
      return this.authCrmService.getToken().pipe(
        map((response) => response),
        catchError((error) => {
          throw error;
        }),
        switchMap((response: any) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: response,
            }),
          };
  
          return this.http.post(
            environment.crm.apiUrl + '/customer/update',
            {origin:"oo",...customer},
            httpOptions
          );
        })
      );

}
}
