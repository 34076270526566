import { Injectable } from "@angular/core";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HolidayService {

  constructor(private http: HttpClient) { }
 
  deleteHoliday(hdrId: any){
 
    return this.http.get(
      environment.baseUrl + "holiday/delete?id=" + hdrId
    );

}

holidayShopList(holidayType: any, regionId: any): any {
  if (holidayType === "1" || regionId === "") {
    return this.http.get<any>(environment.baseUrl + "holiday/get-shops");
  } else {
    return this.http.get<any>(
      environment.baseUrl + "holiday/get-shop?id=" + regionId
    );
  }
}

holidayGetListByDate(bodyParam: any): any {
  return this.http.post<any>(
    environment.baseUrl + "holiday/get-list",
    bodyParam
  );
}
holidaySave(bodyParam: any): any {
  return this.http.post<any>(
    environment.baseUrl + "holiday/save",
    bodyParam
  );
}

holidayListDays(bodyParam: any): any {
  return this.http.post<any>(
    environment.baseUrl + "holiday/get-list",
    bodyParam
  );
}

getFestiveTimings(bodyParam: any){
  return this.http.post<any>(
    environment.baseUrl + "holiday/get-shop-holiday-list-for-edit",
    bodyParam
  );
}

getBusinessType(): any {
  return this.http.get<any>(environment.baseUrl + "businesstype/get-list");
}

holidaySaveNew(bodyParam: any): any {
  return this.http.post<any>(
    environment.baseUrl + "holiday/save",
    bodyParam
  );
}
getShopList(): any {
  return this.http.get<any>(environment.baseUrl + "shop/get-list");
}
getRegionList(): any {
  return this.http.get<any>(environment.baseUrl + "region/get-list");
}

}
